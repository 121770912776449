import keyboardJS from 'keyboardjs'
import { browserEvent } from '@isoftdata/browser-event'

export class TableKeyboardNavigator {
	constructor(options = {}) {
		const missingProps = Object.keys(options).filter(k => typeof options[k] === 'undefined')
		if (missingProps.length) {
			throw new Error('TableKeyboardNavigator: all options should be defined.', missingProps)
		}
		Object.assign(this, options)
		this.bindEvents()
		if (this.initFocusListeners) {
			this.createFocusListeners()
		}
	}

	keyboardJs = keyboardJS
	tableId = ''
	ractive = null
	rowsKeypath = ''
	selectedIndexKeypath = ''
	columnIds = []
	initFocusListeners = true
	bindCustomEvents = () => false
	changeRow = () => false
	otherTable = () => false
	removeFocusinListener = () => false
	removeFocusoutListener = () => false
	createFocusListeners() {
		const ractive = this.ractive
		const targetTable = ractive.find(`#${this.tableId}`)

		if (targetTable) {
			this.removeFocusinListener = browserEvent(targetTable, 'focusin', () => {
				keyboardJS.setContext(this.tableId)
				keyboardJS.withContext(this.tableId, () => {
					keyboardJS.resume()
				})
			})

			this.removeFocusoutListener = browserEvent(targetTable, 'focusout', () => {
				// not sure if we want to set the context to something else here?
				keyboardJS.withContext(this.tableId, () => {
					keyboardJS.pause()
				})
			})
		}
	}

	bindEvents() {
		const ractive = this.ractive

		keyboardJS.withContext(this.tableId, () => {
			keyboardJS.bind('up', event => {
				console.log('up', this.tableId, event)
				const tableWithEventId = event.target.closest('table').id
				event.target?.blur()

				if (tableWithEventId === this.tableId) {
					event.preventDefault()
					event.preventRepeat() // I just don't want to deal with the possiblity of repeats
					const selectedRowIndex = ractive.get(this.selectedIndexKeypath)
					if (selectedRowIndex > 0) {
						this.selectRow(event, selectedRowIndex, -1)
					}
				} else if (tableWithEventId) {
					this.otherTable(tableWithEventId, event)
				}
			})

			keyboardJS.bind('down', event => {
				console.log('down', this.tableId, event)
				const tableWithEventId = event.target.closest('table').id
				event.target?.blur()

				if (tableWithEventId === this.tableId) {
					event.preventDefault()
					event.preventRepeat()
					const selectedRowIndex = ractive.get(this.selectedIndexKeypath)
					const rows = ractive.get(this.rowsKeypath)
					if (selectedRowIndex < rows.length - 1) {
						this.selectRow(event, selectedRowIndex, 1)
					}
				} else if (tableWithEventId) {
					this.otherTable(tableWithEventId, event)
				}
			})

			keyboardJS.bind('left', event => {
				console.log('left', this.tableId, event)
				const tableWithEventId = event.target.closest('table').id
				if (tableWithEventId === this.tableId
					&& (!event.target.selectionDirection || (event.target.selectionStart === 0 && event.target.selectionEnd === event.target.value.length))
					&& event.pressedKeys.length === 1) {
					event.preventDefault()
					event.preventRepeat()
					// I don't think I can rely on doing stuff like nextSibling or whatever here because a lot of our components have a lot of nested elements
					const lastFocusedId = event.target.id

					const lastFocusedIndex = this.columnIds.findIndex(column => lastFocusedId.includes(column))
					if (lastFocusedIndex > 0) {
						const nextElement = TableKeyboardNavigator.getPreviousSelectableElementInRow(event.target, this.columnIds)
						console.log('nextElement', nextElement?.id)
						TableKeyboardNavigator.selectFocusScrollIntoView(nextElement)
					}
				} else if (tableWithEventId && tableWithEventId !== this.tableId) {
					this.otherTable(tableWithEventId, event)
				}
			})

			keyboardJS.bind('right', event => {
				console.log('right', this.tableId, event)
				const tableWithEventId = event.target.closest('table').id
				if (tableWithEventId === this.tableId
					&& (!event.target.selectionDirection || (event.target.selectionStart === 0 && event.target.selectionEnd === event.target.value.length))
					&& event.pressedKeys.length === 1) {
					event.preventDefault()
					event.preventRepeat()
					const lastFocusedId = event.target.id
					const lastFocusedIndex = this.columnIds.findIndex(column => lastFocusedId.includes(column))
					if (lastFocusedIndex < this.columnIds.length - 1 && lastFocusedIndex >= 0) {
						const nextElement = TableKeyboardNavigator.getNextSelectableElementInRow(event.target, this.columnIds)
						console.log('nextElement', nextElement?.id)
						TableKeyboardNavigator.selectFocusScrollIntoView(nextElement)
					}
				} else if (tableWithEventId && tableWithEventId !== this.tableId) {
					this.otherTable(tableWithEventId, event)
				}
			})

			keyboardJS.bind('enter', event => {
				console.log('enter', this.tableId, event)
				const tableWithEventId = event.target.closest('table').id
				event.target?.blur()

				if (tableWithEventId === this.tableId) {
					event.preventDefault()
					event.preventRepeat()
					const selectedRowIndex = ractive.get(this.selectedIndexKeypath)
					if (selectedRowIndex < ractive.get(this.rowsKeypath).length - 1) {
						this.selectRow(event, selectedRowIndex, 1, this.ractive.find(`#${this.columnIds[0]}-${selectedRowIndex + 1}`))
					}
				} else if (tableWithEventId) {
					this.otherTable(event)
				}
			})

			this.bindCustomEvents()
		})
	}

	selectRow(event, lastRowIndex, increment = 1, nextElement = null) {
		let nextElementIndex = lastRowIndex + increment
		if (!nextElement) {
			nextElement = increment > 0
				? TableKeyboardNavigator.getNextSelectableElementInColumn(event.target)
				: TableKeyboardNavigator.getPreviousSelectableElementInColumn(event.target)
			// This could make us skip a few rows since it skips disabled rows
			nextElementIndex = nextElement?.id?.split('-')?.pop()
		}
		this.changeRow(parseInt(nextElementIndex, 10), lastRowIndex)

		console.log('nextElement', nextElement?.id)

		try { // temp try/catch so I can see the errors that happen while testing
			TableKeyboardNavigator.selectFocusScrollIntoView(nextElement)
		} catch (e) {
			console.error(e)
		}
	}

	teardown() {
		this.removeFocusinListener()
		this.removeFocusoutListener()
		keyboardJS.withContext(this.tableId, () => {
			keyboardJS.reset()
		})
	}

	// static functions that may be called on a different table or even ractive instance (child state), so we can't make any assumptions

	static getNextSelectableElementInRow(startElement, columnIds) {
		const startElementId = startElement.id
		const startElementIndex = columnIds.findIndex(column => startElementId.includes(column))
		const nextElement = startElement.closest('tr').querySelector(`[id^=${columnIds[startElementIndex + 1]}]`)
		if (nextElement?.disabled && startElementIndex < columnIds.length - 1) {
			return TableKeyboardNavigator.getNextSelectableElementInRow(nextElement, columnIds)
		}
		return nextElement
	}

	static getPreviousSelectableElementInRow(startElement, columnIds) {
		const startElementId = startElement.id
		const startElementIndex = columnIds.findIndex(column => startElementId.includes(column))
		const nextElement = startElement.closest('tr').querySelector(`[id^=${columnIds[startElementIndex - 1]}]`)

		if (nextElement?.disabled && startElementIndex > 1) {
			return TableKeyboardNavigator.getPreviousSelectableElementInRow(nextElement, columnIds)
		}
		return nextElement
	}

	static getNextSelectableElementInColumn(startElement) {
		const nextElement = startElement.closest('tr').nextElementSibling?.querySelector(`[id^=${startElement.id.replace(/-\d+$/, '')}]`)
		if (nextElement?.disabled) {
			return TableKeyboardNavigator.getNextSelectableElementInColumn(nextElement) ?? startElement
		} else if (!nextElement) {
			return null
		}
		return nextElement
	}

	static getPreviousSelectableElementInColumn(startElement) {
		const nextElement = startElement.closest('tr').previousElementSibling?.querySelector(`[id^=${startElement.id.replace(/-\d+$/, '')}]`)
		if (nextElement?.disabled) {
			return TableKeyboardNavigator.getPreviousSelectableElementInColumn(nextElement) ?? startElement
		} else if (!nextElement) {
			return null
		}
		return nextElement
	}

	static selectFocusScrollIntoView(element, focusOptions = { focusVisible: true }, scrollOptions = { block: 'nearest', inline: 'center' }) {
		element?.select?.()
		element?.focus(focusOptions) // Chrome/Safari don't support focusVisible yet but Firefox does
		element?.scrollIntoView(scrollOptions)
	}
}
