import Cookies from 'js-cookie'
import sessionStore, { getSession } from 'stores/session'
import Login from './Login.svelte'
import type { AppContext, ResolveParameters } from 'types/common'
import { stringToBoolean } from '@isoftdata/utility-string'

interface LoginParameters {
	message: string
	username: string
	accountActivationSuccess: string
}

export default ({ mediator, stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'login',
		route: 'login',
		template: {
			svelte: true, // this tells our renderer wrapper that this is a svelte component
			component: Login, // this is the svelte component
			options: {
				// these are the options passed to the svelte component
			},
		},
		querystringParameters: ['message', 'username', 'accountActivationSuccess'],
		// NOTE: I have pretty much just copied the resolve function from the original login.js file in Ractive style and made some minor changes
		async resolve(_data, { username, accountActivationSuccess }: ResolveParameters<LoginParameters>) {
			const oldSession = getSession()
			//They came back to the login state, clear any auth token they may have.
			sessionStore.set({})
			Cookies.remove('auth-token')

			if (oldSession?.authToken) {
				try {
					await mediator.call('apiFetch', 'mutation logout { closeSession }', {}, {})
				} catch (err) {
					console.log(err)
				}
			}

			const defaultLanguage = 'en'
			const selectedLanguage = localStorage.getItem('i18nextLng') ?? defaultLanguage
			const lastSelectedPlantId = localStorage.getItem('lastSelectedPlantId')

			interface Plant {
				id: number
				name: string
				code: string
			}

			interface LoadPlantResponse {
				plants: {
					data: Plant[]
				}
			}

			async function loadPlants(): Promise<Plant[]> {
				const res = (await mediator.call(
					'apiFetch',
					`#graphql
						query Plants($pagination: PaginatedInput) {
							plants(pagination: $pagination) {
								data {
									id,
									name,
									code
								}
							}
						}
					`,
					{ pagination: { pageSize: 0 } },
					{},
				)) as LoadPlantResponse

				return res.plants.data
			}

			const { getServerInformation: serverInformation } = (await mediator.call(
				'apiFetch',
				`#graphql
					query GetServerInformation {
						getServerInformation {
							releaseVersionNumber
							schemaVersion
						}
					}
				`,
				{},
				{},
			)) as { getServerInformation: { releaseVersionNumber: string; schemaVersion: string } }

			return Promise.resolve({
				plantList: await loadPlants(),
				selectedPlantId: lastSelectedPlantId ? parseInt(lastSelectedPlantId, 10) : 1,
				userName: username ?? localStorage.getItem('lastUsername') ?? '',
				rememberMe: !!localStorage.getItem('lastUsername'),
				showLanguageSelector: selectedLanguage !== defaultLanguage,
				languages: [
					{ code: 'en', name: 'English' },
					{ code: 'cs', name: 'Czech' },
					{ code: 'es', name: 'Spanish' },
					{ code: 'de', name: 'German' },
					{ code: 'fr', name: 'French' },
				],
				selectedLanguage,
				apiVersionNumber: serverInformation.releaseVersionNumber,
				apiSchemaVersion: serverInformation.schemaVersion,
				accountActivationSuccess: stringToBoolean(accountActivationSuccess) || undefined,
			})
		},
	})
}
