<script lang="ts">
	import type { SvelteAsr } from 'types/common'
	import type { ComponentEvents } from 'svelte'
	import type { Column, SortDirection } from '@isoftdata/svelte-table'

	import { History, type PrintJob } from '@isoftdata/svelte-report-viewer'
	import { graphql } from '$houdini'

	export let history: PrintJob[] = []
	export let asr: SvelteAsr
	export let currentPageNumber: number
	export let sortColumnProperty: string
	export let sortDirection: 'ASC' | 'DESC'
	export let totalItemsCount: number
	export let showCancelledJobs: boolean
	export let showSentJobs: boolean
	export let perPageCount: number

	const setPrintJobStatus = graphql(`
		mutation SetPrintJobStatus($input: [SetPrintJobStatusInput!]!) {
			setPrintJobStatus(input: $input)
		}
	`)

	function onPreviewClick(event: CustomEvent<PrintJob>) {
		localStorage.setItem('reportViewerPreviewPrintJob', JSON.stringify(event.detail))
		asr.go('app.report-viewer.reports.preview', { selectedReportId: event.detail.reportId, preview: true })
	}

	function onSetReadyClick(event: CustomEvent<PrintJob>) {
		setPrintJobStatus.mutate({
			input: [
				{
					id: event.detail.id,
					status: 'READY',
				},
			],
		})
	}

	function onCancelClick(event: ComponentEvents<History>['cancelClick']) {
		setPrintJobStatus.mutate({
			input: event.detail.map(id => ({
				id,
				status: 'CANCELLED',
			})),
		})
	}

	function onShowCancelledChange(event: CustomEvent<boolean>) {
		asr.go(null, { showCancelledJobs: event.detail }, { inherit: true })
	}

	function onShowSentChange(event: CustomEvent<boolean>) {
		asr.go(null, { showSentJobs: event.detail }, { inherit: true })
	}

	function onPageChange(event: ComponentEvents<History>['pageChange']) {
		asr.go(null, { pageNumber: event.detail.pageNumber }, { inherit: true })
	}

	function columnClickedMethod(clickedColumn: Column, sortDirection: SortDirection) {
		asr.go(null, { sortColumnProperty: clickedColumn.property, sortDirection, pageNumber: 1 }, { inherit: true })
	}
</script>

<History
	{history}
	{currentPageNumber}
	{perPageCount}
	{sortColumnProperty}
	{sortDirection}
	{totalItemsCount}
	{showCancelledJobs}
	{showSentJobs}
	{columnClickedMethod}
	refreshHref={asr.makePath(null, { lastRefreshTime: Date.now(), pageNumber: 1 }, { inherit: true })}
	on:previewClick={onPreviewClick}
	on:setReadyClick={onSetReadyClick}
	on:cancelClick={onCancelClick}
	on:showCancelledChange={onShowCancelledChange}
	on:showSentChange={onShowSentChange}
	on:pageChange={onPageChange}
></History>
