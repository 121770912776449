export default {
    "name": "DeleteWorkOrderType",
    "kind": "HoudiniMutation",
    "hash": "74665969e537a5bdbd403dd75653a047f20430aff21808ea83fa0607118ec143",

    "raw": `mutation DeleteWorkOrderType($deleteWorkOrderTypeId: ID!) {
  deleteWorkOrderType(id: $deleteWorkOrderTypeId)
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "deleteWorkOrderType": {
                "type": "Void",
                "keyRaw": "deleteWorkOrderType(id: $deleteWorkOrderTypeId)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "deleteWorkOrderTypeId": "ID"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=4537ca22783bda32abfe3f8474d8e17c8f8a9fd1c3cee7463912fb3b4626085d";