<script
	context="module"
	lang="ts"
>
	import type { EntityTagType } from '$houdini'
	/** This type represents an EntityTag with the required metadata to use it with the TagSelection component.*/
	export type MetaTag = { id: null | number; active: boolean; name: string; uuid: string; inUse: boolean; entityType: keyof typeof EntityTagType }
	// Load-bearing nonsense; remove this if you want to break syntax highlighting
	;('')
</script>

<script
	lang="ts"
	generics="T"
>
	import type { i18n } from 'i18next'

	import Table from '@isoftdata/svelte-table'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Input from '@isoftdata/svelte-input'
	import Icon from '@isoftdata/svelte-icon'
	import Button from '@isoftdata/svelte-button'

	import { v4 as uuid } from '@lukeed/uuid'
	import makeCrudStore from '@isoftdata/svelte-store-crud'
	import { createEventDispatcher, getContext, tick } from 'svelte'

	const dispatch = createEventDispatcher<{
		/** Fired when a tag is created, renamed, or deleted.*/
		tagChange: void
		/** Fired when a tag's "In Use" status changes*/
		tagsInUseChange: void
		/** Fired when a tag is marked as "In Use"*/
		tagsInUseAdd: MetaTag
		/** Fired when a tag is no longer marked as "In Use"*/
		tagsInUseRemove: MetaTag
	}>()

	const { t: translate } = getContext<i18n>('i18next')

	export let buttonsInHeader: boolean = false
	export let disabled: boolean | undefined = false
	export let entityType: keyof typeof EntityTagType
	export let removeOuterCard = false
	export let tagsInUse: MetaTag[]
	export let selectedTag: MetaTag | null = null

	export let tags: MetaTag[] = []
	/** If you want to name this card after a specific selected entity, ("TEC Tags") you may specify a title.
	 *
	 * If you just want it to be "Plant Tags" etc., leave it undefined.
	 */
	export let title: string | undefined = undefined
	export let tableParentClass: string = 'mh-50vh'

	const tagTemplate = {
		active: true,
		entityType: entityType,
		id: null,
		name: '',
		inUse: false,
	}
	/** Pass your own CRUD store in if you want tracked changes to persist when the component is destroyed.*/
	export let tagCrudStore = makeCrudStore<MetaTag, 'uuid'>('uuid')

	export let tagsToCreate: MetaTag[] = []
	export let tagsToUpdate: MetaTag[] = []
	export let tagsToDelete: MetaTag[] = []
	$: tagsToUpdate = Object.values($tagCrudStore.updated)
	$: tagsToCreate = Object.values($tagCrudStore.created)
	$: tagsToDelete = Object.values($tagCrudStore.deleted)

	let selectedTagIndex: number | null = null

	function getDeleteTagConfirmMessage() {
		switch (entityType) {
			case 'ANALYSIS':
				return translate(
					'tagSelection.confirmDeleteAnalysisTag',
					'Are you sure you want to permanently delete this tag? If you want to remove it from this analysis, use the checkbox instead. \r\n\r\nNote: After saving, this tag will be removed from all items at all plants.',
				)
			case 'LOCATION':
				return translate(
					'tagSelection.confirmDeleteLocationTag',
					'Are you sure you want to permanently delete this tag? If you want to remove it from this location, use the checkbox instead. \r\n\r\nNote: After saving, this tag will be removed from all items at all plants.',
				)
			case 'PLANT':
				return translate(
					'tagSelection.confirmDeletePlantTag',
					'Are you sure you want to permanently delete this tag? If you want to remove it from this plant, use the checkbox instead. \r\n\r\nNote: After saving, this tag will be removed from all items at all plants.',
				)
			case 'PRODUCT':
				return translate(
					'tagSelection.confirmDeleteProductTag',
					'Are you sure you want to permanently delete this tag? If you want to remove it from this product, use the checkbox instead. \r\n\r\nNote: After saving, this tag will be removed from all items at all plants.',
				)
			case 'SUPPLIER':
				return translate(
					'tagSelection.confirmDeleteSupplierTag',
					'Are you sure you want to permanently delete this tag? If you want to remove it from this supplier, use the checkbox instead. \r\n\r\nNote: After saving, this tag will be removed from all items at all plants.',
				)
			default:
				return translate(
					'tagSelection.confirmDeleteTag',
					'Are you sure you want to permanently delete this tag? If you want to remove it from this item, use the checkbox instead. \r\n\r\nNote: After saving, this tag will be removed from all items at all plants.',
				)
		}
	}

	function getCardHeaderText() {
		switch (entityType) {
			case 'ANALYSIS':
				return translate('tagSelection.analysisTags', 'Analysis Tags')
			case 'LOCATION':
				return translate('tagSelection.locationTags', 'Location Tags')
			case 'PLANT':
				return translate('tagSelection.plantTags', 'Plant Tags')
			case 'PRODUCT':
				return translate('tagSelection.productTags', 'Product Tags')
			case 'SUPPLIER':
				return translate('tagSelection.supplierTags', 'Supplier Tags')
			default:
				return translate('tagSelection.tags', 'Tags')
		}
	}

	function getNoTagsFoundText() {
		switch (entityType) {
			case 'ANALYSIS':
				return translate('tagSelection.noAnalysisTagsFound', 'No analysis tags found.')
			case 'LOCATION':
				return translate('tagSelection.noLocationTagsFound', 'No location tags found.')
			case 'PLANT':
				return translate('tagSelection.noPlantTagsFound', 'No plant tags found.')
			case 'PRODUCT':
				return translate('tagSelection.noProductTagsFound', 'No product tags found.')
			case 'SUPPLIER':
				return translate('tagSelection.noSupplierTagsFound', 'No supplier tags found.')
			default:
				return translate('tagSelection.noTagsFound', 'No tags found.')
		}
	}

	function deleteSelectedTag() {
		if (!confirm(getDeleteTagConfirmMessage()) || selectedTagIndex === null) {
			return
		}
		const selectedTag = tags[selectedTagIndex]
		if (selectedTag.uuid in $tagCrudStore.deleted) {
			tagCrudStore.unDelete(selectedTag)
			tags = tags
		} else {
			tags.splice(selectedTagIndex, 1)
			tags = tags
			tagCrudStore.delete(selectedTag)
		}
		dispatch('tagChange')
	}

	async function addTag() {
		const newTag = { ...tagTemplate, uuid: uuid(), entityType }
		tags.push(newTag)
		tags = tags
		selectedTagIndex = tags.length - 1
		selectedTag = newTag
		tagCrudStore.create(newTag)
		await tick()
		const input = document.getElementById(`${entityType}-tag-name-${selectedTagIndex}`) as HTMLInputElement | null
		input?.focus()
		dispatch('tagChange')
	}
	function tagClicked(thisRow: MetaTag & { originalIndex: number }) {
		if (disabled) {
			return
		}
		selectedTagIndex = thisRow.originalIndex
		selectedTag = thisRow
	}

	function useTagClicked(event, tagIndex: number) {
		if (disabled) {
			return
		}
		tags[tagIndex].inUse = (event.target as HTMLInputElement).checked
		if (tags[tagIndex].inUse) {
			// if we just checked the box add it to the list otherwise remove it
			tagsInUse = [...tagsInUse, tags[tagIndex]]
			dispatch('tagsInUseAdd', tags[tagIndex])
		} else {
			tagsInUse = tagsInUse.filter(tag => tag.uuid !== tags[tagIndex].uuid)
			dispatch('tagsInUseRemove', tags[tagIndex])
		}
		dispatch('tagsInUseChange')
	}
	function updateTagName(event, tagIndex: number) {
		tags[tagIndex].name = (event.target as HTMLInputElement).value.trim()
		tagCrudStore.update(tags[tagIndex])
		tags = tags
		dispatch('tagChange')
	}

	/** Svelte Action hack to move buttons around without creating another component. When Svelte 5 drops, use snippets instead.*/
	function moveButtonsToHeader(node: HTMLElement, moveToHeader = true) {
		if (!moveToHeader) {
			return
		} else {
			doMoveToHeader()
		}
		function doMoveToHeader() {
			const header = node.querySelector('.card-header')
			const footer = node.querySelector('.card-footer')
			const buttons = footer?.querySelector('#action-buttons')
			if (header && buttons) {
				header.querySelector('#header-buttons-go-here')?.appendChild(buttons)
				footer?.remove()
			}
		}

		function doMoveFromHeader() {
			const header = node.querySelector('.card-header')
			const footer = document.createElement('div')
			footer.classList.add('card-footer')
			node.appendChild(footer)
			const buttons = header?.querySelector('#action-buttons')
			if (footer && buttons) {
				header?.querySelector('#header-buttons-go-here')?.removeChild(buttons)
				footer.appendChild(buttons)
			}
		}

		return {
			update(moveToHeader: boolean) {
				if (moveToHeader) {
					doMoveToHeader()
				} else {
					doMoveFromHeader()
				}
			},
			destroy() {
				if (moveToHeader) {
					doMoveFromHeader()
				}
			},
		}
	}
	/** Svelte Action hack to move card around without creating another component. When Svelte 5 drops, use snippets instead.*/
	function removeCard(node: HTMLDivElement, remove = false) {
		const bodyContents = (node.querySelector('.card-body') as HTMLDivElement)?.children
		const footer = node.querySelector('.card-footer')
		const card = node.querySelector('.card')
		if (remove) {
			footer && node.closest('.card')?.appendChild(footer)
			bodyContents && Array.from(bodyContents).forEach(content => node?.appendChild(content))
			card?.remove()
		}
		return {
			destroy() {
				footer?.remove()
			},
		}
	}

	function getMappedTags(tags: Array<MetaTag>, activeTags: Array<MetaTag>) {
		return tags.map(tag => ({ ...tag, inUse: activeTags.some(activeTag => activeTag.uuid === tag.uuid) }))
	}

	$: mappedTags = getMappedTags(tags, tagsInUse)
	function tagHasDuplicateTagName(tag: MetaTag) {
		return tags.some(thisTag => thisTag.name.toUpperCase() === tag.name.toUpperCase() && thisTag.uuid !== tag.uuid)
	}
	$: hasDuplicateTagNames = tags.some(tag => tagHasDuplicateTagName(tag))

	$: hasEmptyTagNames = tags.some(tag => tag.name.trim() === '')
</script>

<div
	use:moveButtonsToHeader={buttonsInHeader}
	use:removeCard={removeOuterCard}
>
	<div class="card">
		<div class="card-header">
			<div class="d-flex justify-content-between">
				<h5>
					{title ? translate('tagSelection.titleTags', '{{title}} Tags', { title }) : getCardHeaderText()}
				</h5>
				<!-- Used by the moveButtonsToHeader action; it moves the buttons into this div -->
				<div id="header-buttons-go-here" />
			</div>
		</div>
		<div class="card-body">
			<Table
				rows={mappedTags}
				columns={[
					{ name: 'In Use', width: '5%', property: 'inUse', align: 'center' },
					{ name: 'Name', width: '95%', property: 'name' },
				]}
				responsive
				parentClass={tableParentClass}
				stickyHeader
				let:row
			>
				<tr
					id="{entityType}-tag-row-{row.originalIndex}"
					class:table-primary={selectedTagIndex === row.originalIndex}
					class="cursor-pointer"
					on:click={() => tagClicked(row)}
				>
					<td class="text-center">
						<Checkbox
							checked={row.inUse}
							showLabel={false}
							{disabled}
							on:change={event => useTagClicked(event, row.originalIndex)}
						/>
					</td>
					<td>
						<Input
							id="{entityType}-tag-name-{row.originalIndex}"
							type="text"
							labelParentClass="mb-0"
							{disabled}
							showLabel={false}
							value={row.name}
							class={tagHasDuplicateTagName(row) ? 'is-invalid' : ''}
							on:change={event => updateTagName(event, row.originalIndex)}
						/>
					</td>
				</tr>
				<svelte:fragment slot="no-rows">
					<tr>
						<td
							colspan="2"
							class="text-center"
						>
							{getNoTagsFoundText()}
						</td>
					</tr>
				</svelte:fragment>
			</Table>
			<div
				class="alert alert-danger"
				class:d-none={!hasDuplicateTagNames}
			>
				<Icon icon="exclamation-triangle" />
				<span class="ml-2">{translate('tagSelection.duplicateTagNameError', 'Duplicate tag names are not allowed.')}</span>
			</div>
		</div>
		<div class="card-footer">
			<div id="action-buttons">
				<Button
					class="btn-sm"
					title={translate('tagSelection.newTagButtonTitle', 'Add a new tag')}
					color="success"
					outline
					disabled={disabled || hasEmptyTagNames}
					icon={{ prefix: 'fas', icon: 'plus', fixedWidth: true }}
					on:click={addTag}
				>
					{translate('tagSelection.newTagButton', 'New Tag')}
				</Button>
				<Button
					class="btn-sm"
					title={translate('tagSelection.deleteTagButtonTitle', 'Delete selected tag. This will remove the tag from all items that use it.')}
					color="danger"
					outline
					disabled={!selectedTag || disabled}
					icon={{ prefix: 'fas', icon: 'trash', fixedWidth: true }}
					on:click={deleteSelectedTag}
				>
					{translate('tagSelection.deleteTagButton', 'Delete')}...
				</Button>
			</div>
		</div>
	</div>
</div>
