export default {
    "name": "UpdateWorkOrderType",
    "kind": "HoudiniMutation",
    "hash": "93a75672cb6a4a6dca3fce0c9c80d2cfb41dc4600664e5f205471fe60404f5d5",

    "raw": `mutation UpdateWorkOrderType($updateWorkOrderType: UpdateWorkOrderTypeInput!) {
  updateWorkOrderType(updateWorkOrderType: $updateWorkOrderType) {
    id
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "updateWorkOrderType": {
                "type": "WorkOrderType",
                "keyRaw": "updateWorkOrderType(updateWorkOrderType: $updateWorkOrderType)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "updateWorkOrderType": "UpdateWorkOrderTypeInput"
        },

        "types": {
            "UpdateWorkOrderTypeInput": {
                "daysTillDue": "NonNegativeInt",
                "daysTillVerificationDue": "NonNegativeInt",
                "defaultAnalysisId": "PositiveInt",
                "defaultGroupId": "PositiveInt",
                "defaultReport": "String",
                "defaultVerificationGroupId": "PositiveInt",
                "id": "PositiveInt",
                "inUseAtPlantIDsToAdd": "PositiveInt",
                "inUseAtPlantIDsToRemove": "PositiveInt",
                "name": "String",
                "productBatchRequired": "ProductBatchRequired",
                "showDue": "Boolean",
                "showLocation": "Boolean",
                "showLocationDescription": "Boolean",
                "showProduct": "ShowProduct",
                "showRecipes": "Boolean",
                "showSampling": "Boolean",
                "showSamplingDetail": "Boolean",
                "showTasks": "Boolean",
                "showTestingDetail": "Boolean",
                "titleRequired": "Boolean",
                "verificationRequired": "Boolean",
                "visibleGroupId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=6fb0c6dbf0546ff1e67edfb1f749faca221500cf3752b84348fe07903fd2dac5";