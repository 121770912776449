export default {
    "name": "CheckApplicableThresholds",
    "kind": "HoudiniQuery",
    "hash": "8b8211aa14b4a7665a658d729790a642d0f0ec38896feefac26b14e8e37c37bf",

    "raw": `query CheckApplicableThresholds($analysisOptionId: PositiveInt!, $currentResult: String!, $onlyApplicable: Boolean, $productBatchId: PositiveInt, $productId: PositiveInt, $severityClassId: PositiveInt, $plantId: PositiveInt, $boundaryTypes: [BoundaryType!]) {
  checkApplicableThresholds(
    analysisOptionId: $analysisOptionId
    currentResult: $currentResult
    onlyApplicable: $onlyApplicable
    productBatchId: $productBatchId
    productId: $productId
    severityClassId: $severityClassId
    plantId: $plantId
    boundaryTypes: $boundaryTypes
  ) {
    id
    choice
    constraint
    boundaryType
    applicability
    violated
    severityClassId
    plantId
    productId
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "checkApplicableThresholds": {
                "type": "CheckApplicableThresholdsResult",
                "keyRaw": "checkApplicableThresholds(analysisOptionId: $analysisOptionId, boundaryTypes: $boundaryTypes, currentResult: $currentResult, onlyApplicable: $onlyApplicable, plantId: $plantId, productBatchId: $productBatchId, productId: $productId, severityClassId: $severityClassId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Float",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "choice": {
                            "type": "String",
                            "keyRaw": "choice",
                            "visible": true
                        },

                        "constraint": {
                            "type": "ConstraintType",
                            "keyRaw": "constraint",
                            "visible": true
                        },

                        "boundaryType": {
                            "type": "BoundaryType",
                            "keyRaw": "boundaryType",
                            "visible": true
                        },

                        "applicability": {
                            "type": "Applicability",
                            "keyRaw": "applicability",
                            "visible": true
                        },

                        "violated": {
                            "type": "Violated",
                            "keyRaw": "violated",
                            "visible": true
                        },

                        "severityClassId": {
                            "type": "PositiveInt",
                            "keyRaw": "severityClassId",
                            "nullable": true,
                            "visible": true
                        },

                        "plantId": {
                            "type": "PositiveInt",
                            "keyRaw": "plantId",
                            "nullable": true,
                            "visible": true
                        },

                        "productId": {
                            "type": "PositiveInt",
                            "keyRaw": "productId",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "analysisOptionId": "PositiveInt",
            "currentResult": "String",
            "onlyApplicable": "Boolean",
            "productBatchId": "PositiveInt",
            "productId": "PositiveInt",
            "severityClassId": "PositiveInt",
            "plantId": "PositiveInt",
            "boundaryTypes": "BoundaryType"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=a53b69a1d10a88514a5ef8411d63ee920716a534f69311aafe0d6995c842173f";