<script lang="ts">
	import type { i18n } from 'i18next'
	import { getContext, createEventDispatcher } from 'svelte'
	import { klona } from 'klona'
	import Modal from '@isoftdata/svelte-modal'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import Input from '@isoftdata/svelte-input'
	import type { Plant, Group, UserAccount } from 'utility/alert-subscription-helper'
	import { emailIsValid } from 'utility/alert-subscription-helper'

	const { t: translate } = getContext<i18n>('i18next')
	const dispatch = createEventDispatcher<{
		/** Fired when the confirm button is clicked*/
		confirm: string[]
	}>()

	interface Filters {
		plantId: string | null
		groupId: string | null
		text: string
	}

	export let show: boolean = false
	export let userAccounts: UserAccount[] = []
	let selectedEmailAddresses: string[] = []
	let hasAppliedFilters: boolean = false
	export let plants: Plant[] = []
	export let groups: Group[] = []
	export let filters: Filters = {
		plantId: null,
		groupId: null,
		text: '',
	}

	export function showModal(emailAddresses: string[]) {
		//Allow them to pass in a list of email addresses to pre-select
		selectedEmailAddresses = klona(emailAddresses)
		show = true
	}

	function confirm() {
		dispatch('confirm', selectedEmailAddresses)
		show = false
	}

	function toggleEmailAddress(toggledEmailAddress: string | null) {
		if (!toggledEmailAddress) {
			return
		}

		const emailAddressIndex = selectedEmailAddresses.indexOf(toggledEmailAddress)
		if (emailAddressIndex > -1) {
			selectedEmailAddresses.splice(emailAddressIndex, 1)
		} else {
			selectedEmailAddresses.push(toggledEmailAddress)
		}
		//let Svelte know about the change
		selectedEmailAddresses = selectedEmailAddresses
	}

	function selectAllEmailAddresses() {
		const validFilteredEmailAddresses = filteredEmailAddresses.reduce((acc: string[], userAccount: UserAccount | null) => {
			if (userAccount?.workEmail) {
				return [...acc, userAccount.workEmail]
			} else return acc
		}, [])

		selectedEmailAddresses = Array.from(new Set([...validFilteredEmailAddresses, ...selectedEmailAddresses]))
	}

	function computeFilteredAccounts(filters: Filters, selectedEmailAddresses: string[]) {
		const { text: textFilter, plantId: plantIdFilter, groupId: groupIdFilter } = filters

		return userAccounts.filter(({ name, workEmail, firstName, lastName, authorizedPlants, userGroups }) => {
			let include = true

			if (!workEmail) {
				return false
			}

			//We always include the existing selected email addresses and never filter them from display
			if (selectedEmailAddresses.includes(workEmail)) {
				return true
			}

			//I've opted to not even let them pick invalid email addresses
			if (!emailIsValid([workEmail])) {
				return false
			}

			if (textFilter) {
				const lowerText = textFilter.toLowerCase()
				include = !!(
					include &&
					(name.toLowerCase().includes(lowerText) || workEmail?.toLowerCase().includes(lowerText) || firstName?.toLowerCase().includes(lowerText) || lastName?.toLowerCase().includes(lowerText))
				)
			}

			if (plantIdFilter) {
				include = !!(include && authorizedPlants && authorizedPlants.length > 0 && authorizedPlants?.find(plant => plant.id === parseInt(plantIdFilter, 10)))
			}

			if (groupIdFilter) {
				include = !!(include && groupIdFilter && userGroups && userGroups.length > 0 && userGroups.map(({ id }) => id).includes(parseInt(groupIdFilter, 10)))
			}

			return include
		})
	}

	$: filteredEmailAddresses = computeFilteredAccounts(filters, selectedEmailAddresses)
	$: hasAppliedFilters = !!filters.plantId || !!filters.groupId || !!filters.text
</script>

<Modal
	bind:show
	title={translate('configuration.alertSubscriptions.pickEmailAddressesModalTitle', 'Pick Email Addresses')}
	cancelButtonText={translate('common:cancel', 'Cancel')}
	cancelButtonSize="sm"
	cancelButtonColor="secondary"
	cancelButtonOutline
	confirmButtonIcon="check"
	confirmButtonText={translate('common:confirm', 'Confirm')}
	confirmButtonOutline
	confirmButtonColor="primary"
	confirmButtonSize="sm"
	on:close={() => (show = false)}
	on:confirm={() => confirm()}
>
	<div class="form-row">
		<div class="col-12 col-sm-6">
			<Select
				label={translate('common:authorizedAtPlant', 'Authorized at Plant')}
				emptyText="-- {translate('common:selectPlant', 'Select Plant')} --"
				bind:value={filters.plantId}
			>
				{#each plants as plant}
					<option value={plant.id}>{plant.code} - {plant.name}</option>
				{/each}
			</Select>
		</div>
		<div class="col-12 col-sm-6">
			<Select
				label={translate('common:memberOfGroup', 'Member of Group')}
				emptyText="-- {translate('common:selectGroup', 'Select Group')} --"
				bind:value={filters.groupId}
			>
				{#each groups as group}
					<option value={group.id}>{group.name}</option>
				{/each}
			</Select>
		</div>
		<div class="col-12">
			<Input
				label={translate('configuration.alertSubscriptions.filterEmailAddressInputLabel', 'Text Filter')}
				showLabel={false}
				placeholder={translate('configuration.alertSubscriptions.filterEmailAddressInputPlaceholder', 'Filter list by name or email address')}
				class="mt-2"
				bind:value={filters.text}
			/>
		</div>
	</div>

	{#if hasAppliedFilters}
		<small class="font-italic">{translate('configuration.alertSubscriptions.pickEmailAddressesModalHasFiltersApplied', 'Selected email addresses are never filtered')}</small>
	{/if}
	<ul
		id="pickEmailList"
		class="list-group"
		style="max-height: 50vh; overflow-y: auto;"
	>
		{#each filteredEmailAddresses as { name, workEmail }}
			{#if workEmail && workEmail?.trim()}
				<button
					on:click={() => toggleEmailAddress(workEmail)}
					class="list-group-item list-group-item-action p-2"
				>
					<i
						class="fa-solid fa-fw text-primary font-weight-bold fa-lg"
						class:fa-check={workEmail && selectedEmailAddresses.includes(workEmail)}
					></i>&nbsp;
					{workEmail} ({name})
				</button>
			{/if}
		{/each}
	</ul>

	<div slot="modalFooter">
		<Button
			class="mb-1"
			size="sm"
			outline
			color="success"
			on:click={selectAllEmailAddresses}
		>
			{translate('common:selectAll', 'Select All')}
		</Button>
	</div>
</Modal>
