export default {
    "name": "CreateEntityTags",
    "kind": "HoudiniMutation",
    "hash": "b2eb8907c70300a0e8e41c8db387e99838609fc6eb52dcc2a435a918941bb9a0",

    "raw": `mutation CreateEntityTags($input: [NewEntityTag!]!) {
  createEntityTags(input: $input) {
    id
    name
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "createEntityTags": {
                "type": "EntityTag",
                "keyRaw": "createEntityTags(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "NewEntityTag"
        },

        "types": {
            "NewEntityTag": {
                "active": "Boolean",
                "entityType": "EntityTagType",
                "name": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=ccd90aa487f458657ce69a7b523d5e9dd2bec7873a52ddefd370e54563e516d1";