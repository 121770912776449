<svelte:options accessors={true} />

<script lang="ts">
	import type { SaveResetProps } from '../configuration'
	import type { SvelteAsr } from 'types/common'
	import type { Mediator } from 'client/services/api-fetch'
	import type { UserSetting, SettingSaveType, DefaultSettingSaveType } from 'utility/setting.d'
	import type { Writable } from 'svelte/store'

	import { onDestroy, setContext, getContext } from 'svelte'
	import Setting from '@isoftdata/svelte-setting'
	import SaveResetButton from '@isoftdata/svelte-save-reset-button'
	import apiFetch from 'utility/api-fetch'
	import { booleanToString } from '@isoftdata/utility-boolean'
	import { writable } from 'svelte/store'

	const mediator = getContext<Mediator>('mediator')
	const i18next = getContext<{ t: (key: string, fallback: string) => string }>('i18next')

	let dirtySettings = writable<Array<UserSetting>>([])
	setContext<Writable<Array<UserSetting>>>('dirtySettings', dirtySettings)
	let dirtyDefaultSettings = writable<Array<UserSetting>>([])
	setContext<Writable<Array<UserSetting>>>('dirtyDefaultSettings', dirtyDefaultSettings)

	const mutations = {
		setDefaultValues: `#graphql
			mutation SetSettingsDefaultValues($updateDefaultValueInput: UpdateDefaultValuesInput!) {
				setSettingsDefaultValues(updateDefaultValueInput: $updateDefaultValueInput)
			}
		`,
		setUpdatedValues: `#graphql
			mutation SetUpdatedValues($updateSettingValuesInput: UpdateSettingValuesInput!) {
				setSettingsValues(updateSettingValuesInput: $updateSettingValuesInput)
			}
		`,
		setUserSetting: `#graphql
			mutation SetUserSetting($value: SettingChange!) {
				setUserSetting(value: $value)
			}
		`,
	}

	export let settingsChanged: boolean = false
	$: settingsChanged = $dirtySettings.length > 0 || $dirtyDefaultSettings?.length > 0

	//#region global props
	//#endregion
	export let asr: SvelteAsr
	//#region local props
	export let configurationSettings: {
		importantConfiguration: boolean
		optionalConfiguration: boolean
		preference: boolean
		interfacePreference: boolean
		interfaceHistory: boolean
	}
	export let settings: UserSetting[]
	export let selectedCategory: string = 'All'
	export let saveResetProps: SaveResetProps
	$: $saveResetProps = {
		disabled: !settingsChanged,
		resetHref: asr.makePath(null, { lastResetTime: Date.now(), lastSavedTime: null, selectedCategory }, { inherit: true }),
		save: saveSettings,
	}
	//#endregion

	//#region Internal Props
	let filterChoices = [
		{ name: 'Important Configuration', value: configurationSettings.importantConfiguration ?? true },
		{ name: 'Preference', value: configurationSettings.preference ?? true },
		{ name: 'Interface Preference', value: configurationSettings.interfacePreference ?? true },
		{ name: 'Optional Configuration', value: configurationSettings.optionalConfiguration ?? true },
		{ name: 'Interface History', value: configurationSettings.interfaceHistory ?? false },
	]
	//#endregion

	//#region Internal Save Methods
	function formatSettingForSave(setting: UserSetting): SettingSaveType {
		let valueToSave = setting.value
		if (setting.dataType === 'boolean') {
			valueToSave = booleanToString(Boolean(setting.value))
		} else {
			valueToSave = String(setting.value)
		}
		return {
			settingId: setting.id,
			value: valueToSave,
		}
	}

	function formatDefaultSettingForSave(setting: UserSetting): DefaultSettingSaveType {
		let valueToSave = setting.defaultValue
		if (setting.dataType === 'boolean') {
			valueToSave = booleanToString(Boolean(setting.defaultValue))
		} else {
			valueToSave = String(setting.defaultValue)
		}
		return {
			settingId: setting.id,
			newDefaultValue: valueToSave,
		}
	}

	async function saveSettings() {
		const dirtyValuesToUpdate = $dirtySettings.map(formatSettingForSave)
		const defaultValuesToUpdate = $dirtyDefaultSettings.map(formatDefaultSettingForSave)

		if (dirtyValuesToUpdate?.length + defaultValuesToUpdate?.length === 0) {
			mediator.call('showMessage', { type: 'info', heading: 'No settings to save', message: 'No settings have been changed', time: 10 })
			return
		}

		try {
			await Promise.all([
				defaultValuesToUpdate.length
					? apiFetch(mediator, {
							query: mutations.setDefaultValues,
							variables: {
								updateDefaultValueInput: {
									settings: defaultValuesToUpdate,
								},
							},
						})
					: null,
				dirtyValuesToUpdate.length
					? apiFetch(mediator, {
							query: mutations.setUpdatedValues,
							variables: {
								updateSettingValuesInput: {
									settings: dirtyValuesToUpdate,
								},
							},
						})
					: null,
			])
		} catch (e: any) {
			const error = e.message ?? ''
			mediator.call('showMessage', { type: 'error', heading: 'Error saving settings', message: error, time: false })
			console.error(e)
		}

		dirtySettings.set([])
		dirtyDefaultSettings.set([])

		asr.go(null, { lastSavedTime: Date.now(), selectedCategory }, { inherit: true })
	}

	async function saveInterfaceHistory(): Promise<void> {
		await Promise.all([
			apiFetch(mediator, {
				query: mutations.setUserSetting,
				variables: {
					value: {
						category: 'Configuration',
						name: "Configure Settings Dialog: Show 'important configuration' settings",
						settingType: 'INTERFACE_HISTORY',
						newValue: booleanToString(filterChoices.find(choice => choice.name === 'Important Configuration')?.value ?? true),
					},
				},
			}),
			apiFetch(mediator, {
				query: mutations.setUserSetting,
				variables: {
					value: {
						category: 'Configuration',
						name: "Configure Settings Dialog: Show 'optional configuration' settings",
						settingType: 'INTERFACE_HISTORY',
						newValue: booleanToString(filterChoices.find(choice => choice.name === 'Optional Configuration')?.value ?? true),
					},
				},
			}),
			apiFetch(mediator, {
				query: mutations.setUserSetting,
				variables: {
					value: {
						category: 'Configuration',
						name: "Configure Settings Dialog: Show 'preference' settings",
						settingType: 'INTERFACE_HISTORY',
						newValue: booleanToString(filterChoices.find(choice => choice.name === 'Preference')?.value ?? true),
					},
				},
			}),
			apiFetch(mediator, {
				query: mutations.setUserSetting,
				variables: {
					value: {
						category: 'Configuration',
						name: "Configure Settings Dialog: Show 'interface preference' settings",
						settingType: 'INTERFACE_HISTORY',
						newValue: booleanToString(filterChoices.find(choice => choice.name === 'Interface Preference')?.value ?? true),
					},
				},
			}),
			apiFetch(mediator, {
				query: mutations.setUserSetting,
				variables: {
					value: {
						category: 'Configuration',
						name: "Configure Settings Dialog: Show 'interface history' settings",
						settingType: 'INTERFACE_HISTORY',
						newValue: booleanToString(filterChoices.find(choice => choice.name === 'Interface History')?.value ?? false),
					},
				},
			}),
		])
	}
	//#endregion

	//#region Cleanup
	onDestroy(() => {
		saveInterfaceHistory()
	})
	//#endregion
</script>

<Setting
	bind:settings
	bind:selectedCategory
	{filterChoices}
	hideDescription={true}
	defaultValueEditable={true}
	siteAlias="Plant"
	scopeBadge
/>
