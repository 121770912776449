import { v4 as uuid } from '@lukeed/uuid'
import { ComponentProps } from 'svelte'

import DateRange from '@isoftdata/svelte-date-range/dist/DateRange.svelte'
import { type ScheduleType$options, ScheduleType } from '$houdini/graphql'

export function convertToScheduleTypeEnum(str: string): ScheduleType$options | undefined {
	const scheduleType = ScheduleType[str as keyof typeof ScheduleType]
	return scheduleType
}
export type DateRangeFilterType = ComponentProps<DateRange>['defaultRange']

export type AutomaticWorkOrderData = {
	uuid: string
	id: number | null
	workOrderId: number
	scheduleType: string
	monthOffset: number | null
	dayOffset: number
	timeOffset: string
	timezone: string
	active: boolean
	rank: number
	cloneAdditionalData: boolean
	cloneMostRecent: boolean
	description: string | null
	dayOfWeek: number | null
	dateCreated: Date
	nextOccurance: Date | null
	workOrder: {
		id: number
		plant: {
			id: number
			name: string
			code: string
		}
	}
}

export type NewAutomaticWorkOrder = {
	workOrderId: number
	active: boolean
	cloneAdditionalData: boolean
	cloneMostRecent: boolean
	dayOfWeek: number | null
	dayOffset: number
	description: string | null
	monthOffset: number | null
	rank: number
	scheduleType: ScheduleType$options
	timeOffset: string
	timezone: string
}

export type UpdateAutomaticWorkOrder = {
	id: number
	workOrderId: number
	active: boolean
	cloneAdditionalData: boolean
	cloneMostRecent: boolean
	dayOfWeek: number | null
	dayOffset: number
	description: string | null
	monthOffset: number | null
	rank: number
	scheduleType: ScheduleType$options
	timeOffset: string
	timezone: string
}

export class AutomaticWorkOrder {
	public constructor(data: Partial<AutomaticWorkOrder>) {
		Object.assign(this, data)
		this.uuid = uuid()
		this.id = null // If the constructor is called its a new AutomaticWorkOrder
		this.workOrderId = data.workOrderId ?? 0
		this.scheduleType = data.scheduleType ?? 'DAILY'
		this.monthOffset = data.monthOffset ?? null
		this.dayOffset = data.dayOffset ?? 0
		this.timeOffset = data.timeOffset ?? '00:00:00'
		this.timezone = data.timezone ?? 'SYSTEM'
		this.active = data.active ?? true
		this.rank = data.rank ?? 0
		this.cloneAdditionalData = data.cloneAdditionalData ?? false
		this.cloneMostRecent = data.cloneMostRecent ?? false
		this.description = data.description ?? null
		this.dayOfWeek = data.dayOfWeek ?? null
		this.dateCreated = new Date()
		this.nextOccurance = data.nextOccurance ?? null
		this.workOrder = data.workOrder ?? {
			id: 0,
			plant: {
				id: 0,
				name: '',
				code: '',
			},
		}
	}
	uuid: string
	id!: number | null
	workOrderId!: number
	scheduleType!: ScheduleType$options
	monthOffset!: number | null
	dayOffset!: number
	timeOffset!: string
	timezone!: string
	active!: boolean
	rank!: number
	cloneAdditionalData!: boolean
	cloneMostRecent!: boolean
	description!: string | null
	dayOfWeek!: number | null
	dateCreated!: Date
	nextOccurance!: Date | null
	workOrder!: {
		id: number
		plant: {
			id: number
			name: string
			code: string
		}
	}

	static createNewAutomaticWorkOrder(currentPlant: { id: number; name: string; code: string }, workOrderId?: number) {
		return new AutomaticWorkOrder({
			workOrderId: workOrderId ?? 0,
			workOrder: {
				id: 0,
				plant: {
					id: currentPlant.id,
					name: currentPlant.name,
					code: currentPlant.code,
				},
			},
		})
	}

	static from(data: AutomaticWorkOrder) {
		return new AutomaticWorkOrder(data)
	}

	static fromArray(data: AutomaticWorkOrder[]) {
		return data.map(d => new AutomaticWorkOrder(d))
	}

	static toCreateSave(data: AutomaticWorkOrder): NewAutomaticWorkOrder {
		return {
			workOrderId: data.workOrderId,
			active: data.active,
			cloneAdditionalData: data.cloneAdditionalData,
			cloneMostRecent: data.cloneMostRecent,
			dayOfWeek: data.dayOfWeek,
			dayOffset: data.dayOffset,
			description: data.description,
			monthOffset: data.monthOffset,
			rank: data.rank,
			scheduleType: data.scheduleType,
			timeOffset: data.timeOffset,
			timezone: data.timezone,
		}
	}

	static toUpdateSave(data: AutomaticWorkOrder): UpdateAutomaticWorkOrder {
		if (!data.id) throw new Error('Cannot update AutomaticWorkOrder without an id')
		return {
			id: data.id,
			workOrderId: data.workOrderId,
			active: data.active,
			cloneAdditionalData: data.cloneAdditionalData,
			cloneMostRecent: data.cloneMostRecent,
			dayOfWeek: data.dayOfWeek,
			dayOffset: data.dayOffset,
			description: data.description,
			monthOffset: data.monthOffset,
			rank: data.rank,
			scheduleType: data.scheduleType,
			timeOffset: data.timeOffset,
			timezone: data.timezone,
		}
	}
}
