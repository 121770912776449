export default {
    "name": "PasswordRecovery",
    "kind": "HoudiniMutation",
    "hash": "ec8a518c1b6c66693e31f2251e8b5817cd80ae359a0b3cb0a1cdbddbcf151d5f",

    "raw": `mutation PasswordRecovery($userName: String!) {
  passwordRecovery(userName: $userName) {
    recoveryEmail
    userName
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "passwordRecovery": {
                "type": "PasswordResetResponse",
                "keyRaw": "passwordRecovery(userName: $userName)",

                "selection": {
                    "fields": {
                        "recoveryEmail": {
                            "type": "String",
                            "keyRaw": "recoveryEmail",
                            "visible": true
                        },

                        "userName": {
                            "type": "String",
                            "keyRaw": "userName",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "userName": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=5bb1fb2d35c0079899db7feac44137d2225ad7c8d30561854a35db1cc8e69815";