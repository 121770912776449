export default {
    "name": "SetDefaultReportPrinter",
    "kind": "HoudiniMutation",
    "hash": "1864451daafbfb44b096a374565fa6f37e0e5411e2624a995070a54bb09953a4",

    "raw": `mutation SetDefaultReportPrinter($input: SetReportPrinter!) {
  setDefaultReportPrinter(input: $input) {
    id
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "setDefaultReportPrinter": {
                "type": "ReportPrinter",
                "keyRaw": "setDefaultReportPrinter(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "SetReportPrinter"
        },

        "types": {
            "SetReportPrinter": {
                "plantId": "PositiveInt",
                "printer": "NonEmptyString",
                "reportType": "String",
                "userId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=5836418233c6c2dcdeea446feebab09b55d67e826c22cb5a7720a6e1c3e6aa25";