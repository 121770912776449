export default {
    "name": "WoLocationAttachments",
    "kind": "HoudiniQuery",
    "hash": "43d10f49fb2e56a738e8827c8262e23a571f74d39ea12b1fc749309fba417e49",

    "raw": `query WoLocationAttachments($locationId: ID!) {
  location(id: $locationId) {
    attachments {
      file {
        path
        type
        id
      }
      id
    }
    id
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "location": {
                "type": "Location",
                "keyRaw": "location(id: $locationId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "attachments": {
                            "type": "LocationFile",
                            "keyRaw": "attachments",

                            "selection": {
                                "fields": {
                                    "file": {
                                        "type": "File",
                                        "keyRaw": "file",

                                        "selection": {
                                            "fields": {
                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "type": {
                                                    "type": "FileType",
                                                    "keyRaw": "type",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "locationId": "ID"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=f5496161869e3bd7a73f63f76d79c3b655cf408e66193e50dbff49d2515775b0";