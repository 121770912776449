export default {
    "name": "CopyWorkOrders",
    "kind": "HoudiniMutation",
    "hash": "481924b0f95a57f58d47e65cd553e3cfa1329d6adec7582daaee7f96a11b00f0",

    "raw": `mutation CopyWorkOrders($input: [CopyWorkOrderInput!]!) {
  copyWorkOrders(input: $input) {
    lastClonedId
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "copyWorkOrders": {
                "type": "CopyWorkOrderResult",
                "keyRaw": "copyWorkOrders(input: $input)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "lastClonedId": {
                            "type": "NonNegativeInt",
                            "keyRaw": "lastClonedId",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "CopyWorkOrderInput"
        },

        "types": {
            "CopyWorkOrderInput": {
                "copyMostRecent": "Boolean",
                "copyValues": "Boolean",
                "id": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=5983f40f499d85727deddff79a287a3870379fe630d5cd1f929333782afe9393";