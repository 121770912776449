export default {
    "name": "GetDefaultReportPrinter",
    "kind": "HoudiniQuery",
    "hash": "b525110eef3ae65d9e98b6ca989724c82ad2a8e40b03ce28bc47529b43f1a28a",

    "raw": `query GetDefaultReportPrinter($input: GetReportPrinter!) {
  getDefaultReportPrinter(input: $input) {
    printer
    id
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "getDefaultReportPrinter": {
                "type": "ReportPrinter",
                "keyRaw": "getDefaultReportPrinter(input: $input)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "printer": {
                            "type": "String",
                            "keyRaw": "printer",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "GetReportPrinter"
        },

        "types": {
            "GetReportPrinter": {
                "plantId": "PositiveInt",
                "reportType": "String",
                "userId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=33f807116b4b59cfe8ff2868ad9dc01605be8f392f29d2839f02f0f43ea2535a";