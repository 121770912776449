export default {
    "name": "AttachFileToLocation",
    "kind": "HoudiniMutation",
    "hash": "baeb7a8a378dbf8df4d25b192206f51b80c0a5ab37b954c2e22e33b937e38dd8",

    "raw": `mutation AttachFileToLocation($input: NewLocationFile!) {
  attachFileToLocation(input: $input) {
    id
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "attachFileToLocation": {
                "type": "LocationFile",
                "keyRaw": "attachFileToLocation(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "NewLocationFile"
        },

        "types": {
            "NewLocationFile": {
                "base64String": "String",
                "fileName": "String",
                "locationId": "PositiveInt",
                "public": "Boolean",
                "rank": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=ba31b7451542a56552e1d5287a49a3a16759ce29e15b8b04810001d22069080a";