<script
	context="module"
	lang="ts"
>
	export type WorkOrderSearchResult = {
		favorite: boolean
		plant: {
			id: number
			name: string
			code: string
		}
		id: number
		workOrderType: {
			id: number
			name: string
		}
		title: string
		scheduled: Date
		dateCreated: Date
		due: Date | null
		internalNotes: string
		instructions: string
	}

	export type WorkOrderSearchFilter = {
		plantId?: number | null
		from?: Date | null
		to?: Date | null
		openDocuments?: boolean | null
		closedDocuments?: boolean | null
		favoriteDocuments?: boolean | null
		workOrderTypeId?: number | null
		groupId?: number | null
	}

	//end module section
</script>

<script lang="ts">
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { Mediator, i18n, HasPermission } from 'types/common'
	import type { DateRangeFilterType } from 'types/automatic-work-order'

	import apiFetch from 'utility/api-fetch'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import DateRange from '@isoftdata/svelte-date-range'
	import { booleanToString } from '@isoftdata/utility-boolean'
	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'
	import { getContext, onMount, onDestroy, type ComponentEvents } from 'svelte'

	const { t: translate } = getContext<i18n>('i18next')
	const mediator = getContext<Mediator>('mediator')
	const hasPermission = getContext<HasPermission>('hasPermission')

	const mutations = {
		setUserSetting: `#graphql
			mutation SetUserSetting($value: SettingChange!) {
				setUserSetting(value: $value)
			}
		`,
	}

	export let workOrderFavoritesStore: CrudStore<WorkOrderSearchResult, 'id'>
	export let selectedPlantId: number
	export let plants: { id: number; name: string; code: string }[] = []
	export let workOrderTypes: { id: number; name: string }[] = []
	export let groups: { id: number; name: string }[] = []
	export let showFavoriteCheckbox: boolean = false
	export let perPageCount: number
	export let showOpenDocuments = true
	export let showClosedDocuments = false
	export let selectedWorkOrderIds: number[] = []
	export let favoriteWorkOrdersOnly: boolean = false
	export let dateRangeFilter: DateRangeFilterType = 'Last 30 Days'

	let workOrderSearchTable: Table<WorkOrderSearchResult>
	let loadingWorkOrders: boolean = false
	let rows: WorkOrderSearchResult[] = []
	let tablePageNumber: number = 1
	let totalItemsCount: number = 0
	let totalPages: number = 0
	let dates: { from: Date | null; to: Date | null }
	let selectedWorkOrderTypeId: number | null = null
	let selectedGroupId: number | null = null
	let orderColumnName: string | null = null // This is for the ss-pagination to know which column
	let orderDirection: string | null = null // This is for the ss-pagination to know which direction
	let workOrderCache = new Map<number, WorkOrderSearchResult[]>()
	let columns: Column[] = [
		{ property: 'favorite', name: translate('workOrderSearch.favorite', 'Favorite'), sortType: false, align: 'center', width: '1%' },
		{ property: 'plant', name: translate('workOrderSearch.plant', 'Plant'), sortType: false },
		{ property: 'id', name: translate('workOrderSearch.wO#', 'WO#'), numeric: true },
		{ property: 'workOrderType', name: translate('workOrderSearch.type', 'Type'), sortType: false },
		{ property: 'title', name: translate('workOrderSearch.title', 'Title') },
		{ property: 'scheduled', name: translate('workOrderSearch.scheduled', 'Scheduled') },
		{ property: 'dateCreated', name: translate('workOrderSearch.dateCreated', 'Date Created') },
		{ property: 'due', name: translate('workOrderSearch.due', 'Due') },
		{ property: 'internalNotes', name: translate('workOrderSearch.internalNotes', 'Internal Notes') },
		{ property: 'instructions', name: translate('workOrderSearch.instructions', 'Instructions') },
	]

	$: selectedPlant = plants.find(plant => plant.id === selectedPlantId) ?? null
	$: canViewWorkOrders = hasPermission('WORK_ORDERS_CAN_VIEW', selectedPlantId)
	$: canEditWorkOrders = hasPermission('WORK_ORDERS_CAN_EDIT', selectedPlantId)

	function changePlant(event: ComponentEvents<SiteAutocomplete<{ code: string; id: number; name: string } | null>>['change']) {
		const target = event.detail
		if (!target) {
			return
		}
		selectedPlantId = target.id
	}

	function getWorkOrderSearchResultsFromCache(pageNumber: number) {
		const workOrderCacheResult = workOrderCache.get(pageNumber)
		if (workOrderCacheResult) {
			rows = workOrderCacheResult
		} else {
			getWorkOrderSearchResults()
		}
	}

	function clearWorkOrderCache() {
		workOrderCache.clear()
	}

	function updateQueryResponseWithStoreData(queryResponse: WorkOrderSearchResult[]) {
		const out = queryResponse.map(queryResponseItem => {
			if ($workOrderFavoritesStore.updated[queryResponseItem.id]) {
				queryResponseItem.favorite = $workOrderFavoritesStore.updated[queryResponseItem.id].favorite
			}
			return queryResponseItem
		})
		return out
	}

	async function getWorkOrderSearchResults() {
		if (!canViewWorkOrders) {
			return
		}
		const searchFilter: WorkOrderSearchFilter = {
			plantId: selectedPlantId,
			from: dates.from,
			to: dates.to,
			openDocuments: showOpenDocuments,
			closedDocuments: showClosedDocuments,
			favoriteDocuments: favoriteWorkOrdersOnly,
			workOrderTypeId: selectedWorkOrderTypeId,
			groupId: selectedGroupId,
		}
		const response = (await apiFetch(
			mediator,
			{
				query: `#graphql
					query WorkOrders($filter: ManyWorkOrdersFilter, $pagination: PaginatedInput, $orderBy: WorkOrderOrderByFilter)  {
						workOrders(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
							data {
								favorite
								plant {
									id
									name
									code
								}
								id
								workOrderType {
									id
									name
								}
								title
								scheduled
								dateCreated
								due
								internalNotes
								instructions
							}
							info {
								totalItems
								totalPages
							}
						}
					}
				`,
				variables: {
					filter: searchFilter,
					pagination: {
						pageNumber: tablePageNumber,
						pageSize: perPageCount,
					},
					orderBy: {
						columnName: orderColumnName,
						direction: orderDirection,
					},
				},
			},
			'workOrders',
		)) as { data: WorkOrderSearchResult[]; info: { totalItems: number; totalPages: number } }
		const updatedResponse = updateQueryResponseWithStoreData(response.data)
		rows = updatedResponse ?? []
		totalItemsCount = response.info.totalItems
		totalPages = response.info.totalPages
		workOrderCache.set(tablePageNumber, rows)
	}

	function updateFavorite(event: MouseEvent, row: WorkOrderSearchResult) {
		event.stopPropagation()
		const foundWorkOrder = rows.find(workOrder => workOrder.id === row.id)
		if (foundWorkOrder) {
			foundWorkOrder.favorite = !foundWorkOrder.favorite
			workOrderFavoritesStore.update(foundWorkOrder)
			rows = rows
		}
	}

	async function saveInterfaceHistory() {
		await Promise.all([
			apiFetch(mediator, {
				query: mutations.setUserSetting,
				variables: {
					value: {
						category: 'Work Orders',
						name: 'Automatic WOs: show open documents',
						settingType: 'INTERFACE_HISTORY',
						newValue: booleanToString(showOpenDocuments),
					},
				},
			}),
			apiFetch(mediator, {
				query: mutations.setUserSetting,
				variables: {
					value: {
						category: 'Work Orders',
						name: 'Automatic WOs: show closed documents',
						settingType: 'INTERFACE_HISTORY',
						newValue: booleanToString(showClosedDocuments),
					},
				},
			}),
			apiFetch(mediator, {
				query: mutations.setUserSetting,
				variables: {
					value: {
						category: 'Work Orders',
						name: 'Automatic WOs: date range filter',
						settingType: 'INTERFACE_HISTORY',
						newValue: dateRangeFilter,
					},
				},
			}),
		])
	}

	onMount(async () => {
		loadingWorkOrders = true
		await getWorkOrderSearchResults()
		loadingWorkOrders = false
	})

	onDestroy(() => {
		saveInterfaceHistory()
	})
</script>

<div class="form-row">
	<div class="col-md-3 col-12">
		<SiteAutocomplete
			label={translate('workOrderSearch.plant', 'Plant')}
			bind:value={selectedPlant}
			options={plants}
			on:change={event => {
				changePlant(event)
				getWorkOrderSearchResults()
				clearWorkOrderCache()
			}}
		/>
	</div>
</div>

<div class="form-inline">
	<DateRange
		inline
		allowNone
		excludedRanges={[]}
		bind:dates
		defaultRange={dateRangeFilter}
		on:change={() => {
			getWorkOrderSearchResults()
			clearWorkOrderCache()
		}}
	/>

	<Select
		label={translate('workOrderSearch.wOType', 'WO Type')}
		labelClass="mr-1"
		labelParentClass="mr-3"
		options={workOrderTypes}
		bind:value={selectedWorkOrderTypeId}
		emptyText={translate('workOrderSearch.allWorkOrderTypes', 'All WO Types')}
		let:option={workOrderType}
		on:change={() => getWorkOrderSearchResults()}
	>
		<option value={workOrderType?.id}>{workOrderType?.name}</option>
	</Select>

	<Select
		label={translate('workOrderSearch.assignedToGroup', 'Assigned to Group')}
		labelClass="mr-1"
		labelParentClass="mr-3"
		options={groups}
		bind:value={selectedGroupId}
		emptyText={translate('workOrderSearch.allGroups', 'All Groups')}
		let:option={group}
		on:change={() => getWorkOrderSearchResults()}
	>
		<option value={group?.id}>{group?.name}</option>
	</Select>
</div>

<div class="form-row">
	<div class="col-12">
		{translate('workOrderSearch.status', 'Status:')}
		<Checkbox
			class="mx-1"
			bind:checked={showOpenDocuments}
			label={translate('workOrderSearch.open', 'Open')}
			inline
			on:change={() => {
				getWorkOrderSearchResults()
				clearWorkOrderCache()
			}}
		/>
		<Checkbox
			class="mx-1"
			bind:checked={showClosedDocuments}
			label={translate('workOrderSearch.closed', 'Closed')}
			inline
			on:change={() => {
				getWorkOrderSearchResults()
				clearWorkOrderCache()
			}}
		/>
	</div>
</div>
{#if showFavoriteCheckbox}
	<div class="form-row">
		<div class="col-12">
			<Checkbox
				bind:checked={favoriteWorkOrdersOnly}
				label={translate('workOrderSearch.onlyShowFavoriteWorkOrders', 'Only show favorite Work Orders')}
				inline
				on:change={() => {
					getWorkOrderSearchResults()
					clearWorkOrderCache()
				}}
			/>
		</div>
	</div>
{/if}

<div class="my-2">
	<Table
		responsive
		stickyHeader
		selectionEnabled
		columnHidingEnabled
		idProp="id"
		{rows}
		{columns}
		filterEnabled={false}
		{perPageCount}
		parentStyle="max-height: 50vh;"
		rowSelectionIdProp="id"
		selectionMode="SINGLE"
		bind:selectedRowIds={selectedWorkOrderIds}
		bind:currentPageNumber={tablePageNumber}
		{totalItemsCount}
		bind:this={workOrderSearchTable}
		let:row
		on:pageChange={event => {
			tablePageNumber = event.detail.pageNumber
			if (workOrderCache.has(tablePageNumber)) {
				getWorkOrderSearchResultsFromCache(tablePageNumber)
			} else {
				getWorkOrderSearchResults()
			}
		}}
		columnClickedMethod={async (column, direction) => {
			orderColumnName = column.property
			orderDirection = direction
			clearWorkOrderCache()
			getWorkOrderSearchResults()
		}}
	>
		<tr
			class:table-primary={selectedWorkOrderIds.includes(row.id)}
			on:click={() => {
				workOrderSearchTable.rowClick(row)
			}}
		>
			<Td property="favorite">
				<Button
					showLabel={false}
					size="xs"
					outline
					icon={{
						icon: 'star',
						color: 'warning',
						prefix: row.favorite ? 'fas' : 'far',
					}}
					disabled={!canEditWorkOrders}
					on:click={event => updateFavorite(event, row)}
				/>
			</Td>
			<Td property="plant">
				{row.plant.name}
			</Td>
			<Td property="id">
				{row.id}
			</Td>
			<Td property="workOrderType">
				{row.workOrderType.name}
			</Td>
			<Td property="title">
				{row.title ?? ''}
			</Td>
			<Td property="scheduled">
				{new Date(row.scheduled)?.toLocaleString() ?? ''}
			</Td>
			<Td property="dateCreated">
				{new Date(row.dateCreated)?.toLocaleString() ?? ''}
			</Td>
			<Td property="due">
				{row.due ? new Date(row.due)?.toLocaleString() : ''}
			</Td>
			<Td property="internalNotes">
				{row.internalNotes ?? ''}
			</Td>
			<Td property="instructions">
				{row.instructions ?? ''}
			</Td>
		</tr>
		<svelte:fragment slot="no-rows">
			{#if loadingWorkOrders}
				<tr>
					<td
						colspan="14"
						class="text-center"
					>
						{translate('workOrderSearch.loading', 'Loading')}...
					</td>
				</tr>
			{:else}
				<tr>
					<td
						colspan="14"
						class="text-center"
					>
						{translate('workOrderSearch.noWorkOrdersFound', 'No Work Orders Found.')}
					</td>
				</tr>
			{/if}
		</svelte:fragment>
		<svelte:fragment slot="footer">
			<div class="p-2" />
		</svelte:fragment>
	</Table>
</div>

<slot />
