export default {
    "name": "AlertPreview",
    "kind": "HoudiniQuery",
    "hash": "6fc6b9ce9d6edcedaa7cbdc9a983c2433749960e366f052725f0e47fad7bc9e0",

    "raw": `query AlertPreview($input: AlertPreviewInput!) {
  alertPreview(input: $input) {
    body
    emailAddress
    error
    title
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "alertPreview": {
                "type": "AlertPreview",
                "keyRaw": "alertPreview(input: $input)",

                "selection": {
                    "fields": {
                        "body": {
                            "type": "String",
                            "keyRaw": "body",
                            "nullable": true,
                            "visible": true
                        },

                        "emailAddress": {
                            "type": "String",
                            "keyRaw": "emailAddress",
                            "visible": true
                        },

                        "error": {
                            "type": "String",
                            "keyRaw": "error",
                            "nullable": true,
                            "visible": true
                        },

                        "title": {
                            "type": "String",
                            "keyRaw": "title",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "AlertPreviewInput"
        },

        "types": {
            "SummaryAlertPreviewInput": {
                "date": "Date",
                "id": "PositiveInt"
            },

            "NewAlertSubscription": {
                "active": "Boolean",
                "analysisId": "PositiveInt",
                "body": "String",
                "description": "String",
                "emailAddress": "String",
                "expirationHourOffset": "Int",
                "groupId": "PositiveInt",
                "investigationTriggerId": "PositiveInt",
                "locationId": "PositiveInt",
                "method": "AlertMethod",
                "plantId": "PositiveInt",
                "productId": "PositiveInt",
                "rank": "PositiveInt",
                "requireAcceptable": "Boolean",
                "requireFailed": "Boolean",
                "requireMarginal": "Boolean",
                "requireMissing": "Boolean",
                "scheduleId": "PositiveInt",
                "sendAcceptable": "Boolean",
                "sendFailed": "Boolean",
                "sendMarginal": "Boolean",
                "supplierId": "PositiveInt",
                "templateId": "PositiveInt",
                "timing": "AlertTiming",
                "title": "String",
                "type": "AlertType",
                "userAccountId": "PositiveInt",
                "workOrderTypeId": "PositiveInt"
            },

            "AlertPreviewInput": {
                "documentId": "PositiveInt",
                "schedule": "SummaryAlertPreviewInput",
                "subscription": "NewAlertSubscription",
                "subscriptionId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=6f162a030f9a6982fdd26c31652fe33419be911b236b02bb87991e9a393c9733";