export default {
    "name": "GetValueAcceptabilities",
    "kind": "HoudiniQuery",
    "hash": "cdaaa01c2dc7a535e7fd58e9f4788b337d498f7bff1b2248a6b0448019958fae",

    "raw": `query GetValueAcceptabilities($options: [GetValueAcceptability!]!) {
  getValueAcceptabilities(options: $options) {
    acceptability
    analysisOptionId
    plantId
    productBatchId
    productId
    productionVolume
    currentResult
    severityClassId
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "getValueAcceptabilities": {
                "type": "ValueAcceptability",
                "keyRaw": "getValueAcceptabilities(options: $options)",

                "selection": {
                    "fields": {
                        "acceptability": {
                            "type": "Acceptability",
                            "keyRaw": "acceptability",
                            "visible": true
                        },

                        "analysisOptionId": {
                            "type": "PositiveInt",
                            "keyRaw": "analysisOptionId",
                            "visible": true
                        },

                        "plantId": {
                            "type": "PositiveInt",
                            "keyRaw": "plantId",
                            "nullable": true,
                            "visible": true
                        },

                        "productBatchId": {
                            "type": "PositiveInt",
                            "keyRaw": "productBatchId",
                            "nullable": true,
                            "visible": true
                        },

                        "productId": {
                            "type": "PositiveInt",
                            "keyRaw": "productId",
                            "nullable": true,
                            "visible": true
                        },

                        "productionVolume": {
                            "type": "NonNegativeInt",
                            "keyRaw": "productionVolume",
                            "nullable": true,
                            "visible": true
                        },

                        "currentResult": {
                            "type": "String",
                            "keyRaw": "currentResult",
                            "visible": true
                        },

                        "severityClassId": {
                            "type": "PositiveInt",
                            "keyRaw": "severityClassId",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "options": "GetValueAcceptability"
        },

        "types": {
            "GetValueAcceptability": {
                "analysisOptionId": "PositiveInt",
                "currentResult": "String",
                "plantId": "PositiveInt",
                "productBatchId": "PositiveInt",
                "productId": "PositiveInt",
                "productionVolume": "NonNegativeInt",
                "severityClassId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=6f75a7acdcd3db890993c673a82b33d48462fee87ef1d0820388ef48ddef99d1";