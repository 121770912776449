import template from './tag-selection.html'
import { v4 as uuid } from '@lukeed/uuid'

//Ractive components
import makeSelect from '@isoftdata/select'
import makeCheckbox from '@isoftdata/checkbox'
import makeInput from '@isoftdata/input'
import makeButton from '@isoftdata/button'
import makeTable from '@isoftdata/table'

const tagTemplate = {
	// EntityTag fields
	active: true,
	entityType: null,
	id: null,
	name: '',
	originalName: '',
	// client meta fields
	// inUse: false,
	add: true,
	remove: false,
	deleted: false,
}

export default function createComponent() {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		isolated: true,
		data() {
			return {
				tags: [],
				selectedTagIndex: null,
				tagSelectionDisable: false,
				showHeader: true,
				title: 'Tags',
				entityType: null,
				selectedObjectActiveTags: [],
				canEditGlobalFields: false,
			}
		},
		components: {
			itSelect: makeSelect({ twoway: true }),
			itCheckbox: makeCheckbox(),
			itInput: makeInput({ twoway: true }),
			itButton: makeButton(),
			itTable: makeTable({ stickyHeader: true }),
		},
		attributes: {
			required: [ 'tags' ],
			optional: [ 'tagSelectionDisable', 'showHeader', 'title', 'entityType', 'selectedObjectActiveTags', 'selectedTagIndex' ],
		},
		computed: {
			selectedObjectTags() {
				const tags = this.get('tags')
				const selectedObjectActiveTags = this.get('selectedObjectActiveTags') || []
				return tags.filter(tag => selectedObjectActiveTags.some(t => {
					if ((t.id === tag.id) && tag.id !== null) {
						return true
					}
					if (t.uuid === tag.uuid) {
						return true
					}
					return false
				}))
			},
			mappedTags() {
				const ractive = this
				const tags = ractive.get('tags') || []
				const indexedTags = tags.map((tag, originalIndex) => ({ ...tag, originalIndex }))
				const selectedObjectTags = ractive.get('selectedObjectTags') || []
				return indexedTags.map(tag => {
					return {
						...tag,
						inUse: selectedObjectTags.some(t => t.uuid === tag.uuid),
					}
				})
			},
		},
		tagSelectionNameCounts() {
			const ractive = this
			const tags = ractive.get(`mappedTags`)
			// Do not allow them to create a tag with a name equal to another tag's originalName or current name
			const duplicateCounts = tags.reduce((acc, { name, originalName }) => {
				acc[name] = (acc[name] || 0) + 1
				// If the name and originalName are different, we need to check both, because we tag maps key off of the originalName
				// If a new tag is created with the same name as another tag's originalName, it will overwrite the other tag , even if its visible name is different
				// I don't think this will happen often at all, but because it's possible, I want to prevent it.
				if (originalName && originalName !== name) {
					acc[originalName] = (acc[originalName] || 0) + 1
				}
				return acc
			}, {})
			return duplicateCounts
		},
		tagSelectionHasDuplicateTagNames() {
			const ractive = this

			return Object.values(ractive.tagSelectionNameCounts()).some(count => count > 1)
		},
		tagSelectionTagIsDuplicate({ name, originalName }) {
			const ractive = this
			const nameCounts = ractive.tagSelectionNameCounts()
			return nameCounts[name] > 1 || (originalName && originalName !== name && nameCounts[originalName] > 1)
		},
		oninit() {
			const ractive = this

			// #region tagSelection Modal
			ractive.on('tag-clicked', (context, entityType, index) => { //This selects the tag row
				if (ractive.get('tagSelectionDisable')) {
					return
				}
				ractive.set({ selectedTagIndex: index })
			})

			// Puts the tag in the selectedObjectActiveTags array
			ractive.on('use-tag-clicked', (context, index) => {
				const selectedObjectActiveTags = ractive.get('selectedObjectActiveTags') || []
				const tags = ractive.get('tags')
				const tag = tags[index]
				const tagIndex = selectedObjectActiveTags.findIndex(t => t.uuid === tag.uuid)
				if (tagIndex > -1) {
					selectedObjectActiveTags.splice(tagIndex, 1)
				} else {
					selectedObjectActiveTags.push(tag)
				}

				ractive.set({
					selectedObjectActiveTags,
				})
			})
			// updates the tag name
			ractive.on('tag-name-changed', (context, index) => {
				const tagKeypath = `tags.${index}`
				ractive.set({
					[`${tagKeypath}.name`]: context.node.value,
					[`${tagKeypath}.dirty`]: true,
				})
			})
			// deletes the tag
			ractive.on('delete-tag', (context, entityType, index, isNew) => {
				// I could make this a modal so they can decide between Cancel, Delete, and Remove, but I don't think it's necessary for now and that's a lot of modals.
				if (confirm('Are you sure you want to permanently delete this tag? If you want to remove it from this rule, use the checkbox instead. \r\n\r Note: After saving, this tag will be removed from all items at all plants.')) {
					if (isNew && (index || index === 0)) {
						ractive.splice('tags', index, 1)
					} else if (index || index === 0) {
						ractive.set({
							[`tags.${index}.inUse`]: false,
							[`tags.${index}.add`]: false,
							[`tags.${index}.remove`]: false,
							[`tags.${index}.deleted`]: true,
						})
					}
					ractive.set({
						[`selected${entityType.slice(0, 1)}${entityType.slice(1).toLowerCase()}TagIndex`]: null,
						'selectedTagIndex': null,
					})
				}
			})
			// adds a new tag
			ractive.on('add-tag', async(context, entityType) => {
				const newTag = {
					...tagTemplate,
					entityType,
					uuid: uuid(),
				}
				await ractive.push(`tags`, {
					...newTag,
				})
				//Add something here to auto attach the tag to the list of active tags for the selected object
				ractive.find(`#${entityType}-tag-name-${ractive.get(`tags`).length - 1}`)?.focus()
			})

			// #endregion
		},
	})
}
