<script lang="ts">
	import type { ConstraintType$options, CheckApplicableThresholds$input, CheckApplicableThresholds$result, BoundaryType$options } from '$houdini'
	import type { ComponentProps } from 'svelte'
	import type { IconName } from '@fortawesome/fontawesome-common-types'
	import type { i18n } from 'i18next'
	import Table, { Td, type Column, type UuidRowProps } from '@isoftdata/svelte-table'
	import Icon from '@isoftdata/svelte-icon'
	import { graphql } from '$houdini'
	import valueAcceptabilityMap, { boundaryTypeToResultStatus } from 'utility/value-acceptability-map'
	import { getContext } from 'svelte'

	const { t: translate } = getContext<i18n>('i18next')

	// Analysis Management will need more columns, so add those (with a prop to show them, default them hidden) when we rewrite it in Svelte
	const columns: Array<Column> = [
		{
			property: 'boundaryType',
			name: translate('thresholds.thresholdColumn', 'Threshold'),
			title: translate('thresholds.thresholdColumnTitle', 'How this threshold applies to a value'),
			sortType: false,
		},
		{
			property: 'constraint',
			name: '',
			icon: 'question',
			title: translate('thresholds.constraintColumnTitle', 'Condition for the threshold to be violated'),
			align: 'center',
			sortType: false,
		},
		{
			property: 'value',
			name: translate('thresholds.valueColumn', 'Value'),
			title: translate('thresholds.valueColumnTitle', 'The value of the threshold'),
			numeric: true,
			sortType: false,
		},
		{
			property: 'violated',
			name: translate('thresholds.volatedColumn', 'Violated?'),
			title: translate('thresholds.violatedColumnTitle', 'Whether this threshold was triggered by the entered value'),
			sortType: false,
			defaultSortColumn: true,
			defaultSortDirection: 'DESC',
		},
		{
			property: 'applicability',
			name: translate('thresholds.applicableColumn', 'Applicable?'),
			title: translate('thresholds.applicableColumnTitle', 'When a threshold does not apply, this field will say why'),
			sortType: false,
		},
	]
	const applicabilityMap = Object.freeze({
		APPLICABLE: translate('thresholds.applicable', 'Applicable'),
		DEACTIVATED: translate('thresholds.deactivated', 'Deactivated'),
		WRONG_BATCH: translate('thresholds.wrongBatch', 'Wrong Batch'),
		WRONG_PLANT: translate('thresholds.wrongPlant', 'Wrong Plant'),
		WRONG_PRODUCT: translate('thresholds.wrongProduct', 'Wrong Product'),
		WRONG_SEVERITY_CLASS: translate('thresholds.wrongSeverity', 'Wrong Severity Class'),
	})

	let thresholds: CheckApplicableThresholds$result['checkApplicableThresholds'] = []
	let table: Table<UuidRowProps & CheckApplicableThresholds$result['checkApplicableThresholds'][number]>
	let columnInfo: ComponentProps<typeof table>['columnInfo']

	const constraintMap: Record<ConstraintType$options, { icon: IconName; title: string }> = Object.freeze({
		MINIMUM: { icon: 'greater-than', title: translate('thresholds.greaterThan', 'Greater Than') },
		MAXIMUM: { icon: 'less-than', title: translate('thresholds.lessThan', 'Less Than') },
		NOT_EQUAL: { icon: 'not-equal', title: translate('thresholds.notEqualTo', 'Not Equal To') },
		NONE: { icon: 'equals', title: translate('thresholds.equalTo', 'Equal To') },
	})

	function getBoundaryTypeIcon(boundaryType: BoundaryType$options): ComponentProps<Icon> {
		const acceptability = valueAcceptabilityMap.get(boundaryTypeToResultStatus[boundaryType])!
		return {
			fixedWidth: true,
			icon: acceptability.icon,
			title: acceptability.label,
			class: `text-${acceptability.colorClass}`,
		}
	}

	export async function getData(variables: CheckApplicableThresholds$input) {
		table?.setColumnVisibility('applicability', !variables.onlyApplicable)
		await checkApplicableThresholds.fetch({
			variables: {
				...variables,
				currentResult: variables.currentResult.toString(),
			},
		})
		// Re-run the query with onlyApplicable = false if the given value is not in the list of applicable thresholds
		if (variables.currentResult && variables.onlyApplicable && !$checkApplicableThresholds.data?.checkApplicableThresholds.some(threshold => threshold.choice === variables.currentResult)) {
			await checkApplicableThresholds.fetch({
				variables: {
					...variables,
					onlyApplicable: false,
					currentResult: variables.currentResult.toString(),
				},
			})
			if ($checkApplicableThresholds.data?.checkApplicableThresholds.length) {
				table?.setColumnVisibility('applicability', true)
			}
		} else if ($columnInfo?.['applicability'].visible && variables.onlyApplicable) {
			table?.setColumnVisibility('applicability', false)
		}
		thresholds = $checkApplicableThresholds.data?.checkApplicableThresholds ?? []
	}

	const checkApplicableThresholds = graphql(`
		query CheckApplicableThresholds(
			$analysisOptionId: PositiveInt!
			$currentResult: String!
			$onlyApplicable: Boolean
			$productBatchId: PositiveInt
			$productId: PositiveInt
			$severityClassId: PositiveInt
			$plantId: PositiveInt
			$boundaryTypes: [BoundaryType!]
		) {
			checkApplicableThresholds(
				analysisOptionId: $analysisOptionId
				currentResult: $currentResult
				onlyApplicable: $onlyApplicable
				productBatchId: $productBatchId
				productId: $productId
				severityClassId: $severityClassId
				plantId: $plantId
				boundaryTypes: $boundaryTypes
			) {
				id
				choice
				constraint
				boundaryType
				applicability
				violated
				severityClassId
				plantId
				productId
			}
		}
	`)
</script>

<Table
	bind:this={table}
	bind:columnInfo
	{columns}
	rows={thresholds}
	parentStyle="background-color: white; width: fit-content;"
	responsive
	let:row
>
	<tr>
		<Td property="boundaryType">
			<span class="text-nowrap">
				<Icon {...getBoundaryTypeIcon(row.boundaryType)} />
				{getBoundaryTypeIcon(row.boundaryType).title}
			</span>
		</Td>
		<Td property="constraint">
			<Icon
				fixedWidth
				{...constraintMap[row.constraint]}
			/>
		</Td>
		<Td property="value">
			<span class="text-nowrap">{row.choice}</span>
		</Td>
		<Td property="violated">
			{#if row.violated === 'TRUE'}
				<Icon
					fixedWidth
					icon="check"
					class="text-success"
				/>
				{translate('thresholds.violated', 'Yes')}
			{:else if row.violated === 'FALSE'}
				<Icon
					fixedWidth
					icon="xmark"
					class="text-danger"
				/>
				{translate('thresholds.notViolated', 'No')}
			{:else if row.violated === 'NOT_CALCULATED'}
				<Icon
					fixedWidth
					icon="question"
					class="text-warning"
				/>
				{translate('thresholds.notCalculated', 'Not Calculated')}
			{/if}
		</Td>
		<Td property="applicability">
			<span class="text-nowrap">
				<Icon
					class={row.applicability === 'APPLICABLE' ? 'text-success' : 'text-danger'}
					icon={row.applicability === 'APPLICABLE' ? 'check' : 'xmark'}
				></Icon>
				{applicabilityMap[row.applicability]}
			</span>
		</Td>
	</tr>
	<tr slot="no-rows">
		<td
			colspan={columns.length}
			class="text-center"
			>{#if $checkApplicableThresholds.fetching}
				<Icon isLoading></Icon> {translate('thresholds.loadingThresholds', 'Loading...')}
			{:else}
				{translate('thresholds.noApplicableThresholds', 'No applicable thresholds.')}
			{/if}</td
		>
	</tr>
</Table>
