export default {
    "name": "WoAnalysisOptionData",
    "kind": "HoudiniFragment",
    "hash": "e7e90b995065e82f10809bad63f77b911cb76b7f30ed55f23098c8e7ddfcc671",

    "raw": `fragment WoAnalysisOptionData on AnalysisOption {
  active
  id
  option
  valueType
  thresholdType
  rank
  defaultType
  defaultValue
  unit
  __typename
}
`,

    "rootType": "AnalysisOption",

    "selection": {
        "fields": {
            "active": {
                "type": "Boolean",
                "keyRaw": "active",
                "visible": true
            },

            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "option": {
                "type": "String",
                "keyRaw": "option",
                "visible": true
            },

            "valueType": {
                "type": "ValueType",
                "keyRaw": "valueType",
                "visible": true
            },

            "thresholdType": {
                "type": "ThresholdType",
                "keyRaw": "thresholdType",
                "visible": true
            },

            "rank": {
                "type": "NonNegativeInt",
                "keyRaw": "rank",
                "visible": true
            },

            "defaultType": {
                "type": "DefaultType",
                "keyRaw": "defaultType",
                "visible": true
            },

            "defaultValue": {
                "type": "String",
                "keyRaw": "defaultValue",
                "nullable": true,
                "visible": true
            },

            "unit": {
                "type": "String",
                "keyRaw": "unit",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=0afc4340d9c912b80275002d8608b44e9f3e0a29196828c96519f3f9485b6305";