export default {
    "name": "WorkOrderTypesAndPlants",
    "kind": "HoudiniQuery",
    "hash": "fa516ff25abf9890f16d69476ca9937a7c82528b7db7a0d438ccc08873b82f27",

    "raw": `query WorkOrderTypesAndPlants($pagination: PaginatedInput) {
  workOrderTypes(pagination: $pagination) {
    data {
      ...WorkOrderTypeData
      id
    }
  }
  plants(pagination: $pagination) {
    data {
      id
      code
      name
    }
  }
}

fragment WorkOrderTypeData on WorkOrderType {
  daysTillDue
  daysTillVerificationDue
  defaultAnalysisId
  defaultGroupId
  defaultReport
  defaultVerificationGroupId
  id
  inUseAtPlantIDs
  name
  productBatchRequired
  showDue
  showLocation
  showLocationDescription
  showProduct
  showSamplingDetail
  showTestingDetail
  titleRequired
  verificationRequired
  visibleGroupId
  __typename
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "workOrderTypes": {
                "type": "WorkOrderTypeResponse",
                "keyRaw": "workOrderTypes(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "WorkOrderType",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "daysTillDue": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "daysTillDue",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "daysTillVerificationDue": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "daysTillVerificationDue",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultAnalysisId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "defaultAnalysisId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultGroupId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "defaultGroupId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "defaultReport": {
                                        "type": "String",
                                        "keyRaw": "defaultReport",
                                        "visible": true
                                    },

                                    "defaultVerificationGroupId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "defaultVerificationGroupId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "inUseAtPlantIDs": {
                                        "type": "PositiveInt",
                                        "keyRaw": "inUseAtPlantIDs",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "productBatchRequired": {
                                        "type": "ProductBatchRequired",
                                        "keyRaw": "productBatchRequired",
                                        "visible": true
                                    },

                                    "showDue": {
                                        "type": "Boolean",
                                        "keyRaw": "showDue",
                                        "visible": true
                                    },

                                    "showLocation": {
                                        "type": "Boolean",
                                        "keyRaw": "showLocation",
                                        "visible": true
                                    },

                                    "showLocationDescription": {
                                        "type": "Boolean",
                                        "keyRaw": "showLocationDescription",
                                        "visible": true
                                    },

                                    "showProduct": {
                                        "type": "ShowProduct",
                                        "keyRaw": "showProduct",
                                        "visible": true
                                    },

                                    "showSamplingDetail": {
                                        "type": "Boolean",
                                        "keyRaw": "showSamplingDetail",
                                        "visible": true
                                    },

                                    "showTestingDetail": {
                                        "type": "Boolean",
                                        "keyRaw": "showTestingDetail",
                                        "visible": true
                                    },

                                    "titleRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "titleRequired",
                                        "visible": true
                                    },

                                    "verificationRequired": {
                                        "type": "Boolean",
                                        "keyRaw": "verificationRequired",
                                        "visible": true
                                    },

                                    "visibleGroupId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "visibleGroupId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "WorkOrderTypeData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "plants": {
                "type": "PlantResponse",
                "keyRaw": "plants(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Plant",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=0a8a28efe8146cb2357f64d292e558123c3f1cf5aaeaa3ffb6bca15770e9e723";