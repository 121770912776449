export default {
    "name": "GetDocumentsByAssociation",
    "kind": "HoudiniQuery",
    "hash": "de49794de66300a617b871bba336b841df1af213fd3f23f144b373e2450ba876",

    "raw": `query GetDocumentsByAssociation($lookups: [DocumentLookupInput!]!) {
  getDocumentsByAssociation(lookups: $lookups) {
    lookup {
      analysisId
      analysisOptionId
      plantId
      productId
      severityClassId
    }
    version {
      file {
        mimeType
        path
        name
        id
      }
      id
    }
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "getDocumentsByAssociation": {
                "type": "DocumentAssociationLookupResult",
                "keyRaw": "getDocumentsByAssociation(lookups: $lookups)",

                "selection": {
                    "fields": {
                        "lookup": {
                            "type": "DocumentLookup",
                            "keyRaw": "lookup",

                            "selection": {
                                "fields": {
                                    "analysisId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "analysisId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "analysisOptionId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "analysisOptionId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "productId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "productId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "severityClassId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "severityClassId",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "version": {
                            "type": "DocumentFile",
                            "keyRaw": "version",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "file": {
                                        "type": "File",
                                        "keyRaw": "file",

                                        "selection": {
                                            "fields": {
                                                "mimeType": {
                                                    "type": "String",
                                                    "keyRaw": "mimeType",
                                                    "visible": true
                                                },

                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "lookups": "DocumentLookupInput"
        },

        "types": {
            "DocumentLookupInput": {
                "analysisId": "PositiveInt",
                "analysisOptionId": "PositiveInt",
                "plantId": "PositiveInt",
                "productId": "PositiveInt",
                "severityClassId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=22daaa0e86e2e1821c1e6d7b87ebbd3e879996d25cb29cf6f80ee3ae44bfc249";