import svelteComponent from './Plant.svelte'
import pProps from 'p-props'
import apiFetch from 'utility/api-fetch'
import Timezone from 'timezone-enum'
import { v4 as uuid } from '@lukeed/uuid'
import { getSession } from 'stores/session'
import makeCrudStore from '@isoftdata/svelte-store-crud'

const gqlPagninationAllPages = { pagination: { pageSize: 0, pageNumber: 1 } }

export default ({ mediator, stateRouter, hasPermission, i18next: { t } }) => {
	stateRouter.addState({
		name: 'app.configuration.plant',
		route: 'plant',
		querystringParameters: [ 'lastResetTime', 'lastSavedTime' ],
		defaultParameters: {
			lastResetTime: null,
			lastSavedTime: null,
		},
		template: {
			svelte: true,
			component: svelteComponent,
			options: {
				// these are the options passed to the svelte component
			},
		},
		canLeaveState(svelte) {
			return !svelte.hasUnsavedChanges || confirm(t('common:canLeaveState', 'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.'))
		},
		async resolve(_data, parameters) {
			const { authorizedPlantIDs, siteId } = getSession()

			if (!authorizedPlantIDs.some(id => hasPermission('CONFIGURATION_CAN_CONFIGURE_PLANTS', id))) {
				// If you dont have permission to view at least one plant, redirect to home
				throw {
					redirectTo: { name: 'app.home' },
				}
			}

			const { plants, states, tags } = await pProps({
				plants: apiFetch(
					mediator,
					{
						query: queries.plants,
						variables: {
							...gqlPagninationAllPages,
						},
					},
					'plants.data',
				),
				states: apiFetch(
					mediator,
					{
						query: queries.states,
					},
					'states',
				),
				tags: apiFetch(
					mediator,
					{
						query: queries.tags,
						variables: {
							filter: {
								active: true,
								entityTypes: 'PLANT',
							},
						},
					},
					'entityTags',
				),
			})
			const loggedInPlantId = siteId ?? null
			const nonPrivatePlants = plants.reduce((acc, plant) => {
				if (!plant.private) {
					acc.push(plant)
				}
				if (loggedInPlantId === plant.id && plant.private) {
					acc.push(plant)
				}
				return acc
			}, [])

			const mappedTags = tags.map(tag => {
				return {
					...tag,
					uuid: uuid(),
				}
			})

			const mappedPlants = nonPrivatePlants.map(plant => {
				return {
					...plant,
					tags: mappedTags.filter(tag => plant.tags?.some(plantTag => plantTag.id === tag.id)) || [],
					uuid: uuid(),
					deleted: false,
					dirty: false,
				}
			})

			const selectedPlantIndex = mappedPlants.findIndex(plant => plant.id === loggedInPlantId) ?? 0

			return {
				plants: mappedPlants,
				timezones: Object.values(Timezone),
				tags: mappedTags,
				states,
				selectedPlantUuid: mappedPlants[selectedPlantIndex].uuid,
				hasPermission,
				queries,
				mutations,
				tagCrudStore: makeCrudStore('uuid'),
				// these are the options passed to the svelte component
			}
		},
	})
}

const tagReturnData = `#graphql
    id
    name
    entityType
    active
`

const plantReturnData = `#graphql
	id
	name
	code
	private
    street
    city
    state
    zip
    country
    phone
    fax
    timezone
    tags {
        ${tagReturnData}
    }
	getPlantUsageCount {
		analyseCount
		productCount
		tagCount
	}
`

const queries = {
	plants: `#graphql
		query Plants($pagination: PaginatedInput) {
		  plants(pagination: $pagination) {
			data {
				${plantReturnData}
			}
		  }
		}
	`,
	states: `#graphql
        query States {
            states {
                abbreviation: stateAbbreviation
                name: stateName
            }
        }
    `,
	tags: `#graphql
        query EntityTags($filter: EntityTagFilter!) {
            entityTags(filter: $filter) {
                ${tagReturnData}
            }
        }
    `,
	locationData: `#graphql
		query getCityStateAbbvCountryFromZip($zipcode: String!) {
			getCityStateAbbvCountryFromZip(zipcode: $zipcode) {
				city
				country
				stateAbbreviation
			}
		}
	`,
}

const mutations = {
	createPlants: `#graphql
        mutation CreatePlants($plants: [PlantCreation!]!) {
            createPlants(plants: $plants) {
                ${plantReturnData}
            }
        }
    `,
	updatePlants: `#graphql
        mutation UpdatePlants($plants: [PlantUpdate!]!) {
            updatePlants(plants: $plants) {
                ${plantReturnData}
            }
        }
    `,
	deletePlants: `#graphql
        mutation DeletePlants($ids: [ID!]!) {
            deletePlants(ids: $ids)
        }
    `,
	createTags: `#graphql
        mutation CreateEntityTags($input: [NewEntityTag!]!) {
            createEntityTags(input: $input) {
                active
                entityType
                id
                name
            }
        }
    `,
	updateTags: `#graphql
        mutation UpdateEntityTags($input: [EntityTagUpdate!]!) {
            updateEntityTags(input: $input) {
                id
            }
        }
    `,
	deleteTags: `#graphql
        mutation DeleteEntityTags($ids: [PositiveInt!]!) {
            deleteEntityTags(ids: $ids)
        }
    `,
}
