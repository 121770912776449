<script lang="ts">
	import type { SvelteAsr, i18n } from 'types/common'
	import type { SaveResetProps } from './configuration'

	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	import SaveResetButton from '@isoftdata/svelte-save-reset-button'

	import { getContext, type ComponentProps } from 'svelte'

	export let tabs: ComponentProps<AsrNavTabBar>['tabs']
	export let asr: SvelteAsr
	export let saveResetProps: SaveResetProps

	const { t: translate } = getContext<i18n>('i18next')
</script>

<div class="card">
	<div class="card-header">
		<div class="d-flex justify-content-between flex-wrap">
			<h4 class="h4">
				<i class="fas fa-cog fa-fw mr-1"></i><span class="d-none d-sm-inline-block">{translate('common:configuration', 'Configuration')}</span>
			</h4>
			{#if $saveResetProps}
				<SaveResetButton {...$saveResetProps}></SaveResetButton>
			{/if}
		</div>
		<AsrNavTabBar
			{tabs}
			{asr}
			breakpoint="md"
		/>
	</div>
	<div class="card-body p-0 m-3">
		<uiView></uiView>
	</div>
</div>
