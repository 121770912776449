<script lang="ts">
	import type { Writable } from 'svelte/store'
	import type { AddRemoveStore } from 'stores/add-remove-store'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { i18n } from 'i18next'
	import type { SvelteAsr } from 'types/common'

	import Input from '@isoftdata/svelte-input'
	import Textarea from '@isoftdata/svelte-textarea'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import ExpandableBadge from 'components/ExpandableBadge.svelte'
	import TagSelection, { type MetaTag } from 'components/TagSelection.svelte'
	import { computeLocationName, LOCATION_MAX_LENGTH, type LocationNode, type ProcessZone, type ProductProximity, type SeverityClass } from '../../Locations.svelte'

	import { getContext, onMount } from 'svelte'
	import { klona } from 'klona'
	import { upsertIntoTree } from '@isoftdata/svelte-table'

	const { t: translate } = getContext<i18n>('i18next')
	// This function can return like 87 data types, and in order to pass it to the severity class placeholder input, it has to be a string
	const loadingSeverityClassPlaceholder = translate('location.loadingSeverityClassPlaceholder', 'Loading Severity Class...') as string

	export let asr: SvelteAsr
	export let tagCrudStore: CrudStore<MetaTag, 'uuid'>
	export let tagAddRemoveStore: AddRemoveStore
	export let locationCrudStore: CrudStore<LocationNode, 'uuid'>
	export let allowEditing: boolean
	export let locations: Writable<Array<LocationNode>>
	export let delimiter: string
	export let defaultSeverityClass: SeverityClass
	export let severityClassAssignments: Record<number, Record<number, SeverityClass>>

	export let expandedCard: Writable<'DETAILS' | 'TAGS'>

	export let processZones: ProcessZone[]
	export let productProximities: ProductProximity[]
	/** All location tags*/
	export let tags: MetaTag[]

	export let selectedLocation: Writable<LocationNode & { description: string; tags: MetaTag[] }>

	// If they reload the page when an unsaved location is selected, they may end up here without a selected location. Redirect them if that happens.
	if (!$selectedLocation) {
		asr.go('app.locations.list', {}, { inherit: true })
	}

	$: $selectedLocation.tags ??= []
	$: if (!$selectedLocation.description) {
		$selectedLocation.description = ''
	}

	/** The location code input. May be undefined if the card is closed.*/
	let codeInput: HTMLInputElement | undefined
	let parentLocationName = $selectedLocation?.parent ? computeLocationName($selectedLocation.parent, delimiter) : ''
	let codeIsValid: boolean = true

	function updateLocation(location: LocationNode) {
		if (location.id) {
			locationCrudStore.update(location)
		} else {
			locationCrudStore.create(location)
		}

		$locations = upsertIntoTree($locations, location, 'uuid', 'parentLocationUuid')
	}

	function getSeverityClass(location: LocationNode) {
		if (!location.productProximity || !location.processZone) {
			return defaultSeverityClass
		}
		return severityClassAssignments[location.processZone.id][location.productProximity.id] ?? defaultSeverityClass
	}

	/** Run this after you already updated the severity class */
	function cascadeChangeToChildren(location: LocationNode, key: 'processZone' | 'productProximity', skipConfirm = false) {
		if (!location.children.length) {
			return
		}
		const theOtherKey = key === 'processZone' ? 'productProximity' : 'processZone'
		// Ternary between two strings to change literally one word because ✨ Translation ✨
		const message =
			key === 'processZone'
				? translate('location.confirmCascadeProcessZone', 'The zone of the location was updated, would you like to apply this change to all sub-locations?')
				: translate('location.confirmCascadeProductPoximity', 'The proximity of the location was updated, would you like to apply this change to all sub-locations?')
		// TODO: desktop buttons say "Cascade Changes" and "Don't Update Sub-Locations" but this'll do for now
		if (!skipConfirm && !confirm(message)) {
			return
		}
		location.children.forEach(child => {
			child[key] = location[key]
			// Update the Severity Class too. Maybe we just do this when they load it in the details pane?
			if (child[theOtherKey]?.id === location[theOtherKey]?.id) {
				child.severityClass = klona(location.severityClass)
			} else {
				child.severityClass = getSeverityClass(child)
			}
			updateLocation(child)
			cascadeChangeToChildren(child, key, true)
		})
	}

	onMount(() => {
		// I can't do this in list resolve, because it depends on the tag array from the location resolve, which I can't access there.
		if ($selectedLocation.tags.some(tag => tag.id && !tag.uuid)) {
			$selectedLocation.tags = $selectedLocation.tags.reduce((acc, tag) => {
				if (tag.uuid) {
					acc.push(tag)
				} else if (tag.id) {
					const matchingTag = tags.find(t => t.id === tag.id)
					if (matchingTag) {
						acc.push({
							...tag,
							uuid: matchingTag.uuid,
						})
					}
				}
				return acc
			}, new Array<MetaTag>())
		}
	})
</script>

<div class="h-100 d-flex flex-column mt-2 mt-xl-0">
	<CollapsibleCard
		bodyShown={$expandedCard === 'DETAILS'}
		entireHeaderToggles
		cardClass="{$expandedCard === 'DETAILS' ? 'flex-grow-1' : ''} border-bottom-0"
		cardStyle="border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
		bodyClass="d-flex flex-column"
		cardHeaderClass="card-header d-flex justify-content-between h5"
		on:show={() => ($expandedCard = 'DETAILS')}
		on:hide={() => ($expandedCard = 'TAGS')}
	>
		<svelte:fragment slot="cardHeader">
			<h5 class="card-title mb-2">{translate('location.detailsCardTitle', 'Details')}</h5>
		</svelte:fragment>
		<fieldset
			class="d-flex flex-column flex-grow-1"
			disabled={!allowEditing}
		>
			<Input
				label="Location Code"
				required
				class={codeIsValid ? '' : 'is-invalid'}
				bind:input={codeInput}
				bind:value={$selectedLocation.code}
				on:change={() => {
					$selectedLocation.location = parentLocationName ? `${parentLocationName}${delimiter}${$selectedLocation.code}` : $selectedLocation.code
					if (codeInput?.validity?.valueMissing) {
						codeInput?.setCustomValidity(translate('location.locationCodeRequiredError', 'Location Code is required'))
					} else if ($selectedLocation.location.length > LOCATION_MAX_LENGTH) {
						codeInput?.setCustomValidity(
							translate(
								'location.locationTooLongError',
								`Location "{{location}}" is too long.
A location's code, plus all of its parents' codes, separated by "{{delimiter}}", must be less than {{LOCATION_MAX_LENGTH}} characters long.`,
								{
									location: $selectedLocation.location,
									delimiter,
									LOCATION_MAX_LENGTH,
								},
							),
						)
					}
					codeIsValid = codeInput?.reportValidity() ?? true
					updateLocation($selectedLocation)
				}}
			/>
			<div class="flex-grow-1">
				<Textarea
					label={translate('location.decriptionLabel', 'Description')}
					labelParentClass="h-100 d-flex flex-column"
					class="flex-grow-1"
					Placeholder={translate('location.descriptionTitle', 'Enter a description (Optional)')}
					bind:value={$selectedLocation.description}
					on:change={() => updateLocation($selectedLocation)}
				/>
			</div>
			<!-- TODO: Add a slot; all fields below this are product-specific -->
			<div>
				<Checkbox
					inline
					label={translate('location.testableLabel', 'Testable')}
					bind:checked={$selectedLocation.testable}
					on:change={() => updateLocation($selectedLocation)}
				/>
				<Checkbox
					inline
					label={translate('location.activeLabel', 'Active')}
					bind:checked={$selectedLocation.active}
					on:change={() => updateLocation($selectedLocation)}
				/>
			</div>
			<Autocomplete
				label={translate('location.productProximityLabel', 'Product Proximity')}
				options={productProximities}
				getLabel={pp => pp?.name ?? ''}
				bind:value={$selectedLocation.productProximity}
				on:change={async () => {
					updateLocation($selectedLocation)
					$selectedLocation.severityClass = getSeverityClass($selectedLocation)
					$selectedLocation.severityClass = await $selectedLocation.severityClass
					cascadeChangeToChildren($selectedLocation, 'productProximity')
				}}
			/>
			<Autocomplete
				label={translate('location.processZoneLabel', 'Process Zone')}
				options={processZones}
				getLabel={pz => pz?.name ?? ''}
				bind:value={$selectedLocation.processZone}
				on:change={async () => {
					updateLocation($selectedLocation)
					$selectedLocation.severityClass = getSeverityClass($selectedLocation)
					$selectedLocation.severityClass = await $selectedLocation.severityClass
					cascadeChangeToChildren($selectedLocation, 'processZone')
				}}
			/>
			{#await $selectedLocation.severityClass}
				<Input
					readonly
					label={translate('location.severityClassLabel', 'Severity Class')}
					isLoading
					value={loadingSeverityClassPlaceholder}
				>
					<!-- <svelte:fragment slot="append"
						><Button
							outline
							iconClass="cog"
							title="Configure Product Proximities, Process Zones, and Severity Classes"
							href={asr.makePath('app.analysis-management.severity-classes')}
						/></svelte:fragment
					> -->
				</Input>
			{:then severityClass}
				<Input
					readonly
					label={translate('location.severityClassLabel', 'Severity Class')}
					value={severityClass?.name ?? ''}
				>
					<!-- <svelte:fragment slot="append"
						><Button
							outline
							iconClass="cog"
							title="Configure Product Proximities, Process Zones, and Severity Classes"
							href={asr.makePath('app.analysis-management.severity-classes')}
						/></svelte:fragment
					> -->
				</Input>
			{/await}
		</fieldset>
	</CollapsibleCard>
	<CollapsibleCard
		bodyShown={$expandedCard === 'TAGS'}
		entireHeaderToggles
		cardClass={$expandedCard === 'TAGS' ? 'flex-grow-1' : ''}
		cardStyle="border-top-left-radius: 0px; border-top-right-radius: 0px;"
		cardHeaderClass="card-header d-flex justify-content-between h5"
		on:show={() => ($expandedCard = 'TAGS')}
		on:hide={() => ($expandedCard = 'DETAILS')}
	>
		<svelte:fragment slot="cardHeader">
			<h5 class="card-title mb-2">{translate('location.tagsCardTitle', 'Tags')}</h5>
			{@const theTags = $selectedLocation?.tags ?? []}
			<div
				class="align-self-end mr-auto ml-2"
				style="font-size: initial;"
			>
				{#each theTags as tag, index}
					<ExpandableBadge
						disabled
						class={index < theTags.length - 1 ? 'mr-1' : ''}
						text={tag.name}
					/>
				{/each}
			</div>
		</svelte:fragment>

		<TagSelection
			removeOuterCard
			entityType="LOCATION"
			tableParentClass="mh-60vh"
			title={$selectedLocation.code}
			disabled={!allowEditing}
			bind:tags
			bind:tagsInUse={$selectedLocation.tags}
			bind:tagCrudStore
			on:tagsInUseAdd={({ detail: tag }) => tagAddRemoveStore.add($selectedLocation.uuid, tag.uuid)}
			on:tagsInUseRemove={({ detail: tag }) => tagAddRemoveStore.remove($selectedLocation.uuid, tag.uuid)}
			on:tagsInUseChange={() => updateLocation($selectedLocation)}
		/>
	</CollapsibleCard>
</div>
