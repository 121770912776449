/** Sets a "Depth" variable on each object in the tree on Product Management.
 * This is not used for Location Management or the Svelte TreeRow component.
 */
export default ({ tree, childListKeypath, depthKeypath }) => {
	const setDepth = (node, depth) => {
		node[depthKeypath] = depth
		if (node[childListKeypath]) {
			node[childListKeypath].forEach(child => setDepth(child, depth + 1))
		}
	}
	tree.forEach(node => setDepth(node, 0))
	return tree
}
