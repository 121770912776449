type LoadFn<T> = (parentId: number) => Promise<T>

export default function makeEntityProxyMap<ObjectType>(items: Array<ObjectType>, initialParentId: number, loadFn: LoadFn<typeof items>) {
	type Item = (typeof items)[number]
	type TheMap = Record<number, Array<Item> | Promise<Array<Item>>>

	const map: TheMap = items.reduce((acc, item) => {
		if (!acc[initialParentId]) {
			acc[initialParentId] = new Array<Item>()
		}

		// I tried to do this properly with type guards, but TS couldn't take a hint and I had to assert it anyways
		;(acc[initialParentId] as Array<Item>).push(item)
		return acc
	}, {} as TheMap)

	const pendingPromises = {} as Record<number, Promise<typeof items>>

	const handler: ProxyHandler<TheMap> = {
		get(target: TheMap, key: number | string | symbol): typeof items | Promise<typeof items> {
			if (typeof key !== 'symbol' && Number(key) && !Array.isArray(target[key]) && !(pendingPromises[key] instanceof Promise)) {
				const promise = loadFn(Number(key)) // Convert key to number
				pendingPromises[key] = promise
				promise
					.then(newItems => {
						target[key] = newItems
						delete pendingPromises[key]
					})
					.catch(console.error)
				return promise
			} else if (typeof key !== 'symbol' && Number(key) && pendingPromises[key] instanceof Promise) {
				return pendingPromises[Number(key)]
			}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return target[key]
		},
	}

	return new Proxy(map, handler)
}

export type EntityProxyMap<T> = ReturnType<typeof makeEntityProxyMap<T>>
