import template from './product-management.html'
import { getSession } from 'stores/session'

//Ractive components
import makeNavTabBar from '@isoftdata/nav-tab-bar-component'
import makeButton from '@isoftdata/button'

export default function({ mediator, stateRouter, hasPermission, i18next }) {
	stateRouter.addState({
		name: 'app.product-management',
		route: 'product-management',
		defaultChild: 'product',
		querystringParameters: [ 'lastResetTime', 'lastSavedTime' ],
		defaultParameters: {
			lastResetTime: null,
			lastSavedTime: null,
		},
		template: {
			template,
			translate: i18next.t,
			components: {
				navTabBar: makeNavTabBar(stateRouter),
				itButton: makeButton(),
			},
			data: {
				childStates: [],
			},
			computed: {
				showNavTabBar() {
					return this.get('childStates').filter(state => !state.hidden && !state.disabled).length > 1
				},
				lastSaved() {
					const lastSavedTime = this.get('lastSavedTime')
					return lastSavedTime ? new Date(parseInt(lastSavedTime, 10)).toLocaleTimeString() : 'never'
				},
			},
		},
		resolve(_data, { lastSavedTime, lastResetTime }) {
			const { authorizedPlantIDs } = getSession()

			if (!authorizedPlantIDs.some(id => hasPermission('PRODUCT_CAN_OPEN_SCREEN', id))) {
				throw {
					redirectTo: { name: 'app.home' },
				}
			}
			return Promise.resolve({
				lastSavedTime,
				lastResetTime,
				// I think we're just building out the one state for now, but might as well lay the groundwork for when we have multiple.
				childStates: [
					{
						title: 'Product',
						stateName: 'app.product-management.product',
						stateParameters: {},
						stateOptions: {},
						hidden: false, // todo permissions
						disabled: false, // todo permissions
					},
				],
			})
		},
		activate(context) {
			const { domApi: ractive } = context
			// The top bar and save button are in this state, but the child states may need to save with their own logic
			ractive.on('product-management-save', async() => {
				ractive.set('productsSaving', true)
				mediator.call('showMessage', { heading: 'Saving...', message: '', type: 'info', time: false })
				try {
					await mediator.call('product-management-save')
				} catch (err) {
					console.error(err)
					alert('Save function not provided by child state')
				} finally {
					ractive.set('productsSaving', false)
				}
			})

			ractive.on('product-management-reset', () => {
				stateRouter.go(null, { lastResetTime: Date.now(), lastSavedTime: null }, { inherit: true })
			})
		},
	})
}
