export default {
    "name": "CreateWorkOrderType",
    "kind": "HoudiniMutation",
    "hash": "460925ae4baec905bd582c61571a8a2c8940b495ea139b20a4a93fa75cf76f92",

    "raw": `mutation CreateWorkOrderType($newWorkOrderType: NewWorkOrderTypeInput!) {
  createWorkOrderType(newWorkOrderType: $newWorkOrderType) {
    id
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "createWorkOrderType": {
                "type": "WorkOrderType",
                "keyRaw": "createWorkOrderType(newWorkOrderType: $newWorkOrderType)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "newWorkOrderType": "NewWorkOrderTypeInput"
        },

        "types": {
            "NewWorkOrderTypeInput": {
                "daysTillDue": "NonNegativeInt",
                "daysTillVerificationDue": "NonNegativeInt",
                "defaultAnalysisId": "PositiveInt",
                "defaultGroupId": "PositiveInt",
                "defaultReport": "String",
                "defaultVerificationGroupId": "PositiveInt",
                "inUseAtPlantIDs": "PositiveInt",
                "name": "String",
                "productBatchRequired": "ProductBatchRequired",
                "showDue": "Boolean",
                "showLocation": "Boolean",
                "showLocationDescription": "Boolean",
                "showProduct": "ShowProduct",
                "showRecipes": "Boolean",
                "showSampling": "Boolean",
                "showSamplingDetail": "Boolean",
                "showTasks": "Boolean",
                "showTestingDetail": "Boolean",
                "titleRequired": "Boolean",
                "verificationRequired": "Boolean",
                "visibleGroupId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=7776d6ac40dd0fe0366971fb60c3f5d29256a939ef3df717773e1dfa7632f843";