import pProps from 'p-props'
import { stringToBoolean } from '@isoftdata/utility-string'
import { klona } from 'klona'
import filterAnalysesAndOptions from 'utility/filter-analyses-and-options'
import { canEditPlantSpecificFields, canEditGlobalFieldsMap, canEditGlobalFields } from 'utility/analysis-permission-helper'
import apiFetch from 'utility/api-fetch'
import { v4 as uuid } from '@lukeed/uuid'
import keyboardJS from 'keyboardjs'
import { TableKeyboardNavigator } from 'utility/table-keyboard-navigator'
import { getSession } from 'stores/session'

const gqlPagninationAllPages = { pagination: { pageSize: 0, pageNumber: 1 } }

import template from './analyses.html'

//Ractive components
import makeTable from '@isoftdata/table'
import makeButton from '@isoftdata/button'
import makeModal from '@isoftdata/modal'
import makeSelect from '@isoftdata/select'
import makeInput from '@isoftdata/input'
import makeCheckbox from '@isoftdata/checkbox'
import makeCollapsibleCard from '@isoftdata/collapsible-card'
import makeDateRange from '@isoftdata/date-range'

const analysisTemplate = Object.freeze({
	id: null,
	name: '',
	inUseAtPlantIDs: [],
	active: true,
	requireAuthentication: false,
	category: '',
	visibleGroup: { id: null, name: null },
	groupSamples: false,
	testingPeriod: 0,
	sampleTagPrintQuantity: 1,
	testingTagPrintQuantity: 1,
	options: [],
})

const defaultRecalculateSamplesModalData = Object.freeze({
	show: false,
	plantId: null,
	locationId: null,
	analysisId: null,
	dates: { from: new Date().toISOString().slice(0, 10), to: new Date().toISOString().slice(0, 10) },
	dateRange: 'Today',
	locationsLoading: true,
	analysesLoading: true,
	locations: [],
	analyses: [],
})

const defaultConfirmNavigationModalData = Object.freeze({
	show: false,
	stateParmeter: '',
	stateParameterValue: null,
})

export default function({ mediator, stateRouter, hasPermission, i18next }) {
	stateRouter.addState({
		name: 'app.analysis-management.analyses',
		route: 'analyses',
		querystringParameters: [ 'plantId', 'showInactive', 'showUnused' ],
		defaultParameters: {
			plantId: () => getSession().siteId,
			showInactive: true,
			showUnused: false,
			selectedAnalysisId: null,
		},
		template: {
			template,
			translate: i18next.t,
			components: {
				itTable: makeTable({ stickyHeader: true, methods: { filter: filterAnalysesAndOptions } }), // TODO: will we need server side paging and custom sorting?,
				itButton: makeButton(),
				itModal: makeModal(),
				itSelect: makeSelect({ twoway: true }),
				itSelectOneWay: makeSelect({ twoway: false }),
				itInput: makeInput({ twoway: true }),
				itCheckbox: makeCheckbox(),
				collapsibleCard: makeCollapsibleCard({ noIntro: true, noOutro: false }),
				itDateRange: makeDateRange(),
			},
			computed: {
				selectedPlant() {
					return this.get('plants').find(plant => plant.id === this.get('plantId'))
				},
				selectedAnalysisIndex() {
					const selectedAnalysisId = this.get('selectedAnalysisId')
					const selectedAnalysisUuid = this.get('selectedAnalysisUuid')
					return this.get('analyses').findIndex(analysis => analysis.id === selectedAnalysisId && analysis.uuid === selectedAnalysisUuid)
				},
				selectedAnalysis() {
					const selectedAnalysisIndex = this.get('selectedAnalysisIndex')
					if (selectedAnalysisIndex === -1) {
						return null
					}
					return this.get(`analyses[${selectedAnalysisIndex}]`)
				},
				indexedAnalyses() {
					const analyses = this.get('analyses')
					// The `originalIndex` of a row, computed by the table component, happens after the rows are filtered,
					// and we need to know what it was before the table filter was applied.
					// I didn't fix this in the table component because that would create a breaking change for all custom filter methods.
					return analyses.map((analysis, index) => ({ ...analysis, unfilteredIndex: index }))
				},
				analysesToUpdate() {
					return this.get('analyses').filter(analysis => analysis.dirty && analysis.id && !analysis.deleted)
				},
				analysesToCreate() {
					return this.get('analyses').filter(analysis => analysis.dirty && !analysis.id && !analysis.deleted)
				},
				analysesToDelete() {
					return this.get('analyses')
						.filter(analysis => analysis.deleted && analysis.id)
						.map(analysis => analysis.id)
				},
				canRecalculateSamples() {
					const plantId = this.get('plantId')
					return hasPermission('ANALYSIS_CAN_RECALCULATE_SAMPLE_HISTORY', plantId)
				},
				plantsForRecalculateSamplesModal() {
					const plants = this.get('plants')
					return plants.filter(plant => hasPermission('ANALYSIS_CAN_RECALCULATE_SAMPLE_HISTORY', plant.id))
				},
				recalculateSamplesModalSubtitle() {
					try {
						const { plantId, locationId, analysisId, dateRange, dates, show, locations, analyses } = this.get('recalculateSamplesModal')
						if (!show) {
							return ''
						}
						const plant = this.get('plantsForRecalculateSamplesModal').find(plant => plant.id === plantId)
						const location = locations.find(location => location.id === locationId)
						const analysis = analyses.find(analysis => analysis.id === analysisId)
						// o7 to anyone who has to translate this. Maybe just don't.
						let dateString = 'invalid date range'
						if (dateRange === 'Always') {
							dateString = 'of all time'
						} else if (dateRange !== 'Custom' && dateRange.includes('Last')) {
							dateString = `in the ${dateRange.toLowerCase()}`
						} else if (dateRange.includes('Previous')) {
							dateString = `sampled ${dateString.replace('Previous', 'last').toLowerCase()}`
						} else if (dateRange !== 'Custom') {
							dateString = `sampled ${dateRange.toLowerCase()}`
						} else if (dateRange === 'Custom' && dates.from && dates.to) {
							dateString = `sampled between ${/* dateTimeFormat */ dates.from} and ${/* dateTimeFormat */ dates.to}`
						} else if (dateRange === 'Custom' && dates.from) {
							dateString = `sampled after ${/* dateTimeFormat */ dates.from}`
						} else if (dateRange === 'Custom' && dates.to) {
							dateString = `sampled before ${/* dateTimeFormat */ dates.to}`
						}

						return `${analysis?.name ?? 'all analyses'} at ${location?.location ?? 'all locations'} at ${plant?.name ?? 'all plants'} ${dateString}`
					} catch (err) {
						console.warn('Could not compute recalculate samples modal subtitle', err)
						return ''
					}
				},
				allTags() {
					return [ ...Array.from(this.get('plantTagsMap').values()), ...Array.from(this.get('locationTagsMap').values()), ...Array.from(this.get('productTagsMap').values()) ]
				},
				tagsToCreate() {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					return this.get('allTags')
						.filter(tag => !tag.id && !tag.deleted)
						.map(({ active, entityType, name }) => ({ active, entityType, name }))
				},
				tagsToUpdate() {
					return this.get('allTags')
						.filter(tag => tag.id && tag.dirty && !tag.deleted)
						.map(({ id, active, entityType, name }) => ({ id, active, entityType, name }))
				},
				tagsToDelete() {
					return this.get('allTags')
						.filter(tag => tag.id && tag.deleted)
						.map(({ id }) => id)
				},
				canEditPlantSpecificFields,
				canEditGlobalFieldsMap,
				// don't use computed for hasUnsavedChanges - we have to check the options state too
			},
			silenceDirtyObserver() {
				this.get('dirtyObserver')?.silence()
			},
			resumeDirtyObserver() {
				this.get('dirtyObserver')?.resume()
			},
			canEditGlobalFields,
			// Remove properties on analysis that we may not have permission to edit
			formatAnalysisForSave(analysis, isNew = false) {
				const ractive = this
				const plantId = ractive.get('plantId')
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { id, sampleTagPrintQuantity, testingTagPrintQuantity, inUseAtPlantIDs, visibleGroup, inUse, dirty, uuid, options, ...globalAnalysisProperties } = klona(analysis)

				let analysisToSave = {
					inUseAtPlantIDs, // enforcing perms for this may be harder than I want to do since the API already does it
				}

				if (isNew && hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', inUseAtPlantIDs[0])) {
					analysisToSave = {
						...analysisToSave,
						...globalAnalysisProperties,
						options: options.filter(option => option.dirty && !option.id && !option.deleted).map(option => ractive.formatOptionForSave({ ...option, analysisId: id }, true)),
						visibleGroupId: visibleGroup?.id,
						printQuantityInput: {
							plantId,
							sampleTagQuantity: sampleTagPrintQuantity,
							testingTagQuantity: testingTagPrintQuantity,
						},
					}
				} else if (!isNew) {
					analysisToSave = {
						...analysisToSave,
						id,
						optionsToUpdate: options.filter(option => option.dirty && option.id && !option.deleted).map(option => ractive.formatOptionForSave({ ...option, analysisId: id })), // update can include choices, which can be plant specific
					}

					// If they have global permission, they can edit everything (does not account for private plants)
					const hasGlobalEditPermission = hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', null)

					// Only send "global" fields if they have edit perm at every plant it's in use at
					const canEditAtAllPlants = hasGlobalEditPermission || analysis.inUseAtPlantIDs.every(inUseAtPlantId => hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', inUseAtPlantId))
					if (canEditAtAllPlants) {
						analysisToSave = {
							...analysisToSave,
							...globalAnalysisProperties,
							visibleGroupId: visibleGroup?.id,
							optionsToCreate: options.filter(option => !option.id && !option.deleted).map(option => ractive.formatOptionForSave({ ...option, analysisId: id }, true)),
							optionsToDelete: options.filter(option => option.id && option.deleted).map(option => option.id),
						}
					}
					// Only send print qty fields if they have edit perm at current plant
					if (hasGlobalEditPermission || hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', plantId)) {
						analysisToSave.printQuantityInput = {
							plantId,
							sampleTagQuantity: sampleTagPrintQuantity,
							testingTagQuantity: testingTagPrintQuantity,
						}
					}
				}
				console.log('analysisToSave', analysisToSave)
				return analysisToSave
			},
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			formatOptionForSave({ choices, dirty, deleted, analysisId, id, threshold, uuid, product, rules, ...option }, isNew = false) {
				const ractive = this
				let optionToSave = {}
				// if they can't modify this at all plants, keep the choices that are plant specific, but nothing else.
				if (!ractive.canEditGlobalFields(analysisId)) {
					choices = choices.filter(choice => hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', choice.plantId))
				} else {
					optionToSave = {
						...option,
						productId: product?.id ?? null, // recipes only
					}
				}
				if (isNew) {
					optionToSave = {
						...optionToSave,
						choices: choices.map(choice => ractive.formatChoiceForSave(choice, true)),
					}
				} else {
					optionToSave = {
						id,
						...optionToSave,
						choicesToCreate: choices.filter(choice => choice.dirty && !choice.id && !choice.deleted).map(choice => this.formatChoiceForSave({ ...choice, valueType: option.valueType }, true)),
						choicesToUpdate: choices
							.filter(choice => choice.dirty && choice.id && !choice.deleted)
							.map(choice => this.formatChoiceForSave({ ...choice, analysisOptionId: id, valueType: option.valueType })),
						choicesToDelete: choices.filter(choice => choice.deleted && choice.id).map(choice => choice.id),
						rulesToCreate: rules.filter(rule => rule.dirty && !rule.id && !rule.deleted).map(rule => this.formatRuleForSave(rule)),
						rulesToUpdate: rules.filter(rule => rule.dirty && rule.id && !rule.deleted).map(rule => this.formatRuleForSave(rule)),
						rulesToDelete: rules.filter(rule => rule.deleted && rule.id).map(rule => rule.id),
					}
				}
				return optionToSave
			},
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			formatChoiceForSave({ dirty, deleted, analysisOptionId, product, severityClass, global, id, uuid, valueType, ...choice }, isNew = false) {
				function transformValue(value) {
					if (typeof choice.choice === 'boolean') {
						return choice.choice ? 'True' : 'False'
					} else if (valueType === 'BOOLEAN' && !choice.choice) {
						// If they create a new boolean option and don't click on 'False', it will be saved as empty string, but they probably want to save 'False'
						return 'False'
					}
					return value?.toString() ?? ''
				}
				const choiceToSave = {
					...choice,
					productId: product?.id ?? null,
					severityClassId: severityClass?.id ?? null,
					choice: transformValue(choice.choice),
				}

				if (!isNew) {
					choiceToSave.id = id
					choiceToSave.analysisOptionId = analysisOptionId
				}
				return choiceToSave
			},
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			formatRuleForSave({ dirty, deleted, id, displayTags, uuid, analysisOptionId, created, tags, ...rule }) {
				const ractive = this
				let ruleToSave = {
					...rule,
				}

				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { tagsToAdd, tagsToRemove } = tags.reduce(
					(acc, { add, deleted, remove, dirty, inUse, originalName, ...tag }) => {
						if (!tag.id) {
							// new tag? Fetch the id from the list of tags that were just created
							tag.id = ractive.get(`${tag.entityType.toLowerCase()}TagsMap`).get(tag.name).id
						}

						if (add) {
							acc.tagsToAdd.push(tag.id)
						} else if (remove) {
							acc.tagsToRemove.push(tag.id)
						}
						return acc
					},
					{ tagsToAdd: [], tagsToRemove: [] },
				)

				/*
					TODO: The way I implemented creating new tags and deleting tags is pretty flawed, so I'm removing it for now.
					We are going to want to save and delete tags on a separate endpoint, rather than when saving the rules,
					as multiple rules can use the same new or edited tag.
				*/
				if (id) {
					ruleToSave = {
						...ruleToSave,
						id,
						tagsToAdd,
						tagsToRemove,
					}
				} else {
					ruleToSave = {
						...ruleToSave,
						tags: tagsToAdd,
					}
				}
				console.log('ruleToSave', ruleToSave)
				return ruleToSave
			},
			getMergedAnalysisOptions(existingOptions = [], modifiedOptions = []) {
				const optionsMap = new Map(existingOptions.map(option => [ option.uuid, option ]))
				for (const modifiedOption of modifiedOptions) {
					optionsMap.set(modifiedOption.uuid, modifiedOption)
				}
				return Array.from(optionsMap.values())
			},
			async saveAnalyses() {
				const ractive = this

				const selectedAnalysisIndex = ractive.get('selectedAnalysisIndex')
				const modifiedOptions = mediator.call('analysis-management-get-modified-options-choices')
				if (modifiedOptions.length) {
					const mergedOptions = ractive.getMergedAnalysisOptions(ractive.get(`analyses[${selectedAnalysisIndex}].options`), modifiedOptions)
					ractive.set(`analyses[${selectedAnalysisIndex}].options`, mergedOptions)
				}

				// const modifiedTags = mediator.call('analysis-management-get-modified-tags')
				const tagsToCreate = ractive.get('tagsToCreate')
				const tagsToUpdate = ractive.get('tagsToUpdate')
				const tagsToDelete = ractive.get('tagsToDelete')

				const analysesToCreate = ractive.get('analysesToCreate')
				const analysesToUpdate = ractive.get('analysesToUpdate')
				const analysesToDelete = ractive.get('analysesToDelete')

				if (!ractive.hasUnsavedChanges()) {
					mediator.call('showMessage', { type: 'info', heading: 'No changes to save', message: '', time: 10 })
					return Promise.resolve(false)
				}

				try {
					// need to save tags first so we can associate the new tag id with the analysis > option > rule > tag
					const [ createTagsRes ] = await Promise.all([
						tagsToCreate.length ? apiFetch(mediator, { query: queries.createTags, variables: { input: tagsToCreate } }, 'createEntityTags') : [],
						tagsToUpdate.length ? apiFetch(mediator, { query: queries.updateTags, variables: { input: tagsToUpdate } }, 'updateEntityTags') : [],
						tagsToDelete.length ? apiFetch(mediator, { query: queries.deleteTags, variables: { ids: tagsToDelete } }, 'deleteEntityTags') : [],
					])
					// update maps with new tags' ids
					if (createTagsRes.length) {
						const plantTagsMap = ractive.get('plantTagsMap')
						const locationTagsMap = ractive.get('locationTagsMap')
						const productTagsMap = ractive.get('productTagsMap')

						for (const tag of createTagsRes) {
							if (tag.entityType === 'PLANT') {
								plantTagsMap.set(tag.name, tag)
							} else if (tag.entityType === 'LOCATION') {
								locationTagsMap.set(tag.name, tag)
							} else if (tag.entityType === 'PRODUCT') {
								productTagsMap.set(tag.name, tag)
							}
						}

						ractive.set({
							plantTagsMap,
							locationTagsMap,
							productTagsMap,
						})
					}
				} catch (err) {
					mediator.call('showMessage', { type: 'error', heading: 'Error saving tags; Analyses not saved', message: err.message, time: false })
					return Promise.reject(err)
				}
				try {
					// This won't differentiate to the user between errors creating/saving/deleting. Maybe use allSettled to give more info?
					await Promise.all([
						analysesToCreate.length ? mediator.call('apiFetch', queries.createAnalyses, { input: analysesToCreate.map(analysis => ractive.formatAnalysisForSave(analysis, true)) }) : Promise.resolve(),
						analysesToUpdate.length ? mediator.call('apiFetch', queries.updateAnalyses, { input: { analyses: analysesToUpdate.map(analysis => ractive.formatAnalysisForSave(analysis)) } }) : Promise.resolve(),
						analysesToDelete.length ? mediator.call('apiFetch', queries.deleteAnalyses, { ids: analysesToDelete }) : Promise.resolve(),
					])
					mediator.call('showMessage', { type: 'success', heading: 'Saved!', message: 'Analyses saved successfully.', time: 10 })
					// Updating/inserting/deleting rows in the table is a pain since they can do all of them at once, so just reload the page
					// Can we preserve the selected analysis somehow, in the case that it's a brand new one?
					stateRouter.go(null, { lastSavedTime: Date.now() }, { inherit: true })
					return Promise.resolve(true)
				} catch (err) {
					mediator.call('showMessage', { type: 'danger', heading: 'Error saving Analyses', message: err.message, time: false })
					console.error('Error saving Analyses', err)
					return Promise.reject(err)
				}
			},
			updateAnalysisKeypath(index, keypath, value, type) {
				if (type === 'number') {
					value = Number(value)
				}
				this.set(`analyses.${index}.${keypath}`, value)
			},
			hasUnsavedChanges() {
				return (
					this.get('analysesToCreate').length ||
					this.get('analysesToUpdate').length ||
					this.get('analysesToDelete').length ||
					this.get('tagsToCreate').length ||
					this.get('tagsToUpdate').length ||
					this.get('tagsToDelete').length ||
					mediator.call('analysis-management-get-modified-options-choices').length
				)
			},
			hasPermission,
		},
		async resolve(_data, { plantId, showInactive, showUnused, selectedAnalysisId }) {
			plantId = parseInt(plantId, 10)
			selectedAnalysisId = (!selectedAnalysisId || selectedAnalysisId === 'null') ? null : parseInt(selectedAnalysisId, 10)
			showUnused = stringToBoolean(showUnused)
			showInactive = stringToBoolean(showInactive)

			const analysesCardExpanded = stringToBoolean(localStorage.getItem('analysesCardExpanded') || 'True')

			const {
				analyses,
				groups: {
					groups: { data: groups },
				},
				plants,
				categories,
				tags,
			} = await pProps({
				analyses: apiFetch(
					mediator,
					{
						query: queries.analyses,
						variables: {
							viewAsPlantId: plantId,
							filter: {
								plantIds: (showUnused || !plantId) ? null : [ plantId ],
								activeOnly: !showInactive,
								type: 'TESTING',
							},
							...gqlPagninationAllPages,
						},
					},
					'analyses.data',
				),
				plants: (await mediator.call('apiFetch', queries.plants, gqlPagninationAllPages)).plants.data,
				groups: mediator.call('apiFetch', queries.groups, gqlPagninationAllPages),
				categories: apiFetch(mediator, `query Query {analysisCategories}`, 'analysisCategories'),
				tags: apiFetch(
					mediator,
					{
						query: queries.tags,
						variables: {
							filter: {
								entityTypes: [ 'PLANT', 'LOCATION', 'PRODUCT' ],
							},
						},
					},
					'entityTags',
				),
			})

			selectedAnalysisId = selectedAnalysisId ?? analyses[0]?.id ?? null
			// selectedAnalysisUuid =
			const mappedAnalyses = analyses.map(analysis => ({
				...analysis,
				visibleGroup: analysis.visibleGroup ?? { id: null, name: null }, // temp fix for table bug where it tries and fails to null[name]
				inUse: analysis.inUseAtPlantIDs.includes(plantId),
				uuid: uuid(),
			}))
			mappedAnalyses.push({
				...klona(analysisTemplate),
				inUseAtPlantIDs: [ plantId ],
				inUse: true,
				uuid: uuid(),
			})

			console.log('tags', tags)

			const { plantTagsMap, locationTagsMap, productTagsMap } = (tags ?? []).reduce(
				({ plantTagsMap, locationTagsMap, productTagsMap }, tag) => {
					// Since we're using the name as the key, we have to track the original name on load/first creation to maintain references to the tag
					tag.originalName = tag.name
					switch (tag.entityType) {
						case 'PLANT':
							plantTagsMap.set(tag.name, tag)
							break
						case 'LOCATION':
							locationTagsMap.set(tag.name, tag)
							break
						case 'PRODUCT':
							productTagsMap.set(tag.name, tag)
							break
						default:
							break
					}
					return { plantTagsMap, locationTagsMap, productTagsMap }
				},
				{ plantTagsMap: new Map(), locationTagsMap: new Map(), productTagsMap: new Map() },
			)

			// no sense getting this from the state router if it's going to be different on every load of this state
			const selectedAnalysisUuid = selectedAnalysisId ? mappedAnalyses.find(analysis => analysis.id === selectedAnalysisId)?.uuid ?? null : null
			const analysesTableColumns = [
				{
					property: 'dirty',
					icon: 'fas fa-fw fa-save',
					columnWidth: '1rem',
					title: 'Rows with a save icon have unsaved changes, and will be saved when you hit the "Save" button',
					focusId: false,
				},
				{ property: 'name', name: 'Name', columnMinWidth: '200px', columnMaxWidth: '400px', title: 'A unique name describing a test (called an analysis) to be performed', focusId: 'analysis-name' },
				{ property: 'inUse', name: 'In Use', columnWidth: '1rem', title: 'Whether this analysis is in use at the currently selected plant', focusId: 'analysis-in-use' },
				{ property: 'active', name: 'Active', columnWidth: '1rem', title: 'Whether this analysis is active (active analyses can be used on new samples)', focusId: 'analysis-active' },
				{
					property: 'requireAuthentication',
					name: 'Auth. Required',
					columnWidth: '1rem',
					title: 'If checked, if the user makes changes to samples with this analysis, they must authenticate with their password',
					focusId: 'analysis-require-authentication',
				},
				{ property: 'category', name: 'Category', columnMinWidth: '150px', title: 'Categories are used to group similar items together', focusId: 'analysis-category' },
				{
					property: 'visibleGroup[name]',
					name: 'Visible Group',
					columnMinWidth: '150px',
					title: '(Optional) If specified, only users in this group will be able to see/use this analysis',
					focusId: 'analysis-visible-group',
				},
				{
					property: 'groupSamples',
					name: 'Group Tag #s',
					columnWidth: '1rem',
					title: 'Samples collected for this analysis will be grouped into one sample tag # if they are being collected from the same location',
					focusId: 'analysis-group-samples',
				},
				{
					property: 'testingPeriod',
					name: 'Testing Period',
					columnWidth: '1rem',
					title: "The amount of time (in fractional hours) a sample spends in testing/incubation.Enter 0 for analyses that don't need testing.",
					focusId: 'analysis-testing-period',
				},
				{
					property: 'sampleTagPrintQuantity',
					name: 'Sample Tag Print Qty',
					columnWidth: '1rem',
					title: 'The default number of sample tags to print for this type of analysis',
					focusId: 'analysis-sample-tag-print-quantity',
				},
				{
					property: 'testingTagPrintQuantity',
					name: 'Testing Tag Print Qty',
					columnWidth: '1rem',
					title: 'The default number of testing tags to print for this type of analysis',
					focusId: 'analysis-testing-tag-print-quantity',
				},
				{
					property: 'deleted',
					icon: 'fas fa-trash',
					class: 'text-center',
					sortType: false,
					columnWidth: '1rem',
					focusId: 'analysis-delete',
					title: 'Mark this for deletion. It will be deleted on save.',
				},
			]
			return {
				// TODO I'm worried that this whole thing will crumble once we get a real volume of data in it.
				// So we may want to switch to having a Map instead of an array of analyses to speed up lookups.
				analyses: mappedAnalyses,
				analysesCardExpanded,
				analysesTableColumns,
				analysesTableFilterProps: analysesTableColumns.slice(1, analysesTableColumns.length - 1).map(col => col.property),
				categories,
				groups,
				plantId,
				plants,
				selectedAnalysisId,
				selectedAnalysisUuid,
				selectedOptionTab: 'THRESHOLDS',
				plantTagsMap,
				locationTagsMap,
				productTagsMap,
				showUnused,
				showInactive,
				cloneWithThresholds: true,
				cloneWithPlants: true,
				recalculateSamplesModal: klona(defaultRecalculateSamplesModalData),
				confirmNavigationModal: klona(defaultConfirmNavigationModalData),
				analysesSortColumn: '',
				analysesSortDirection: '',
				lazySortAnalyses: true,
			}
		},
		activate(context) {
			const { domApi: ractive } = context

			//#region keyboardJS

			const analysesNavigator = new TableKeyboardNavigator({
				tableId: 'analyses-table',
				ractive,
				rowsKeypath: 'analyses',
				selectedIndexKeypath: 'selectedAnalysisIndex',
				columnIds: ractive
					.get('analysesTableColumns')
					.slice(1)
					.map(column => column.focusId),
				changeRow: (newIndex, oldIndex) => ractive.fire('selected-analysis-changed', null, newIndex, oldIndex),
				initFocusListeners: false, // since the table may not start rendered, we don't want to init listeners on it right away
			})

			ractive.observe(
				'analysesCardExpanded',
				expanded => {
					if (expanded) {
						analysesNavigator.createFocusListeners()
					} else {
						analysesNavigator.removeFocusinListener()
						analysesNavigator.removeFocusoutListener()
					}
				},
				{ defer: true },
			)
			// #endregion

			// #region Events
			ractive.on('selected-analysis-changed', (context, selectedAnalysisIndex, lastSelectedAnalysisIndex) => {
				// can we tell which thing they clicked into here?
				const newSelectedAnalysis = ractive.get(`analyses.${selectedAnalysisIndex}`)
				const lastSelectedAnalysis = ractive.get(`analyses.${lastSelectedAnalysisIndex}`) ?? null
				const newSelectedAnalysisId = newSelectedAnalysis?.id ?? null
				const lastSelectedAnalysisId = lastSelectedAnalysis?.id ?? null
				const newSelectedAnalysisUuid = newSelectedAnalysis?.uuid ?? null
				const lastSelectedAnalysisUuid = lastSelectedAnalysis?.uuid ?? null

				// Cache any modified options from the last selected Analysis and reload with new selection
				if (!newSelectedAnalysis?.deleted && (newSelectedAnalysisId !== lastSelectedAnalysisId || newSelectedAnalysisUuid !== lastSelectedAnalysisUuid)) {
					if (stateRouter.stateIsActive('app.analysis-management.analyses.options')) {
						try {
							const modifiedOptions = mediator.call('analysis-management-get-modified-options-choices')
							if (modifiedOptions.length) {
								const mergedOptions = ractive.getMergedAnalysisOptions(ractive.get(`analyses[${lastSelectedAnalysisIndex}].options`), modifiedOptions)
								ractive.set(`analyses[${lastSelectedAnalysisIndex}].options`, mergedOptions)
							}
						} catch (err) {
							// The state was only pretending to be active - no modified options
						}
					}
					// Analyses state is not reloaded by this, set them manually
					ractive.set({
						selectedAnalysisId: newSelectedAnalysisId,
						selectedAnalysisUuid: newSelectedAnalysisUuid,
					})
					ractive.find(`#analysis-row-${selectedAnalysisIndex}`)?.scrollIntoView({ block: 'center', behavior: 'smooth' })
					// Reload options state
					stateRouter.go(
						'app.analysis-management.analyses.options',
						{
							selectedAnalysisId: newSelectedAnalysisId,
							selectedAnalysisUuid: newSelectedAnalysisUuid,
						},
						{ inherit: true },
					)
				}
			})

			ractive.fire('selected-analysis-changed', ractive.get('selectedAnalysisIndex'), null)

			ractive.on('analysis-visible-group-changed', (context, analysisIndex) => {
				const groupId = parseInt(context.node.value, 10) ?? null
				const group = ractive.get(`groups`).find(group => group.id === groupId) ?? { id: null } // null id -> "All Groups"
				ractive.set(`analyses.${analysisIndex}.visibleGroup`, klona(group))
			})
			// #region Recalculate Samples
			ractive.on('recalculate-samples', async() => {
				const plantId = ractive.get('plantId')
				const analyses = ractive.get('analyses').filter(analysis => analysis.inUseAtPlantIDs.includes(plantId) && analysis.active && analysis.id)
				const selectedAnalysisId = ractive.get('selectedAnalysisId')
				const analysisId = klona(analyses).find(analysis => analysis.id === selectedAnalysisId)?.id ?? null

				ractive.set('recalculateSamplesModal', {
					...klona(defaultRecalculateSamplesModalData),
					show: true,
					plantId,
					analysesLoading: false,
					analyses,
					analysisId,
				})

				const locations = await apiFetch(mediator, { query: queries.locations, variables: { plantIds: plantId ? [ plantId ] : [], activeOnly: true, ...gqlPagninationAllPages } }, 'locations.data')

				ractive.set({
					'recalculateSamplesModal.locations': locations,
					'recalculateSamplesModal.locationsLoading': false,
				})
			})

			ractive.on('recalculate-samples-plant-changed', async context => {
				const plantId = parseInt(context.node.value, 10) || null
				ractive.set({
					'recalculateSamplesModal.locationsLoading': true,
					'recalculateSamplesModal.analysesLoading': true,
				})
				try {
					const [ locations, analyses ] = await Promise.all([
						apiFetch(mediator, { query: queries.locations, variables: { plantIds: plantId ? [ plantId ] : [], activeOnly: true, ...gqlPagninationAllPages } }, 'locations.data'),
						apiFetch(
							mediator,
							{ query: queries.analysesNoTags, variables: { filter: { plantIds: plantId ? [ plantId ] : [], activeOnly: true, type: 'TESTING' }, ...gqlPagninationAllPages } },
							'analyses.data',
						),
					])

					ractive.set({
						'recalculateSamplesModal.locations': locations,
						'recalculateSamplesModal.locationsLoading': false,
						'recalculateSamplesModal.analyses': analyses,
						'recalculateSamplesModal.analysesLoading': false,
					})
				} catch (err) {
					alert(`Error loading locations and analyses\r\n${err.message}`)
					console.log(err)
				}
			})

			ractive.on('confirm-recalculate-samples', async(context, plantId, locationId, analysisId, dateFrom, dateTo) => {
				try {
					await apiFetch(
						mediator,
						{
							query: 'mutation RecalculateSamples($input: RecalculateSamplesInput!) {recalculateSamples(input: $input)}',
							variables: {
								input: {
									plantId,
									locationId,
									analysisId,
									dateFrom,
									dateTo,
								},
							},
						},
						'recalculateSamples',
					)
					ractive.set('recalculateSamplesModal.show', false)
				} catch (err) {
					console.error(err)
					ractive.set('recalculateSamplesModal.error', err.message)
				}
			})

			// #endregion
			ractive.on('delete-analysis', (context, index, id) => {
				// TODO the desktop tells you how many samples you'll destroy in the process. Maybe do that here.
				if (
					id &&
					!confirm(
						'Are you sure you want to delete this analysis? \r\n\r\nIt will be removed from all schedules and work orders, and all samples that use it will be deleted. \r\n\r\nAfter saving, this action cannot be undone.',
					)
				) {
					return
				}
				ractive.set(`analyses.${index}.deleted`, true)

				if (index === ractive.get('selectedAnalysisIndex')) {
					// Select the next non-deleted analysis. The list might be filtered, use sortedAnalyses.
					const newSelectedAnalysis = ractive.get('sortedAnalyses').find(analysis => !analysis.deleted)
					const newSelectedAnalysisIndex = newSelectedAnalysis.unfilteredIndex
					ractive.set({
						selectedAnalysisId: newSelectedAnalysis?.id ?? null,
						selectedAnalysisUuid: newSelectedAnalysis?.uuid ?? null,
					})
					ractive.find(`#analysis-row-${newSelectedAnalysisIndex}`)?.scrollIntoView({ block: 'center' })
					stateRouter.go(
						'app.analysis-management.analyses.options',
						{
							selectedAnalysisId: newSelectedAnalysis?.id ?? null,
							selectedAnalysisUuid: newSelectedAnalysis?.uuid ?? null,
						},
						{ inherit: true },
					)
				}
			})

			ractive.on('undo-delete-analysis', (context, index) => {
				ractive.set(`analyses.${index}.deleted`, false)
			})

			ractive.on('add-analysis', () => {
				if (!ractive.get('canEditPlantSpecificFields')) {
					return
				}
				const analyses = ractive.get('analyses')
				// Find an existing "new" row if one exists (it should)
				let newAnalysisIndex = analyses.findIndex(analysis => !analysis.id && !analysis.deleted && !analysis.dirty)
				// I don't this will ever happen, but just in case
				if (newAnalysisIndex === -1) {
					newAnalysisIndex = ractive.get('analyses').length
					ractive.push('analyses', {
						...klona(analysisTemplate),
						inUseAtPlantIDs: [ ractive.get('plantId') ],
						inUse: true,
						uuid: uuid(),
					})
				}

				const lastSelectedAnalysisIndex = ractive.get('selectedAnalysisIndex')
				ractive.find(`#analysis-name-${newAnalysisIndex}`)?.focus()
				ractive.fire('selected-analysis-changed', newAnalysisIndex, lastSelectedAnalysisIndex)
			})

			ractive.on('clone-analysis', async() => {
				const originalAnalysisId = ractive.get('selectedAnalysisId')
				const clonedAnalysis = klona({
					...ractive.get('selectedAnalysis'),
					id: null,
					dirty: true,
					uuid: uuid(),
				})
				if (ractive.get('cloneWithPlants') && !ractive.canEditGlobalFields(originalAnalysisId)) {
					// Make sure we don't let them set this as in use at a plant they can't edit at
					clonedAnalysis.inUseAtPlantIDs = clonedAnalysis.inUseAtPlantIDs.filter(id => hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', id))
					const plants = ractive.get('plants')
					const inUseAtPlantCodes = clonedAnalysis.inUseAtPlantIDs.map(id => plants.find(plant => plant.id === id).code)
					alert(`You do not have permission to edit this analysis at all plants it is in use at. The cloned analysis will only be in use at ${inUseAtPlantCodes.join(', ')}.`)
				} else if (!ractive.get('cloneWithPlants')) {
					// strip plants if not cloning with plants
					clonedAnalysis.inUseAtPlantIDs = [ ractive.get('plantId') ]
				}

				const modifiedOptions = mediator.call('analysis-management-get-modified-options-choices')
				const updatedOptionsMap = new Map(modifiedOptions.filter(option => option.id).map(option => [ option.id, option ]))
				const newOptionsArr = modifiedOptions.filter(option => !option.id)
				// strip choices if not cloning with thresholds

				const cloneWithThresholds = ractive.get('cloneWithThresholds')

				// Have to fetch options, because they're not included in the analysis query and options state only stores choices for selected option
				const canonOptions = originalAnalysisId
					? (
						await apiFetch(
							mediator,
							{
								query: cloneWithThresholds ? queries.analysisOptionsWithChoicesForClone : queries.analysisOptionsForClone,
								variables: {
									filter: { analysisIds: [ originalAnalysisId ] },
									pagination: {
										pageSize: 0,
									},
								},
							},
							'analysisOptions.data',
						)
					).map(option => ({ ...option, choices: option.choices ?? [] }))
					: []

				const mergedOptions = canonOptions
					.map(option => {
						return updatedOptionsMap.get(option.id) ?? option
					})
					.concat(newOptionsArr)

				clonedAnalysis.options = mergedOptions
					.filter(option => !option.deleted)
					.map(option => {
						return {
							...option,
							id: null,
							dirty: !!option.id || option.dirty, // don't set "New Option" placeholder as dirty.
							uuid: uuid(),
							// Since we've already enforced plant permissions on the new analysis, we can just filter out the choices that don't match the new analysis's plants
							choices: cloneWithThresholds
								? option.choices
									.filter(choice => (choice.plantId === null || clonedAnalysis.inUseAtPlantIDs.includes(choice.plantId)) && !choice.deleted)
									.map(choice => ({
										...choice,
										dirty: !!choice.id || choice.dirty, // don't set "New Threshold" placeholder as dirty.
										id: null,
									}))
								: [],
							rules: option.rules?.map(rule => ({ ...rule, dirty: true, id: null })) ?? [],
						}
					})

				// Rename the new analysis
				const duplicateRegex = /\s\((\d+)\)$/
				const analysisNameNoCount = clonedAnalysis.name.replace(duplicateRegex, '')
				const duplicateCount =
					ractive.get('analyses').reduce((duplicateCount, analysis) => {
						const match = analysis.name.includes(analysisNameNoCount) && analysis.name.match(duplicateRegex)
						if (match) {
							const num = parseInt(match[1], 10)
							if (num > duplicateCount) {
								return num
							}
						}
						return duplicateCount
					}, 0) + 1
				clonedAnalysis.name = analysisNameNoCount ? `${analysisNameNoCount} (${duplicateCount})` : ''

				// Insert it after what we're cloning. Have to turn off lazy sorting for a sec so it sorts correctly
				ractive.set('lazySortAnalyses', false)
				ractive.silenceDirtyObserver()
				const newSelectedAnalysisIndex = ractive.get('selectedAnalysisIndex') + 1
				await ractive.splice('analyses', newSelectedAnalysisIndex, 0, clonedAnalysis)
				ractive.resumeDirtyObserver()
				ractive.set('lazySortAnalyses', true)

				const lastSelectedAnalysisIndex = ractive.get('selectedAnalysisIndex')
				ractive.find(`#analysis-name-${newSelectedAnalysisIndex}`)?.focus()
				ractive.fire('selected-analysis-changed', newSelectedAnalysisIndex, lastSelectedAnalysisIndex)
			})

			ractive.on('analysis-in-use-changed', (context, analysisIndex) => {
				const inUse = context.node.checked
				if (!ractive.get('canEditPlantSpecificFields')) {
					return
				}

				if (inUse) {
					ractive.splice(`analyses.${analysisIndex}.inUseAtPlantIDs`, 0, 0, ractive.get(`plantId`))
				} else {
					const inUseAtPlantIDs = ractive.get(`analyses.${analysisIndex}.inUseAtPlantIDs`)
					const plantIndex = inUseAtPlantIDs.indexOf(ractive.get(`plantId`))
					ractive.splice(`analyses.${analysisIndex}.inUseAtPlantIDs`, plantIndex, 1)
				}
				ractive.set(`analyses.${analysisIndex}.inUse`, inUse)
			})

			ractive.on('confirm-navigation', async(context, save = true) => {
				if (save) {
					await ractive.saveAnalyses()
				}
				ractive.get('filterObserver')?.cancel()
				stateRouter.go(
					null,
					{
						[ractive.get('confirmNavigationModal.stateParameter')]: ractive.get('confirmNavigationModal.stateParameterValue'),
						lastSavedTime: save ? Date.now() : null,
						lastResetTime: Date.now(),
					},
					{ inherit: true },
				)
			})

			ractive.on('cancel-navigation', _context => {
				ractive.set({
					[ractive.get('confirmNavigationModal.stateParameter')]: ractive.get('confirmNavigationModal.stateParameterValue'),
					confirmNavigationModal: klona(ractive.get('confirmNavigationModalDefaults')),
				})
			})

			ractive.on('collapsibleCard.transition-complete', (context, expanded) => {
				localStorage.setItem('analysesCardExpanded', expanded ? 'True' : 'False')

				if (expanded) {
					ractive.find(`#analysis-row-${ractive.get('selectedAnalysisIndex')}`)?.scrollIntoView({ block: 'center' })
				} else {
					keyboardJS.pause()
				}
			})

			// #endregion

			// #region Observers

			const dirtyObserver = ractive.observe(
				'analyses.*',
				(newVal, oldVal, keypath) => {
					console.log(keypath, 'modified', newVal)
					const analysisIndex = parseInt(keypath.split('.')[1], 10)
					const analysesCount = ractive.get('analyses').length
					ractive.silenceDirtyObserver()
					if (analysisIndex === analysesCount - 1) {
						ractive.set('lazySortAnalyses', false)
						ractive.push('analyses', {
							...klona(analysisTemplate),
							inUseAtPlantIDs: [ ractive.get('plantId') ],
							inUse: true,
							uuid: uuid(),
						})
						ractive.set('lazySortAnalyses', true)
					}
					ractive.set(`${keypath}.dirty`, true)
					ractive.resumeDirtyObserver()
				},
				{ init: false },
			)

			ractive.set('dirtyObserver', dirtyObserver)

			const filterObserver = ractive.observe(
				'plantId showInactive showUnused',
				(value, oldValue, keypath) => {
					ractive.silenceDirtyObserver()

					if (ractive.hasUnsavedChanges()) {
						ractive.set({
							confirmNavigationModal: {
								show: true,
								stateParameter: keypath,
								stateParameterValue: value,
							},
						})
					} else {
						stateRouter.go(null, { [keypath]: value }, { inherit: true })
					}
					ractive.resumeDirtyObserver()
				},
				{ init: false },
			)

			ractive.set({ filterObserver })

			// #endregion

			const removeSaveProvider = mediator.provide('analysis-management-save', async() => {
				return await ractive.saveAnalyses()
			})

			const removeUnsavedChangesProvider = mediator.provide('analysis-management-has-unsaved-changes', () => {
				let optionsCount = 0
				try {
					const options = mediator.call('analysis-management-get-modified-options-choices')
					optionsCount = options.length
				} catch (err) {
					//
				}
				return ractive.hasUnsavedChanges() || optionsCount > 0
			})

			const removeUpdateTagListsProvider = mediator.provide('analysis-management-update-tag-list', (entityType, modifiedTagsArray) => {
				const tagMapKeypath = `${entityType.toLowerCase()}TagsMap`
				const tagMap = ractive.get(tagMapKeypath)
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				for (const { add, remove, inUse, ...modifiedTag } of modifiedTagsArray) {
					// On a brand new tag, the originalName will be undefined
					if (!modifiedTag.originalName) {
						modifiedTag.originalName = modifiedTag.name
					}

					if (modifiedTag.dirty || modifiedTag.deleted || !modifiedTag.id) {
						// Use original name as the key to not break references to this tag in other rules
						tagMap.set(modifiedTag.originalName, modifiedTag)
					}
				}
				ractive.set(tagMapKeypath, tagMap)
				return tagMap
			})

			// Clean up providers when the component is destroyed
			context.on('destroy', () => {
				removeSaveProvider()
				removeUnsavedChangesProvider()
				removeUpdateTagListsProvider()
				filterObserver.cancel()
				dirtyObserver.cancel()
				analysesNavigator.teardown()
			})
		},
	})
}
const analysisReturnData = `#graphql
	id
	analysisType
	name
	active
	testingPeriod
	category
	visibleGroup {
		id
		name
	}
	groupSamples
	requireAuthentication
	instructions
	createdProductId
	batchVolume
	batchUnit
	inUseAtPlantIDs
	options {
id
option
}
`
const queries = {
	analyses: `#graphql
query Analyses($filter: AnalysisFilter, $viewAsPlantId: ID!, $pagination: PaginatedInput) {
analyses(filter: $filter, pagination: $pagination) {
data {
	${analysisReturnData}
	sampleTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
	testingTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
}
}
}
`,
	analysesNoTags: `#graphql
query Analyses($filter: AnalysisFilter, $pagination: PaginatedInput) {
analyses(filter: $filter, pagination: $pagination) {
data {
	${analysisReturnData}
}
}
}
	`,
	createAnalyses: `#graphql
	mutation CreateAnalyses($input: [NewAnalysis!]!) {
createAnalyses(input: $input) {
id
}
}
`,
	updateAnalyses: `#graphql
mutation UpdateAnalyses($input: UpdateAnalysesInput!) {
	updateAnalyses(input: $input) {
		id
	}
}
`,
	deleteAnalyses: `#graphql
mutation DeleteAnalyses($ids: [ID!]!) {
deleteAnalyses(ids: $ids)
}
`,
	createTags: `#graphql
	mutation CreateEntityTags($input: [NewEntityTag!]!) {
createEntityTags(input: $input) {
active
entityType
id
name
}
}
`,
	updateTags: `#graphql
mutation UpdateEntityTags($input: [EntityTagUpdate!]!) {
updateEntityTags(input: $input) {
id
}
}
`,
	deleteTags: `#graphql
mutation DeleteEntityTags($ids: [PositiveInt!]!) {
deleteEntityTags(ids: $ids)
}
`,
	groups: `#graphql
query Groups($pagination: PaginatedInput) {
groups(pagination: $pagination) {
data {
name
id
}
}
}
`,
	plants: `#graphql
query Plants($pagination: PaginatedInput) {
plants(pagination: $pagination) {
data {
id
name
code
}
}
}`,
	locations: `#graphql
query Locations($filter: LocationFilter, $pagination: PaginatedInput) {
locations(filter: $filter, pagination: $pagination) {
data {
id
plantId
code
location
}
}
}`,
	analysisOptionsForClone: `#graphql
	query AnalysisOptionsQuery($filter: AnalysisOptionFilter, $pagination: PaginatedInput) {
analysisOptions(filter: $filter, pagination: $pagination) {
data {
	id
active
analysisId
defaultType
defaultValue
option
productId
unit
valueType
thresholdType
requiredToPerform
requiredToClose
informational
rank
product {
id
name
}
}
}
}`,
	analysisOptionsWithChoicesForClone: `#graphql
	query AnalysisOptionsChoicesQuery($filter: AnalysisOptionFilter, $pagination: PaginatedInput) {
analysisOptions(filter: $filter, pagination: $pagination) {
data {
	id
active
analysisId
choices {
active
analysisOptionId
choice
constraint
boundaryType
severityClassId
		id
plantId
productId
productBatchId
requiredAnalysisOptionId
requiredConstraint
requiredChoice
severityClass {
name
id
}
product {
id
name
}
}
defaultType
defaultValue
option
productId
unit
valueType
thresholdType
requiredToPerform
requiredToClose
informational
rank
product {
id
name
}
}
}
}`,
	tags: `#graphql
	query EntityTags($filter: EntityTagFilter!) {
	entityTags(filter: $filter) {
		active
		entityType
		id
		name
	}
	}
	`,
}
