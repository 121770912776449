export default {
    "name": "WoProducts",
    "kind": "HoudiniQuery",
    "hash": "2edef587a13b900313f288be95be1891cac61fc005f6bfd1e43622c79117ddc2",

    "raw": `query WoProducts($productsFilter: ProductFilter, $pagination: PaginatedInput) {
  products(filter: $productsFilter, pagination: $pagination) {
    data {
      ...WoProductData
      id
    }
  }
}

fragment WoProductData on Product {
  id
  name
  productType
  attachmentCount(fileType: IMAGE)
  __typename
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "products": {
                "type": "ProductResponse",
                "keyRaw": "products(filter: $productsFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Product",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "productType": {
                                        "type": "ProductType",
                                        "keyRaw": "productType",
                                        "visible": true
                                    },

                                    "attachmentCount": {
                                        "type": "UnsignedInt",
                                        "keyRaw": "attachmentCount(fileType: IMAGE)",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "WoProductData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "productsFilter": "ProductFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "ProductFilter": {
                "active": "Boolean",
                "activeOnly": "Boolean",
                "plantIds": "PositiveInt",
                "plantIdsFilteringByChildren": "PositiveInt",
                "plants": "PositiveInt",
                "productName": "NonEmptyString",
                "productType": "ProductType"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=dfa0512f25885860c477e283ddc193327b796352e467fcc8ca98d4743de888c0";