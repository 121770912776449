import type { Mediator, Template, DomApi } from 'types/common'
import type { AbstractStateRouter, Renderer } from 'abstract-state-router'
import type { Decorator, Static, ValueMap } from 'ractive'
import type { SidebarItemType } from '@isoftdata/svelte-sidebar'

import { graphql } from '$houdini'
import makeStateRouter from 'abstract-state-router'
import makeRenderer from '@isoftdata/ractive-svelte-state-renderer-wrapper'
import mannish from 'mannish'
import component from './App.svelte'
import { stringToBoolean } from '@isoftdata/utility-string'
import hasPermission from 'utility/has-permission'
import betterEmitter from 'better-emitter'
;(window as Window & typeof globalThis & { emitter: unknown }).emitter = betterEmitter()
import { getSession } from 'stores/session'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-locize-backend'
import { locizePlugin } from 'locize'

function isChildState({ childState, parentState }: { childState: string; parentState: string }) {
	return childState.indexOf(parentState) > -1
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const mediator: Mediator = mannish()

const svelteContext = new Map<string, unknown>([
	['apiUrl', '__apiUrl__'],
	['fileBaseUrl', '__apiUrl__'.replace('/graphql', '')],
	['hasPermission', hasPermission],
	['i18next', i18next],
	['isChildState', isChildState],
	['mediator', mediator],
])

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const renderer = makeRenderer(
	{
		// Ractive default data
		data: {},
	},
	{
		// Svelte default props
		props: {},
		// Svelte default context, you can call getContext<Type>('key') to get the value in a svelte component
		context: svelteContext,
	}
) as (asr: AbstractStateRouter<Template, DomApi>) => Renderer<Template, DomApi>

const stateRouter = makeStateRouter(renderer, document.querySelector('body'), { throwOnError: true })
svelteContext.set('stateRouter', stateRouter)

import ractivePartialNoItemsForSelectedFilter from './partials/no-items-for-selected-filter.ractive.html?raw'
import ractiveDecoratorSelectOnFocus from 'ractive-select-on-focus'
import ractiveDecoratorValid from '@isoftdata/ractive-decorator-valid'
import ractiveMethodUpsert from '@isoftdata/ractive-method-upsert'

declare const Ractive: Static
Ractive.partials.noItemsForSelectedFilter = ractivePartialNoItemsForSelectedFilter as string
Ractive.decorators.selectOnFocus = ractiveDecoratorSelectOnFocus as Decorator
Ractive.decorators.valid = ractiveDecoratorValid as Decorator
Ractive.defaults.upsert = ractiveMethodUpsert as keyof ValueMap
const context = {
	stateRouter,
	i18next,
	mediator,
	apiUrl: '__apiUrl__',
	fileBaseUrl: '__apiUrl__'.replace('/graphql', ''),
	isChildState,
	hasPermission,
}
/*
// In debug mode (running outside of a docker stack) we will find the API on localhost at port 4000 probably.  But inside docker-stack, nginx makes /graphql route there
if (!window.location.host && window.location.protocol === 'file:') {
	context.apiUrl = 'http://localhost:4000/graphql'
}
*/

stateRouter.addState({
	name: 'app',
	route: '',
	template: {
		svelte: true,
		component,
	},
	defaultChild: 'home',
	async resolve(_data, _parameters) {
		const session = getSession()

		if (!session) {
			throw {
				redirectTo: { name: 'login' },
			}
		}

		const { data } = await getData.fetch()
		if (!data?.session?.user) {
			throw new Error('No data was returned from the server')
		}

		const sidebarCollapsed = stringToBoolean(data.session.user.sidebarCollapsed.value)

		const states: Array<SidebarItemType> = [
			{
				type: 'HOME',
				name: i18next.t('nav.home', 'Home'),
				route: 'app.home',
				icon: 'home',
			},
			{
				type: 'ANALYSIS_MANAGEMENT',
				name: i18next.t('nav.analysisManagement', 'Analysis Management'),
				route: 'app.analysis-management',
				icon: {
					prefix: 'fak',
					class: 'fa-solid-table-list-gear',
				},
				hidden: !session.authorizedPlantIDs.some(id => context.hasPermission('ANALYSIS_CAN_OPEN_SCREEN', id)),
			},
			{
				type: 'INVESTIGATION',
				name: i18next.t('nav.investigation', 'Investigation'),
				route: 'app.investigation',
				icon: {
					prefix: 'fas',
					class: 'fa-file-magnifying-glass',
				},
				hidden:
					!session.authorizedPlantIDs.some(id => context.hasPermission('INVESTIGATION_CAN_OPEN_SCREEN', id)) ||
					!session.authorizedPlantIDs.some(id => context.hasPermission('INVESTIGATION_CAN_CONFIGURE', id)),
			},
			{
				type: 'LOCATION',
				name: i18next.t('nav.locations', 'Locations'),
				route: 'app.locations',
				icon: 'shelves',
				hidden: !session.authorizedPlantIDs.some(id => context.hasPermission('LOCATION_CAN_OPEN_SCREEN', id)),
				parameters: {
					plantId: session.siteId.toString(),
				},
			},
			{
				type: 'PRODUCT_MANAGEMENT',
				name: i18next.t('nav.productManagement', 'Product Management'),
				route: 'app.product-management',
				icon: {
					prefix: 'fak',
					class: 'fa-solid-boxes-stacked-gear',
				},
				hidden: !session.authorizedPlantIDs.some(id => context.hasPermission('PRODUCT_CAN_OPEN_SCREEN', id)),
			},
			{
				type: 'VERIFICATION',
				name: i18next.t('nav.verification', 'Verification'),
				route: 'app.verify',
				icon: 'pen-swirl',
			},
			{
				type: 'REPORT_VIEWER',
				name: i18next.t('nav.reportViewer', 'Report Viewer'),
				route: 'app.report-viewer',
				icon: 'file-chart-column',
				hidden: false, // TODO hide based on permissions
			},
			{
				type: 'SAMPLING_HISTORY',
				name: i18next.t('nav.samplingHistory', 'Sampling History'),
				route: 'app.sampling-history',
				icon: 'flask-vial',
			},
			{
				type: 'WORK_ORDER',
				name: i18next.t('nav.workOrder', 'Work Order'),
				route: 'app.work-order',
				icon: 'clipboard-list-check',
				hidden: !session.authorizedPlantIDs.some(id => context.hasPermission('WORK_ORDERS_CAN_OPEN_SCREEN', id)),
			},
			{
				type: 'DATA_ENTRY',
				name: i18next.t('nav.dataEntry', 'Data Entry'),
				route: 'app.data-entry',
				icon: 'mobile-screen',
			},
		]

		return {
			states,
			sidebarExpanded: !sidebarCollapsed,
		}
	},
})

const getData = graphql(`
	query AppData {
		session {
			user {
				sidebarCollapsed: getUserSetting(lookup: { category: "Global", name: "Collapse Presage Web sidebar", settingType: INTERFACE_HISTORY, defaultValue: "True" }) {
					value
				}
			}
		}
	}
`)

// Sort states by depth so that child states are registered after their parents
// @ts-expect-error vite handles this
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
Object.entries(import.meta.glob('./states/**/*.[tj]s', { import: 'default', eager: true }))
	.sort((a, b) => {
		const depthA = a[0].split('/').length
		const depthB = b[0].split('/').length
		return depthA - depthB
	})
	.forEach(([_, createState]) => {
		if (typeof createState === 'function') {
			createState(context)
		}
	})
// @ts-expect-error vite handles this
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
Object.values(import.meta.glob('./services/**/*.[tj]s', { import: 'default', eager: true })).forEach(registerService => {
	if (typeof registerService === 'function') {
		registerService(context)
	}
})

i18next
	.use(locizePlugin)
	.use(Backend)
	.use(LanguageDetector)
	.init({
		fallbackLng: 'en',
		ns: ['web', 'common'],
		defaultNS: 'web',
		/*backend: {
			loadPath: 'http://localhost:8080/{{lng}}/{{ns}}.json',
			crossDomain: true,
		},*/

		backend: {
			projectId: '36bd06f3-692f-4bf9-aacf-e1f97400cdab',
			// eslint-disable-next-line no-constant-condition
			apiKey: '__locizeApiKey__' ? '__locizeApiKey__' : undefined,
			referenceLng: 'en',
		},
		saveMissing: !!'__locizeApiKey__',
		debug: !!'__locizeApiKey__',
	})
	.then(() => {
		document.getElementById('loading-view')?.remove()
		stateRouter.evaluateCurrentRoute('login')
	})
	.catch(console.error)
