export default {
    "name": "CreateAutomaticWorkOrders",
    "kind": "HoudiniMutation",
    "hash": "6462be3fb82f0429cc4c09d0bc8b8312aba8779bf636d6c5fedef6bd658d9409",

    "raw": `mutation CreateAutomaticWorkOrders($automaticWorkOrders: [NewAutomaticWorkOrder!]!) {
  createAutomaticWorkOrders(automaticWorkOrders: $automaticWorkOrders) {
    id
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "createAutomaticWorkOrders": {
                "type": "AutomaticWorkOrder",
                "keyRaw": "createAutomaticWorkOrders(automaticWorkOrders: $automaticWorkOrders)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "automaticWorkOrders": "NewAutomaticWorkOrder"
        },

        "types": {
            "NewAutomaticWorkOrder": {
                "active": "Boolean",
                "cloneAdditionalData": "Boolean",
                "cloneMostRecent": "Boolean",
                "dayOfWeek": "NonNegativeInt",
                "dayOffset": "NonNegativeInt",
                "description": "String",
                "monthOffset": "NonNegativeInt",
                "rank": "NonNegativeInt",
                "scheduleType": "ScheduleType",
                "timeOffset": "String",
                "timezone": "String",
                "workOrderId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=5ebb803076cce28b77688eecb2f8dfc2d0886051ab3ba592ad0f8076b2650c98";