/**
 * Forked from EE Web Q&A input comopnent, this input changes types based on the data type.
 */
import makeItInputComponent from '@isoftdata/input'
import makeItSelectComponent from '@isoftdata/select'
import makeItCheckbox from '@isoftdata/checkbox'
import template from './mighty-morphing-input.html'
import { v4 as uuid } from '@lukeed/uuid'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function createMightyMorphingInputComponent({ twoway }) {
	// eslint-disable-next-line no-undef
	return Ractive.extend({
		template,
		isolated: true,
		components: {
			// checkbox, autocomplete, etc
			itInput: makeItInputComponent({ twoway }),
			itSelect: makeItSelectComponent({ twoway }),
			itCheckbox: makeItCheckbox({ twoway }),
		},
		attributes: {
			required: [ 'dataType', 'value' ],
			optional: [ 'id', 'defaultChoice', 'required', 'disabled', 'choices', 'labelParentDivClass', 'name', 'id', 'placeholder', 'isRequired', 'isValid', 'validWarningText', 'valueProp', 'labelProp' ],
		},
		data() {
			return {
				id: uuid(),
				isRequired: false,
				isValid: true,
				validWarningText: '',
				dataType: 'TEXT',
				defaultChoice: '',
				name: '',
				// public: false, // not sure if we need it, unless we want to show it in the ui
				required: false,
				disabled: false,
				value: '',
				labelParentDivClass: 'form-group mb-1',
				valueProp: 'value',
				labelProp: 'label',
			}
		},
		computed: {
			inputType() {
				const dataType = this.get('dataType')
				switch (dataType) {
					case 'TEXT':
					// case 'AUTOSUGGEST_TEXT':
						return 'text'
					case 'CHOICE':
						return 'select'
					case 'NUMBER':
					case 'INTEGER':
					case 'CURRENCY':
						return 'number'
					case 'DATE':
						return 'date'
					case 'TIME':
						return 'time'
					case 'DATETIME':
						return 'datetime-local'
					// case 'BOOLEAN':
						// return 'checkbox'
					default:
						return 'text'
				}
			},
			/* 			computedValue: {
				get() {
					const value = this.get('value')
					const dataType = this.get('dataType')
					// I don't really want to have to deal with string 'True', even though the itCheckbox can handle it
					if (dataType === 'BOOLEAN' && typeof value === 'string') {
						return value === 'True'
					}
					return value
				},
				set(value) {
					this.set({ value })
				},
			}, */

		},
		oninit() {
			if (!this.get('value') && this.get('defaultChoice')) {
				this.set('value', this.get('defaultChoice'))
			}
		},
	})
}
