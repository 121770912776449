export default {
    "name": "CreateGroups",
    "kind": "HoudiniMutation",
    "hash": "8e87d60d40d743b6d575ea3fdbcc9a67ba65a972e39ac30a50761a3f5aacfbfb",

    "raw": `mutation CreateGroups($inputs: [NewGroup!]!) {
  createGroups(inputs: $inputs) {
    id
    name
    workerGroup
    userAccounts {
      id
      name
    }
    groupPermissions {
      permissionId
      value
      id
    }
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "createGroups": {
                "type": "Group",
                "keyRaw": "createGroups(inputs: $inputs)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "workerGroup": {
                            "type": "Boolean",
                            "keyRaw": "workerGroup",
                            "visible": true
                        },

                        "userAccounts": {
                            "type": "UserAccount",
                            "keyRaw": "userAccounts",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "groupPermissions": {
                            "type": "GroupPermission",
                            "keyRaw": "groupPermissions",

                            "selection": {
                                "fields": {
                                    "permissionId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "permissionId",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "PermissionValue",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inputs": "NewGroup"
        },

        "types": {
            "UpdateGroupPermission": {
                "permissionId": "PositiveInt",
                "permissionValue": "PermissionValue"
            },

            "NewGroup": {
                "groupId": "PositiveInt",
                "groupPermissions": "UpdateGroupPermission",
                "name": "NonEmptyString",
                "userIds": "PositiveInt",
                "workerGroup": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=aa22bacd68f7c50d14a8e276c74bd5bd40ce7601b6f2f189c3d6e0e8594d9862";