export default {
    "name": "DetachFilesFromLocation",
    "kind": "HoudiniMutation",
    "hash": "7a7f7500a185e0822d1430e5d3fb65b1fdd486e340dd417b5ef35b9c2c7d3b37",

    "raw": `mutation DetachFilesFromLocation($fileIds: [PositiveInt!]!, $locationId: PositiveInt!) {
  detachFilesFromLocation(fileIds: $fileIds, locationId: $locationId)
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "detachFilesFromLocation": {
                "type": "Void",
                "keyRaw": "detachFilesFromLocation(fileIds: $fileIds, locationId: $locationId)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "fileIds": "PositiveInt",
            "locationId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=1c0da214785f95ebf50c7419d6e07a8ef2eb2cd5c1c322b7994cff0b31a08aad";