import hasPermission from 'utility/has-permission'

/* export function canEditPlantSpecificFields(plantId) {
	return hasPermission('EditPlantSpecificFields', plantId)
} */

export function canEditPlantSpecificFields() {
	if (!this) {
		throw new Error('canEditPlantSpecificFields must be called as a method on a Ractive instance')
	}
	return hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', this.get('plantId'))
}

export function canEditGlobalFieldsMap() {
	if (!this) {
		throw new Error('canEditGlobalFieldsMap must be called as a method on a Ractive instance')
	}
	const analyses = this.get('analyses')
	const thisPlantId = this.get('plantId')
	return analyses.reduce((acc, analysis) => {
		acc.set(analysis.id, analysis.inUseAtPlantIDs.every(plantId => hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', plantId)))
		return acc
	}, new Map([ [ -1, hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', thisPlantId) ] ])) // -1 = selected plant only
}

export function canEditGlobalFields(analysisId = -1) {
	if (!this) {
		throw new Error('canEditGlobalFields must be called as a method on a Ractive instance')
	}
	return this?.get('canEditGlobalFieldsMap').get(analysisId) ?? false
}
