import type { AppContext, ResolveParameters } from 'types/common'
import component from './Reports.svelte'

import { graphql, ReportsStore } from '$houdini'
import showErrorAndRedirect from 'utility/show-error-and-redirect'

const reportListQuery: ReportsStore = graphql`
	query Reports($pagination: PaginatedInput, $filter: ReportsFilter) {
		reports(pagination: $pagination, filter: $filter) {
			data {
				id
				type
				category
				name
				userVisible
				description
				numberOfParameters
				averageExecutionTime
			}
		}
	}
`

type StateParameters = {
	selectedReportId: number | null
	selectedCategory: string | null
}

export default function ({ mediator, stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer.reports',
		route: 'reports',
		defaultChild: 'preview',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, stateParameters: ResolveParameters<StateParameters>) {
			const selectedReportId = parseInt(stateParameters.selectedReportId, 10) || null

			const { data } = await reportListQuery.fetch({ variables: { filter: { userVisible: true }, pagination: { pageNumber: 1, pageSize: 0 } } })

			if (!data) {
				throw showErrorAndRedirect(mediator, 'Failed to load Report Viewer', 'No data returned from server', { name: 'app' })
			}

			const reportList = data.reports.data.map(report => {
				return { ...report, category: report.category || 'Uncategorized' }
			})

			return { reportList, selectedReportId, selectedCategory: stateParameters.selectedCategory }
		},
	})
}
