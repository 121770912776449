export default {
    "name": "DeleteAutomaticWorkOrders",
    "kind": "HoudiniMutation",
    "hash": "6c780741ccf05e604ca3c9d288fdc53b8d084bf5f577c001ddbead8705ee4c96",

    "raw": `mutation DeleteAutomaticWorkOrders($ids: [PositiveInt!]!) {
  deleteAutomaticWorkOrders(ids: $ids)
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "deleteAutomaticWorkOrders": {
                "type": "Void",
                "keyRaw": "deleteAutomaticWorkOrders(ids: $ids)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "ids": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=5f2a0e3ac050afe1b04c0f97c4693bd4da1a5fdb21d0eedd2190f022426830e5";