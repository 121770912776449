import Detail from './Detail.svelte'
import type { AppContext, ResolveParameters } from 'types/common'
import type { ListParams } from '../list'

type DetailParams = ListParams

export default ({ stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'app.locations.list.detail',
		route: '/detail',
		querystringParameters: ['locationId'],
		defaultParameters: {
			locationId: null,
		},
		template: {
			svelte: true,
			component: Detail,
			options: {
				// these are the options passed to the svelte component
			},
		},
		async resolve(_data, parameters: ResolveParameters<DetailParams>) {
			let locationId: number | null
			if (parameters.locationId && parameters.locationId !== 'null') {
				locationId = parseInt(parameters.locationId, 10)
			} else {
				locationId = null
			}

			return Promise.resolve({
				locationId,
			})
		},
	})
}
