import type { ComponentProps } from 'svelte'
import type { AutomaticWorkOrder } from 'types/automatic-work-order'
import type { AppContext } from 'types/common'
import type { DocumentStatus$options, NewWorkOrder, UpdateWorkOrder, WorkOrder$result } from '$houdini'
import type { WritableDeep } from 'type-fest'
import type { WorkOrderSearchResult } from 'components/WorkOrderSearch.svelte'

import { writable } from 'svelte/store'
import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
import makeCrudStore from '@isoftdata/svelte-store-crud'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import { getSession } from 'stores/session'

import svelteComponent from './WorkOrder.svelte'
import type { MediatorProviders } from 'client/services/api-fetch'

export default ({ mediator, stateRouter, hasPermission, i18next: { t: translate } }: AppContext) => {
	stateRouter.addState({
		name: 'app.work-order',
		route: 'work-order',
		defaultChild: 'edit',
		querystringParameters: ['lastResetTime', 'lastSavedTime'],
		defaultParameters: {
			lastResetTime: null,
			lastSavedTime: null,
		},
		template: {
			svelte: true,
			component: svelteComponent,
			options: {},
		},
		resolve(_data, parameters) {
			const { siteId } = getSession()

			if (!hasPermission('WORK_ORDERS_CAN_OPEN_SCREEN', siteId)) {
				showErrorAndRedirect(mediator, 'Permission Denied', 'You do not have permission to open the Work Orders screen.')
			}

			const childStates: ComponentProps<AsrNavTabBar>['tabs'] = [
				{
					name: 'app.work-order.automatic',
					title: translate('work-order.titles.automatic', 'Automatic'),
					inherit: false,
					hidden: !hasPermission('WORK_ORDERS_CAN_MANAGE_AUTOMATIC', siteId),
				},
				{
					name: 'app.work-order.edit',
					title: translate('work-order.titles.edit', 'Edit'),
					inherit: false,
					hidden: !hasPermission('WORK_ORDERS_CAN_VIEW', siteId),
				},
			]

			return Promise.resolve({
				childStates,
				lastSavedTime: Number(parameters.lastSavedTime),
				canEditWorkOrders: hasPermission('WORK_ORDERS_CAN_EDIT', siteId),
				workOrderFavoritesStore: makeCrudStore<WorkOrderSearchResult, 'id'>('id'),
				automaticWorkOrderCrudStore: makeCrudStore<AutomaticWorkOrder, 'uuid'>('uuid'),
				sampleCrudStore: makeCrudStore<Sample, 'uuid'>('uuid'),
				sampleAttachmentCrudStore: makeCrudStore<MetaSampleAttachment, 'uuid'>('uuid'),
				woDocumentStore: makeWoDocumentStore(),
			})
		},
	})
}

// opinionated store factory, for tracking the updates to a single WO document
// Assume it's been modified if it's not null
function makeWoDocumentStore() {
	type WoData = (NewWorkOrder & { id?: undefined }) | UpdateWorkOrder
	type StoreData = (WoData & { documentStatus: DocumentStatus$options; statusChanged?: boolean }) | null
	let value: StoreData = null
	const store = writable<StoreData>(value)
	const svIdsToUpdate = new Set<number>()
	// Have all samples been completely filled out? (all values have been entered)
	let allSamplesPerformed = false
	// Have all samples been closed?
	let allSamplesClosed = false

	function set(newVal: StoreData) {
		store.update((oldVal: StoreData): StoreData => {
			value = newVal

			if (newVal === null) {
				return null
			}

			// if the status has changed, we need to track that
			const statusChanged = !!oldVal?.statusChanged || !!newVal?.statusChanged || (!!oldVal && !!newVal && oldVal.documentStatus !== newVal.documentStatus)

			return {
				...newVal,
				statusChanged,
			}
		})
	}

	/** The normal "set" function has to take in a StoreData object, so this one will handle the conversion  */
	function setFromDocument(wo: WorkOrder, statusChanged: boolean = false) {
		const obj: StoreData = {
			assignedToGroupId: wo.assignedToGroup?.id,
			documentStatus: wo.documentStatus,
			due: wo.due,
			favorite: wo.favorite,
			instructions: wo.instructions,
			internalNotes: wo.internalNotes,
			plantId: wo.plant.id,
			productBatchId: wo.productBatch?.id,
			scheduled: wo.scheduled,
			// sourceWorkOrderId: wo.sourceWorkOrder?.id,
			id: wo.id ? wo.id : undefined,
			title: wo.title,
			verificationDue: wo.verificationDue,
			verifiedByUserId: wo.verifiedByUser?.id ?? null,
			verifiedOn: wo.verifiedOn ?? null,
			workOrderTypeId: wo.workOrderType.id,
		}

		if (obj && statusChanged) {
			obj.statusChanged = true
		}

		set(obj)
	}

	return {
		subscribe: store.subscribe,
		set,
		setFromDocument,
		clear() {
			store.set(null)
		},
		hasChanges() {
			return value !== null
		},
		get() {
			return value
		},
		// These won't be reactive, but I don't need them to be, just to check them at save time
		sampleValueUpdated(svId: number | Array<number>) {
			if (Array.isArray(svId)) {
				svId.forEach(id => svIdsToUpdate.add(id))
			} else {
				svIdsToUpdate.add(svId)
			}
		},
		get sampleValuesToUpdate() {
			return svIdsToUpdate
		},
		get allSamplesPerformed() {
			return allSamplesPerformed
		},
		set allSamplesPerformed(val: boolean) {
			allSamplesPerformed = val
		},
		get allSamplesClosed() {
			return allSamplesClosed
		},
		set allSamplesClosed(val: boolean) {
			allSamplesClosed = val
		},
	}
}

export type WoDocumentStore = ReturnType<typeof makeWoDocumentStore>
export type MetaSampleAttachment = {
	/** New files will have a File object on them*/
	File?: File
	sampleId: number | null
	sampleUuid: string
	mimeType: string
	name: string
	path: string
	public: boolean
	rank: number
	size: number
	uuid: string
	fileId?: number
}

export type WorkOrder = Omit<WritableDeep<WorkOrder$result['workOrder']>, 'samples'> & { samples: Array<Sample> }
export type Sample = Omit<WritableDeep<WorkOrder$result['workOrder']>['samples'][number], 'attachments'> & { uuid: string; attachments: MetaSampleAttachment[] }
export type SampleValue = Sample['sampleValues'][number]
export type DisplaySample = Omit<Sample, 'sampleValues'> & { sampleValues: Array<DisplaySampleValue | null> }
export type DisplaySampleValue = SampleValue & { svOgIndex: number }

export type WoMediatorProviders = MediatorProviders & {
	saveWorkOrder: () => Promise<void>
	sampleMissingRequiredValuesForStatus: (sample: Sample, sampleStatus: DocumentStatus$options) => Array<SampleValue>
	allSamplesPerformed: () => boolean
	allSamplesCompleted: () => boolean
	allSamplesClosed: () => boolean
	updateSampleStatuses: (newStatus: 'CLOSED' | 'SAMPLED') => void
	titleAndProductBatchMissing: () => { titleMissing: boolean; productBatchMissing: boolean }
}

export type WorkOrderOrderFilter = {
	field: string
	direction: 'ASC' | 'DESC'
}
