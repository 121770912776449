export default {
    "name": "GenerateCrystalReport",
    "kind": "HoudiniMutation",
    "hash": "9c54b4fcbe934e5039b2c570e54b2f4a744151422bb360c8011a2c03cd004dbd",

    "raw": `mutation GenerateCrystalReport($reportJob: GenerateCrystalReportInput!) {
  generateCrystalReport(reportJob: $reportJob) {
    data
    mimeType
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "generateCrystalReport": {
                "type": "GenerateCrystalReportResponse",
                "keyRaw": "generateCrystalReport(reportJob: $reportJob)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "String",
                            "keyRaw": "data",
                            "visible": true
                        },

                        "mimeType": {
                            "type": "String",
                            "keyRaw": "mimeType",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "reportJob": "GenerateCrystalReportInput"
        },

        "types": {
            "ReportParameterInput": {
                "key": "String",
                "value": "String"
            },

            "GenerateCrystalReportInput": {
                "id": "PositiveInt",
                "name": "String",
                "parameters": "ReportParameterInput",
                "type": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=652405f1877562ea4b5caa18af0c17df14789b8beed98e514e4feba193128b7d";