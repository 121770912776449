import svelteComponent from './Settings.svelte'
import apiFetch from 'utility/api-fetch'
import pProps from 'p-props'
import type { UserSetting } from 'utility/setting.d'
import { stringToBoolean } from '@isoftdata/utility-string'
import { getSession } from 'stores/session'
import { AppContext, DefaultParameters, ResolveParameters, SvelteDomApi } from 'client/types/common'

type SettingsParameters = {
	lastResetTime: string | null
	lastSavedTime: string | null
	selectedCategory: string
}

export default ({ mediator, stateRouter, i18next: { t } }: AppContext) => {
	stateRouter.addState({
		name: 'app.configuration.settings',
		route: 'settings',
		querystringParameters: ['lastResetTime', 'lastSavedTime', 'selectedCategory'],
		defaultParameters: {
			lastResetTime: null,
			lastSavedTime: null,
			selectedCategory: 'All',
		} satisfies DefaultParameters<SettingsParameters>,
		template: {
			svelte: true,
			component: svelteComponent,
			options: {
				// these are the options passed to the svelte component
			},
		},
		canLeaveState(domApi) {
			return (
				!(domApi as SvelteDomApi & svelteComponent).settingsChanged || confirm(t('common:canLeaveState', 'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.'))
			)
		},
		async resolve(_data, parameters: ResolveParameters<SettingsParameters>) {
			const { lastSavedTime, selectedCategory } = parameters
			const { user } = getSession()
			const { settings } = (await pProps({
				settings: apiFetch(
					mediator,
					{
						query: queries.settings,
					},
					'getSettingsWithValues'
				) as Promise<UserSetting[]>,
			})) as { settings: UserSetting[] }

			const mappedSettings = settings.reduce((acc, setting) => {
				switch (setting.scope) {
					case 'Global':
						if (user.permissions.SETTINGS_GLOBAL_ADMINISTRATOR === 'GLOBAL') {
							acc.push({
								...setting,
								dataType: checkSettingValue(setting.value as string),
								dirty: false,
								defaultDirty: false,
							})
						}
						break
					case 'Plant':
						if (user.permissions.SETTINGS_PLANT_ADMINISTRATOR === 'PLANT' || user.permissions.SETTINGS_GLOBAL_ADMINISTRATOR === 'GLOBAL') {
							acc.push({
								...setting,
								dataType: checkSettingValue(setting.value as string),
								dirty: false,
								defaultDirty: false,
							})
						}
						break
					case 'User':
						acc.push({
							...setting,
							dataType: checkSettingValue(setting.value as string),
							dirty: false,
							defaultDirty: false,
						})
						break
				}
				return acc
			}, new Array<UserSetting>())

			const configurationSettings = (await pProps({
				importantConfiguration: apiFetch(
					mediator,
					{
						query: queries.getUserSetting,
						variables: {
							lookup: {
								category: 'Configuration',
								name: "Configure Settings Dialog: Show 'important configuration' settings",
								settingType: 'INTERFACE_HISTORY',
								defaultValue: 'True',
							},
						},
					},
					'getCurrentUserSetting.value'
				),
				interfaceHistory: apiFetch(
					mediator,
					{
						query: queries.getUserSetting,
						variables: {
							lookup: {
								category: 'Configuration',
								name: "Configure Settings Dialog: Show 'interface history' settings",
								settingType: 'INTERFACE_HISTORY',
								defaultValue: 'True',
							},
						},
					},
					'getCurrentUserSetting.value'
				),
				interfacePreference: apiFetch(
					mediator,
					{
						query: queries.getUserSetting,
						variables: {
							lookup: {
								category: 'Configuration',
								name: "Configure Settings Dialog: Show 'interface preference' settings",
								settingType: 'INTERFACE_HISTORY',
								defaultValue: 'True',
							},
						},
					},
					'getCurrentUserSetting.value'
				),
				optionalConfiguration: apiFetch(
					mediator,
					{
						query: queries.getUserSetting,
						variables: {
							lookup: {
								category: 'Configuration',
								name: "Configure Settings Dialog: Show 'optional configuration' settings",
								settingType: 'INTERFACE_HISTORY',
								defaultValue: 'True',
							},
						},
					},
					'getCurrentUserSetting.value'
				),
				preference: apiFetch(
					mediator,
					{
						query: queries.getUserSetting,
						variables: {
							lookup: {
								category: 'Configuration',
								name: "Configure Settings Dialog: Show 'preference' settings",
								settingType: 'INTERFACE_HISTORY',
								defaultValue: 'True',
							},
						},
					},
					'getCurrentUserSetting.value'
				),
			})) as {
				importantConfiguration: string
				interfaceHistory: string
				interfacePreference: string
				optionalConfiguration: string
				preference: string
			}

			const mapppedConfigurationSettings = {
				importantConfiguration: stringToBoolean(configurationSettings.importantConfiguration) ?? false,
				interfaceHistory: stringToBoolean(configurationSettings.interfaceHistory) ?? false,
				interfacePreference: stringToBoolean(configurationSettings.interfacePreference) ?? false,
				optionalConfiguration: stringToBoolean(configurationSettings.optionalConfiguration) ?? false,
				preference: stringToBoolean(configurationSettings.preference) ?? false,
			}

			return {
				settings: mappedSettings,
				configurationSettings: mapppedConfigurationSettings,
				lastSavedTime: Date.parse(lastSavedTime),
				selectedCategory: selectedCategory ?? 'All',
				// these are the options passed to the svelte component
			}
		},
	})
}

function checkSettingValue(settingValue: string) {
	// This is an abstraction to check the type of the setting value for display
	const numberRegex = /^\d+$/
	const booleanRegex = /^(True|False)$/
	if (numberRegex.test(settingValue)) {
		return 'number'
	} else if (booleanRegex.test(settingValue)) {
		return 'boolean'
	}
	return 'string'
}

const queries = {
	settings: `#graphql
		query GetSettingsWithValues {
			getSettingsWithValues {
				id
				name
				category: location
				cascade
				value
				scope
				defaultValue
				type: settingType
			}
		}
    `,
	getUserSetting: `#graphql
		query GetCurrentUserSetting($lookup: SettingLookup!) {
			getCurrentUserSetting(lookup: $lookup) {
				value
			}
		}
	`,
}
