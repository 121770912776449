import template from './analysis-management.html'

import { getSession } from 'stores/session'

//Ractive components
import makeNavTabBar from '@isoftdata/nav-tab-bar-component'
import makeItButton from '@isoftdata/button'

export default function({ mediator, stateRouter, hasPermission, i18next }) {
	stateRouter.addState({
		name: 'app.analysis-management',
		route: 'analysis-management',
		defaultChild: 'analyses',
		querystringParameters: [ 'lastResetTime', 'lastSavedTime' ],
		defaultParameters: {
			lastResetTime: null,
			lastSavedTime: null,
		},
		template: {
			template,
			translate: i18next.t,
			components: {
				navTabBar: makeNavTabBar(stateRouter),
				itButton: makeItButton(),
			},
			data: {
				childStates: [],
			},
			computed: {
				showNavTabBar() {
					return this.get('childStates').filter(state => !state.hidden && !state.disabled).length > 1
				},
				lastSaved() {
					const lastSavedTime = this.get('lastSavedTime')
					return lastSavedTime ? new Date(parseInt(lastSavedTime, 10)).toLocaleTimeString() : 'never'
				},
			},
		},
		resolve(_data, { lastSavedTime, lastResetTime }) {
			const { authorizedPlantIDs } = getSession()

			if (!authorizedPlantIDs.some(id => hasPermission('ANALYSIS_CAN_OPEN_SCREEN', id))) {
				throw {
					redirectTo: { name: 'app.home' },
				}
			}
			return Promise.resolve({
				lastSavedTime,
				lastResetTime,
				// I think we're just building out the one state for now, but might as well lay the groundwork for when we have multiple.
				childStates: [
					{
						title: 'Analyses',
						stateName: 'app.analysis-management.analyses',
						stateParameters: {},
						stateOptions: {},
						hidden: false, // todo permissions
						disabled: false, // todo permissions
					},
					{
						title: 'Scheduled Analysis Plan',
						stateName: 'app.analysis-management.scheduled-analysis-plan',
						stateParameters: {},
						stateOptions: {},
						hidden: true, // todo make this state and also check permissions
					},
					{
						title: 'Severity Classes',
						stateName: 'app.analysis-management.severity-classes',
						stateParameters: {},
						stateOptions: {},
						hidden: true, // todo make this state and also check permissions
					},
				],
			})
		},
		activate(context) {
			const { domApi: ractive } = context
			// The top bar and save button are in this state, but the child states may need to save with their own logic
			ractive.on('analysis-management-save', () => {
				mediator.call('showMessage', { heading: 'Saving...', message: '', type: 'info', time: false })
				try {
					mediator.call('analysis-management-save').then(saved => {
						// if (saved) {
						// 	ractive.set('lastSavedTime', Date.now())
						// }
					})
				} catch (err) {
					console.error(err)
					alert('Save function not provided by child state')
				}
			})

			ractive.on('analysis-management-reset', () => {
				let hasUnsavedChanges = true
				try {
					hasUnsavedChanges = mediator.call('analysis-management-has-unsaved-changes')
				} catch (err) {
					// Swallow. Worst case scenario is we'll ask if they're sure when we don't have to.
				}
				if (!hasUnsavedChanges || confirm('Are you sure you want to reload? You will lose all unsaved changes.')) {
					// Date parameter is a hack to force a reload, since a state parameter has to change
					stateRouter.go(null, { lastResetTime: Date.now(), lastSavedTime: null }, { inherit: true })
				}
			})
		},
	})
}
