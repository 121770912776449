export default {
    "name": "WoGetOptionRestrictions",
    "kind": "HoudiniQuery",
    "hash": "2ebf6331d783eaf71674e1cfb2d8e9d2f3282778f0fd87b331b547a8b273c741",

    "raw": `query WoGetOptionRestrictions($getOptionRestrictionsInput: [GetOptionRestrictionInput!]!) {
  getOptionRestrictions(options: $getOptionRestrictionsInput) {
    analysisOptionId
    locationId
    plantId
    productId
    restriction
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "getOptionRestrictions": {
                "type": "GetOptionRestrictionOutput",
                "keyRaw": "getOptionRestrictions(options: $getOptionRestrictionsInput)",

                "selection": {
                    "fields": {
                        "analysisOptionId": {
                            "type": "PositiveInt",
                            "keyRaw": "analysisOptionId",
                            "visible": true
                        },

                        "locationId": {
                            "type": "PositiveInt",
                            "keyRaw": "locationId",
                            "nullable": true,
                            "visible": true
                        },

                        "plantId": {
                            "type": "PositiveInt",
                            "keyRaw": "plantId",
                            "visible": true
                        },

                        "productId": {
                            "type": "PositiveInt",
                            "keyRaw": "productId",
                            "nullable": true,
                            "visible": true
                        },

                        "restriction": {
                            "type": "OutcomeOrNone",
                            "keyRaw": "restriction",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "getOptionRestrictionsInput": "GetOptionRestrictionInput"
        },

        "types": {
            "GetOptionRestrictionInput": {
                "analysisOptionId": "PositiveInt",
                "locationId": "PositiveInt",
                "plantId": "PositiveInt",
                "productId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=7b884e8a7a1f3980fed92c0f2c4b7b8f1bbab535ab0e647989e7d9d550f6b5ed";