export default {
    "name": "VerifyPermission",
    "kind": "HoudiniMutation",
    "hash": "32b48dedcff29a46c9ce25da06476fc99f72b02973723ba7240b1c7e8e8f3629",

    "raw": `mutation VerifyPermission($input: VerifyPermissionInput!) {
  verifyPermission(input: $input)
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "verifyPermission": {
                "type": "PositiveInt",
                "keyRaw": "verifyPermission(input: $input)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "VerifyPermissionInput"
        },

        "types": {
            "VerifyPermissionInput": {
                "action": "VerificationAction",
                "password": "NonEmptyString",
                "plantId": "PositiveInt",
                "recordId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=14c1cfdd20269fe53c8b8323a10f9b4db4cbf52c4b69cf2e002af5bc12250680";