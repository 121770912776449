<svelte:options accessors={true} />

<script lang="ts">
	import type { Plant, State } from 'types/plant'
	import type { Mediator } from 'client/services/api-fetch'
	import type { SvelteAsr } from 'types/common'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { SaveResetProps } from '../configuration'

	import Select from '@isoftdata/svelte-select'
	import SiteDetails from '@isoftdata/svelte-sitedetails'
	import TagSelection, { type MetaTag } from 'components/TagSelection.svelte'

	import hasPermission from 'utility/has-permission'
	import { getContext } from 'svelte'
	import { v4 as uuid } from '@lukeed/uuid'
	import pProps from 'p-props'
	import apiFetch from 'utility/api-fetch'

	const mediator = getContext<Mediator>('mediator')
	const i18next = getContext<{ t: (key: string, fallback: string) => string }>('i18next')

	export let asr: SvelteAsr
	export let queries: Record<string, string>
	export let mutations: Record<string, string>

	const newPlantTemplate = {
		id: null,
		name: '',
		code: '',
		street: '',
		city: '',
		state: '',
		zip: '',
		country: '',
		phone: '',
		fax: '',
		timezone: '',
		private: false,
		deleted: false,
		tags: [],
		dirty: true,
	}

	function formatNewPlantForSave(plant: Plant) {
		return {
			city: plant.city,
			code: plant.code,
			country: plant.country,
			fax: plant.fax,
			name: plant.name,
			phone: plant.phone,
			private: plant.private,
			state: plant.state,
			street: plant.street,
			timezone: plant.timezone,
			zip: plant.zip,
			tagIds: plant.tags.map(plantTag => tags.find(tag => tag.uuid === plantTag.uuid)?.id),
		}
	}
	function formatPlantForUpdate(plant: Plant) {
		return {
			id: plant.id,
			...formatNewPlantForSave(plant),
		}
	}

	function formatNewTagForSave(tag: MetaTag) {
		return {
			name: tag.name,
			entityType: tag.entityType.toUpperCase(),
			active: tag.active,
		}
	}
	function formatTagForUpdate(tag: MetaTag) {
		return {
			id: tag.id,
			...formatNewTagForSave(tag),
		}
	}

	export let plants: Plant[]
	export let timezones: string[]
	export let states: State[]
	export let selectedPlantUuid: string
	/** Master tag array. Each plant will have its own list of which tags it is using.*/
	export let tags: MetaTag[] = [] //
	export let tagCrudStore: CrudStore<MetaTag, 'uuid'>
	export let hasUnsavedChanges: boolean = false
	export let saveResetProps: SaveResetProps
	$: $saveResetProps = {
		disabled: !hasUnsavedChanges,
		resetHref: asr.makePath(null, { lastResetTime: Date.now(), lastSavedTime: null }, { inherit: true }),
		save: savePage,
	}
	/** If this is truthy, canLeaveState will not trigger the confirm() dialog*/

	$: selectedPlantIndex = plants.findIndex(plant => plant.uuid === selectedPlantUuid)

	$: plantsToCreate = plants.filter(plant => !plant.id)
	$: plantsToUpdate = plants.filter(plant => plant.id && plant.dirty && !plant.deleted)
	$: plantsToDelete = plants.filter(plant => plant.deleted)

	let tagsToCreate: MetaTag[] = []
	let tagsToUpdate: MetaTag[] = []
	let tagsToDelete: MetaTag[] = []

	$: hasUnsavedChanges = plantsToCreate.length > 0 || plantsToUpdate.length > 0 || plantsToDelete.length > 0 || tagsToCreate.length > 0 || tagsToUpdate.length > 0 || tagsToDelete.length > 0

	$: updateCount = plantsToCreate.length + plantsToUpdate.length + plantsToDelete.length + tagsToCreate.length + tagsToUpdate.length + tagsToDelete.length

	$: canEditSelectedPlant = checkPermission(plants[selectedPlantIndex]?.id)

	//hasPermission is typed in sucha way it needs a number input, but plantId can be null on this type of lookup so we wrap it
	function checkPermission(plantId: number | null) {
		if (plantId === null) {
			return false
		}
		return hasPermission('CONFIGURATION_CAN_CONFIGURE_PLANTS', plantId) ?? false
	}

	// After saving tags before saving plants
	function updateTagsArray(tagsToCreate: MetaTag[]) {
		if (!tagsToCreate.length) {
			return tags
		}
		const tagIdsToUpdate = tags.map(tag => {
			const createdTag = tagsToCreate.find(createdTag => createdTag.name === tag.name)
			if (createdTag) {
				return {
					...tag,
					id: createdTag.id,
				}
			}
			return tag
		})
		tagCrudStore.clear()
		return tagIdsToUpdate
	}

	async function getLocationDataFromZip(zip: string) {
		const res = (await apiFetch(
			mediator,
			{
				query: queries.locationData,
				variables: {
					zipcode: zip,
				},
			},
			'getCityStateAbbvCountryFromZip',
		)) as { city: string; stateAbbreviation: string; country: string }
		if (res) {
			return res
		}
		return null
	}

	async function saveTags() {
		const { createdTags } = await pProps({
			createdTags: tagsToCreate.length
				? apiFetch(
						mediator,
						{
							query: mutations.createTags,
							variables: {
								input: tagsToCreate.map(tag => formatNewTagForSave(tag)),
							},
						},
						'createEntityTags',
					)
				: null,
			updatedTags: tagsToUpdate.length
				? apiFetch(mediator, {
						query: mutations.updateTags,
						variables: {
							input: tagsToUpdate.map(tag => formatTagForUpdate(tag)),
						},
					})
				: null,
			deletedTags: tagsToDelete.length
				? apiFetch(mediator, {
						query: mutations.deleteTags,
						variables: {
							ids: tagsToDelete.map(tag => tag.id),
						},
					})
				: null,
		})
		if (createdTags) {
			tags = updateTagsArray(createdTags)
		}
		return tags
	}

	async function savePlants() {
		await Promise.all([
			plantsToCreate.length
				? apiFetch(mediator, {
						query: mutations.createPlants,
						variables: {
							plants: plantsToCreate.map(plant => formatNewPlantForSave(plant)),
						},
					})
				: null,
			plantsToUpdate.length
				? apiFetch(mediator, {
						query: mutations.updatePlants,
						variables: {
							plants: plantsToUpdate.map(plant => formatPlantForUpdate(plant)),
						},
					})
				: null,
			plantsToDelete.length
				? apiFetch(mediator, {
						query: mutations.deletePlants,
						variables: {
							ids: plantsToDelete.map(plant => plant.id),
						},
					})
				: null,
		])
	}
	async function savePage() {
		if (updateCount === 0) {
			mediator.call('showMessage', { type: 'info', heading: 'No changes to save', message: 'No changes to save', time: 10 })
			return
		}
		try {
			tags = await saveTags() // Saves tags and assigns it so plants can get updated IDs
			await savePlants()
			hasUnsavedChanges = false
			asr.go(null, { lastSavedTime: Date.now(), lastResetTime: null }, { inherit: true }) //Reload the page after saving
		} catch (err: any) {
			const error = err.message ?? ''
			mediator.call('showMessage', { type: 'danger', heading: 'Error saving; Plants not saved', message: error, time: 10000 })
			console.error(err)
		}
	}
	function createNewPlant() {
		plants.push({ ...newPlantTemplate, uuid: uuid() })
		plants = plants
		selectedPlantUuid = plants[plants.length - 1].uuid
	}
	function deleteCurrentPlant() {
		if (plants[selectedPlantIndex].deleted) {
			plants[selectedPlantIndex].deleted = false
			plants = plants
			return
		}
		if (plants[selectedPlantIndex].getPlantUsageCount?.analyseCount || plants[selectedPlantIndex].getPlantUsageCount?.productCount) {
			alert(
				`This plant is in use by ${plants[selectedPlantIndex]?.getPlantUsageCount?.analyseCount ?? 0} analyses, and ${
					plants[selectedPlantIndex]?.getPlantUsageCount?.productCount ?? 0
				} products. Please remove these references before deleting.`,
			)
			return
		}
		if (confirm('Are you sure you want to delete this plant?') === false) {
			return
		}
		plants[selectedPlantIndex].deleted = true
		plants = plants
		selectedPlantUuid = plants[0].uuid
	}
	async function updateLocationFieldsFromZip() {
		const zip = plants[selectedPlantIndex].zip
		if (!zip) {
			return
		}
		const locationData = await getLocationDataFromZip(zip)
		if (!locationData) {
			return
		}
		plants[selectedPlantIndex].city = locationData.city
		plants[selectedPlantIndex].state = states.find(state => state.abbreviation === locationData.stateAbbreviation)?.abbreviation ?? ''
		plants[selectedPlantIndex].country = locationData.country
		plants = plants
	}
</script>

<class class="form-row">
	<Select
		label="Plant"
		bind:value={selectedPlantUuid}
		showEmptyOption={false}
		options={plants}
		let:option={plant}
	>
		<option value={plant?.uuid}>{plant?.code ? `${plant?.code} -` : ''}{plant?.name || 'Enter a Plant name'}</option>
	</Select>
</class>
<hr />
<div class="row row-cols-1 row-cols-md-2">
	<div class="col-md-6 col-12 p-1 h-100">
		<SiteDetails
			{i18next}
			{timezones}
			{states}
			siteAlias="plant"
			canEditSelectedSite={canEditSelectedPlant}
			createNewSite={createNewPlant}
			deleteCurrentSite={deleteCurrentPlant}
			{updateLocationFieldsFromZip}
			bind:sites={plants}
			bind:selectedSiteUuid={selectedPlantUuid}
		/>
	</div>
	<div class="col-md-6 col-12 p-1">
		<TagSelection
			buttonsInHeader
			entityType="PLANT"
			disabled={!canEditSelectedPlant}
			title={plants[selectedPlantIndex].name}
			bind:tags
			bind:tagCrudStore
			bind:tagsInUse={plants[selectedPlantIndex].tags}
			bind:tagsToCreate
			bind:tagsToUpdate
			bind:tagsToDelete
			on:tagsInUseChange={() => (plants[selectedPlantIndex].dirty = true)}
		/>
	</div>
</div>
