export default {
    "name": "CreateReportQueueJob",
    "kind": "HoudiniMutation",
    "hash": "e1ccc945c6b8ac149cae9a6b6babeb98f5d3f9f9a59663df43129629aceccd03",

    "raw": `mutation CreateReportQueueJob($newReportQueue: NewReportQueue!) {
  createReportQueueJob(newReportQueue: $newReportQueue) {
    id
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "createReportQueueJob": {
                "type": "ReportQueue",
                "keyRaw": "createReportQueueJob(newReportQueue: $newReportQueue)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "newReportQueue": "NewReportQueue"
        },

        "types": {
            "NewReportQueueParameter": {
                "parameterName": "String",
                "value": "String"
            },

            "NewReportQueue": {
                "attachmentName": "String",
                "createdByUserId": "PositiveInt",
                "destination": "String",
                "destinationType": "DestinationType",
                "emailBcc": "String",
                "emailBody": "String",
                "emailFrom": "String",
                "emailSubject": "String",
                "error": "String",
                "executionTime": "Float",
                "lastSentOn": "DateTime",
                "name": "String",
                "notes": "String",
                "parameters": "NewReportQueueParameter",
                "quantity": "Float",
                "reportId": "PositiveInt",
                "sourceQuery": "String",
                "sourceType": "SourceType",
                "status": "ReportQueueStatus",
                "title": "String",
                "type": "String",
                "waitUntil": "DateTime"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=6f7f1be4adf1e3a1ac04104560ae9bd296e8d46d5e5dc644c52d9da59fae34aa";