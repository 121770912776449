export default {
    "name": "WoLocations",
    "kind": "HoudiniQuery",
    "hash": "6ac5c6bdf209de7077097090a7095dfe0c6d75d99d2ba170cbed4c36609bb9db",

    "raw": `query WoLocations($locationsFilter: LocationFilter, $pagination: PaginatedInput) {
  locations(filter: $locationsFilter, pagination: $pagination) {
    data {
      ...WoLocationData
      id
    }
  }
}

fragment WoLocationData on Location {
  id
  location
  description
  plantId
  severityClass {
    id
    default
  }
  attachmentCount(fileType: IMAGE)
  __typename
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "locations": {
                "type": "LocationResponse",
                "keyRaw": "locations(filter: $locationsFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Location",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "location": {
                                        "type": "String",
                                        "keyRaw": "location",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "visible": true
                                    },

                                    "severityClass": {
                                        "type": "SeverityClass",
                                        "keyRaw": "severityClass",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "default": {
                                                    "type": "Boolean",
                                                    "keyRaw": "default",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "attachmentCount": {
                                        "type": "UnsignedInt",
                                        "keyRaw": "attachmentCount(fileType: IMAGE)",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "WoLocationData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "locationsFilter": "LocationFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "ProcessZoneFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            },

            "ProductProximityFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            },

            "LocationFilter": {
                "activeOnly": "Boolean",
                "location": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "processZone": "ProcessZoneFilter",
                "productProximity": "ProductProximityFilter",
                "testableOnly": "Boolean"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=69a64f71c22103887447921ed58cb1467e8bc3c763e0996339d9a9a158c8da3c";