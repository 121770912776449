<script
	context="module"
	lang="ts"
>
	//Types
	import type { i18n } from 'i18next'
	import type { Mediator } from 'services/api-fetch'
	import type {
		AlertSubscription,
		AlertType,
		AlertTypeMap,
		AlertTypeCategory,
		AlertTimingWithKey,
		Schedule,
		UserAccount,
		Plant,
		PhoneCarrier,
		Analysis,
		Product,
		Group,
		AlertTemplate,
		WorkOrderType,
		DataResultStatus,
		UserResultStatus,
		InvestigationRule,
		LocationData,
	} from 'utility/alert-subscription-helper'
	import type { ClientSession } from 'stores/session'

	import makeTranslateAction from '@isoftdata/svelte-action-translate'
	import { klona } from 'klona'
	import { getContext, createEventDispatcher } from 'svelte'
	import {
		buildTranslatedHelpers,
		hasValidTiming,
		canSpecifySchedule as computeCanSpecifySchedule,
		canSpecifyExpirationOffset as computeCanSpecifyExpirationOffset,
		emailIsValid as computeEmailIsValid,
		locationsQuery,
		updateAlertSubscription,
		createAlertSubscription,
	} from 'utility/alert-subscription-helper'
</script>

<script lang="ts">
	const dispatch = createEventDispatcher<{
		/** Fired when an alert subcrpition is created*/
		alertSubscriptionCreated: AlertSubscription
		/** Fired when an alert subcrpition is updated*/
		alertSubscriptionUpdated: AlertSubscription
	}>()
	const { t: translate } = getContext<i18n>('i18next')
	const mediator = getContext<Mediator>('mediator')
	const translated = makeTranslateAction(translate)
	const {
		alertTypeMap,
		alertMethodMap,
		alertTypesGroupedByCategory,
		alertTypeCategories,
		getAlertTimings,
		buildNewAlertSubscriptionForApiFromViewState,
		buildSavedAlertSubscriptionForApiFromViewState,
		buildAlertSubscriptionForViewStateFromApi,
	} = buildTranslatedHelpers(translate)

	import Label from '@isoftdata/svelte-label'
	import Modal from '@isoftdata/svelte-modal'
	import Button from '@isoftdata/svelte-button'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Textarea from '@isoftdata/svelte-textarea'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import { NavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	import SummaryPreviewConfiguration from './SummaryPreviewConfiguration.svelte'
	import StandardPreviewConfiguration from './StandardPreviewConfiguration.svelte'
	import AlertPreview from './AlertPreview.svelte'
	import EmailAddressPicker from './EmailAddressPicker.svelte'

	//Component instance references
	let alertPreviewComponent: AlertPreview
	let emailAddressPicker: EmailAddressPicker

	//Methods that can be called by the consumer
	export function showModal(givenAlertSubscription?: AlertSubscription, isClone?: boolean) {
		if (givenAlertSubscription) {
			if (isClone) {
				const { id, ...cloneAlertSubscription } = givenAlertSubscription
				alertSubscription = klona(cloneAlertSubscription)
				title = translate('configuration.alertSubscription.CloneAlertSubscriptionModalTitle', 'Clone Alert Subscription')
			} else {
				alertSubscription = klona(givenAlertSubscription)
				title = translate('configuration.alertSubscription.EditAlertSubscriptionModalTitle', 'Edit Alert Subscription')
			}
		} else {
			alertSubscription = klona({
				...defaultAlertSubscription,
				assignedToUserAccountId: session.userAccountId,
				message: {
					...defaultAlertSubscription.message,
					emailAddress: [session.user.workEmail ?? ''],
				},
			})
			emailAddressString = alertSubscription.message.emailAddress.join(';')
			title = translate('configuration.alertSubscription.AddAlertSubscriptionModalTitle', 'New Alert Subscription')
		}
		show = true
	}

	const defaultAlertSubscription: AlertSubscription = Object.freeze<AlertSubscription>({
		id: undefined,
		assignedToUserAccountId: undefined,
		active: true,
		type: null,
		timing: null,
		scheduleId: null,
		hours: 0,
		tense: 'BEFORE',
		description: '',
		message: {
			title: '',
			body: '',
			method: 'EMAIL',
			emailAddress: [],
			phone: [
				{
					number: '',
					carrier: '',
					carrierDomain: '',
				},
			],
			templateId: null,
			send: {
				failed: true,
				marginal: false,
				acceptable: false,
			},
		},
		filters: {
			plantId: null,
			analysisId: null,
			locationId: null,
			productId: null,
			workOrderTypeId: null,
			groupId: null,
			investigationTriggerId: null,
			resultStatus: 'FAILED',
		},
	})

	//Data
	export let schedules: Schedule[] = []
	export let alertSubscription: AlertSubscription = klona(defaultAlertSubscription)
	export let userAccounts: UserAccount[]
	export let plants: Plant[]
	export let investigationRules: InvestigationRule[]
	export let analyses: Analysis[]
	export let phoneCarriers: PhoneCarrier[]
	export let products: Product[]
	export let groups: Group[]
	export let templates: AlertTemplate[]
	export let workOrderTypes: WorkOrderType[]
	export let permissions: Record<string, boolean>
	export let session: ClientSession

	//Data that can't be passed by the consumer, either static or will be loaded when needed
	let locations: Promise<LocationData[]> | LocationData[]
	let displayPhoneCarriers: PhoneCarrier[] = [...phoneCarriers, { name: 'Custom Domain', domain: 'CUSTOM' }]

	//Interface state
	export let show: boolean = false
	export let title: string = ''
	export let isSaving: boolean = false
	export let messageTab: 'EDIT' | 'PREVIEW' = 'EDIT'
	let isPreviewLoading: boolean = false
	let canSaveAlertSubscription: boolean = false
	let displayResultStatusOptions: DataResultStatus | UserResultStatus | undefined

	type TemplatesGroupsByCategory = { [key: string]: AlertTemplate[] }

	let timingOptions: AlertTimingWithKey[] = []
	let emailIsValid: boolean = false
	let choosableAnalyses: Analysis[] = []
	let templatesGroupedByCategory: TemplatesGroupsByCategory = {}
	let emailAddressString: string = ''

	const reactiveStatements = {
		computeDisplayResultStatusOptions(
			alertType: AlertType | null,
			alertTypeMap: AlertTypeMap,
			alertTypeCategories: Map<AlertTypeCategory, DataResultStatus | UserResultStatus>,
		): DataResultStatus | UserResultStatus | undefined {
			const alertTypeMetadata = alertType ? alertTypeMap.get(alertType) : undefined

			return alertTypeCategories.get(alertTypeMetadata?.category || 'Data')
		},
		computeAlertTimings(type: AlertSubscription['type']): AlertTimingWithKey[] {
			return type ? getAlertTimings(type) : []
		},
		computeUserAccountEmailAddresses(userAccounts: UserAccount[]) {
			return userAccounts.reduce((acc: string[], userAccount) => {
				return userAccount.workEmail ? [...acc, userAccount.workEmail] : acc
			}, [])
		},
		computeUnusedUserAccountEmailAddresses(alertSubscription: AlertSubscription, userAccountEmailAddresses: string[]) {
			const usedEmailAddresses = alertSubscription.message.emailAddress.filter(v => v)

			return userAccountEmailAddresses.filter(emailAddress => !usedEmailAddresses.includes(emailAddress))
		},
		computeChoosableAnalyses(analyses: Analysis[], plantIdFilter: number | null): Analysis[] {
			return plantIdFilter ? analyses.filter(analysis => analysis.inUseAtPlantIDs.includes(plantIdFilter)) : analyses
		},
		computeChoosableProducts(products: Product[], plantIdFilter: number | null) {
			return plantIdFilter
				? products.filter(product => {
						return product?.inUseAtPlants?.map(plant => plant.id).includes(plantIdFilter)
					})
				: products
		},
		computeChoosableTemplates(templates: AlertTemplate[], type?: AlertType | null): AlertTemplate[] {
			return type ? templates.filter(template => template.type === type) : templates
		},
		computeTemplatesGroupedByCategory(choosableTemplates: AlertTemplate[]): TemplatesGroupsByCategory {
			return choosableTemplates.reduce((acc, template) => {
				const alertMethodMetadata = alertMethodMap.get(template.method)
				if (alertMethodMetadata) {
					const { name: method } = alertMethodMetadata
					acc[method] = acc[method] ? [...acc[method], template] : [template]
					return acc
				} else {
					return acc
				}
			}, {})
		},
		computeCanSaveAlertSubscription(
			type: AlertSubscription['type'],
			timing: AlertSubscription['timing'],
			scheduleId: AlertSubscription['scheduleId'],
			hours: AlertSubscription['hours'],
			message: AlertSubscription['message'],
			assignedToUserAccountId: AlertSubscription['assignedToUserAccountId'],
			emailIsValid: boolean,
		): boolean {
			const { method, emailAddress, templateId, body } = message

			const destinationIsValid = ((method === 'EMAIL' || method === 'TEXT') && emailAddress && emailIsValid && emailAddress.length <= 320) || method === 'SHOW'
			const timingIsValid = hasValidTiming(timing, scheduleId, hours)

			return !!(type && timingIsValid && destinationIsValid && (templateId || body) && assignedToUserAccountId)
		},
	}

	function isString(val: unknown): val is string {
		return typeof val === 'string'
	}

	function hoursChanged(event: Event) {
		const hours = Math.abs(parseInt((event.target as HTMLInputElement).value, 10) || 0)
		alertSubscription.hours = hours
	}

	function showPickEmailAddressesModal() {
		emailAddressPicker.showModal(alertSubscription.message.emailAddress)
	}

	async function confirm() {
		isSaving = true
		try {
			if (alertSubscription.id) {
				const alertSubscriptionForApi = buildSavedAlertSubscriptionForApiFromViewState(alertSubscription)
				const { data } = await updateAlertSubscription.mutate({
					input: alertSubscriptionForApi,
				})
				if (data?.savedAlertSubscription) {
					dispatch('alertSubscriptionUpdated', buildAlertSubscriptionForViewStateFromApi(data.savedAlertSubscription))
				}
			} else {
				const alertSubscriptionForApi = buildNewAlertSubscriptionForApiFromViewState(alertSubscription)
				const { data } = await createAlertSubscription.mutate({
					input: alertSubscriptionForApi,
				})
				if (data?.savedAlertSubscription) {
					dispatch('alertSubscriptionCreated', buildAlertSubscriptionForViewStateFromApi(data?.savedAlertSubscription))
				}
			}
			show = false
		} catch (err) {
			console.error(err)
			mediator.call('showMessage', {
				heading: translate('configuration.alertSubscription.errorSavingAlertSubscription', 'Error Saving Alert Subscription'),
				message: err && typeof err === 'object' && 'message' in err && typeof err.message === 'string' ? err.message : translate('common:unknownError', 'Unknown error'),
				type: 'danger',
			})
		} finally {
			isSaving = false
		}
	}

	function phoneCarrierChange(event: Event, index: number) {
		const carrier = (event.target as HTMLSelectElement)?.value

		alertSubscription.message.phone[index].carrierDomain = carrier === 'CUSTOM' ? '' : carrier

		if (carrier === 'CUSTOM') {
			document.getElementById(`#carrierDomainInput-${index}`)?.focus?.()
		}
	}

	function removePhoneNumber(index: number) {
		alertSubscription.message.phone.splice(index, 1)
		alertSubscription.message.phone = alertSubscription.message.phone
	}

	function typeChange(event: Event, alertSubscriptionType: AlertType | null) {
		event.stopPropagation()

		const previousType = alertSubscriptionType
		const alertType = (event.target as HTMLSelectElement)?.value as AlertType
		const alertTypeMetadata = alertTypeMap.get(alertType)

		alertSubscription.type = alertType

		if (alertTypeMetadata) {
			const defaultTiming = alertTypeMetadata?.defaultTiming
			if (defaultTiming) {
				alertSubscription.timing = defaultTiming
			} else if (alertSubscription.timing && !alertTypeMetadata.allowedTimings.includes(alertSubscription.timing)) {
				alertSubscription.timing = null
			}

			const previousTypeIsSummary = !previousType || alertTypeMap.get(previousType)?.isSummary
			const alertTypeIsSummary = alertTypeMap.get(alertType)?.isSummary

			if ((alertTypeIsSummary && !previousTypeIsSummary) || (alertType === 'INVESTIGATION' && previousType !== 'INVESTIGATION')) {
				alertSubscription.filters.resultStatus = null
			}

			if (alertTypeMap.get(alertType)?.category === 'User') {
				alertSubscription.message.send.marginal = false
			}
		}
	}

	function templateChange(templateId: AlertSubscription['message']['templateId']) {
		const template = templates.find(template => template.id === templateId)

		//in order to preview the template, we'll set the title and body these are not saved
		alertSubscription.message.title = template?.title ?? ''
		alertSubscription.message.body = template?.body ?? ''
	}

	function getEmailArrayFromString(emailString: string): string[] {
		return emailString.split(/[;,]/).map(email => email.trim())
	}

	function emailAddressStringChange(event: Event) {
		const { value } = event.target as HTMLInputElement
		emailAddressString = value
	}

	async function loadLocations(plantId: number | null): Promise<LocationData[]> {
		let locations: LocationData[] = []

		if (plantId) {
			try {
				const { data } = await locationsQuery.fetch({
					variables: {
						filter: {
							activeOnly: true,
							plantIds: [plantId],
						},
						pagination: {
							pageSize: 0,
							pageNumber: 1,
						},
					},
					policy: 'CacheOrNetwork',
				})
				locations = data?.locations?.data ?? []
			} catch (err) {
				mediator.call('showMessage', {
					heading: translate('configuration.alertSubscription.errorLoadingLocationData', 'Error Loading Location Data'),
					message: err && typeof err === 'object' && 'message' in err && typeof err.message === 'string' ? err.message : translate('common:unknownError', 'Unknown error'),
					type: 'danger',
				})
			}
		}
		return locations
	}

	$: displayResultStatusOptions = reactiveStatements.computeDisplayResultStatusOptions(alertSubscription.type, alertTypeMap, alertTypeCategories)
	$: userAccountEmailAddresses = reactiveStatements.computeUserAccountEmailAddresses(userAccounts)
	$: unusedUserAccountEmailAddresses = reactiveStatements.computeUnusedUserAccountEmailAddresses(alertSubscription, userAccountEmailAddresses)
	$: timingOptions = reactiveStatements.computeAlertTimings(alertSubscription.type)
	$: canSpecifySchedule = computeCanSpecifySchedule(alertSubscription.timing, alertSubscription.type)
	$: canSpecifyExpirationOffset = computeCanSpecifyExpirationOffset(alertSubscription.timing)
	$: emailIsValid = computeEmailIsValid(alertSubscription.message.emailAddress)
	$: choosableAnalyses = reactiveStatements.computeChoosableAnalyses(analyses, alertSubscription.filters.plantId)
	$: choosableProducts = reactiveStatements.computeChoosableProducts(products, alertSubscription.filters.plantId)
	$: choosableTemplates = reactiveStatements.computeChoosableTemplates(templates, alertSubscription.type)
	$: templatesGroupedByCategory = reactiveStatements.computeTemplatesGroupedByCategory(choosableTemplates)
	$: alertSubscription.message.emailAddress = emailAddressString.split(/[;,]/).map(email => email.trim())
	$: canSaveAlertSubscription = reactiveStatements.computeCanSaveAlertSubscription(
		alertSubscription.type,
		alertSubscription.timing,
		alertSubscription.scheduleId,
		alertSubscription.hours,
		alertSubscription.message,
		alertSubscription.assignedToUserAccountId,
		emailIsValid,
	)
</script>

<Modal
	bind:show
	{title}
	modalSize="xl"
	cancelButtonText={translate('common:cancel', 'Cancel')}
	cancelButtonSize="sm"
	confirmButtonIcon="check"
	confirmButtonDisabled={!canSaveAlertSubscription}
	confirmButtonText={translate('common:save', 'Save')}
	confirmButtonOutline
	confirmButtonColor="primary"
	confirmButtonSize="sm"
	savingInProgress={isSaving}
	cancelButtonColor="secondary"
	cancelButtonOutline
	on:close={() => (show = false)}
	on:confirm={() => confirm()}
>
	<div class="form-row">
		<div class="col-12 col-xl-5">
			<CollapsibleCard
				cardClass="mt-3"
				headerText={translate('configuration.alertSubscriptions.event', 'Event')}
				cardHeaderClass="card-header d-flex justify-content-between h5"
				entireHeaderToggles
			>
				<div class="form-row align-items-end">
					<div class="col-12 col-sm-6">
						<Label
							label={translate('configuration.alertSubscriptions.type', 'Type')}
							required
						>
							<!-- This is not using our Select component because the typeChange function needs the previous value, which isn't supported via our custom Select -->
							<select
								class="custom-select custom-select-sm"
								id="alertTypeSelect"
								on:change={event => typeChange(event, alertSubscription.type)}
								value={alertSubscription.type}
								disabled={isSaving}
							>
								<option value={null}>-- {translate('common:select', 'Select')} {translate('configuration.alertSubscriptions.type', 'Type')} --</option>
								{#each Object.entries(alertTypesGroupedByCategory) as [category, alertTypes]}
									<optgroup label={category}>
										{#each Object.entries(alertTypes) as [index, { key, name }]}
											<option value={key}>{name}</option>
										{/each}
									</optgroup>
								{/each}
							</select>
						</Label>
					</div>
					<div class="col-12 col-sm-6">
						<Select
							label={translate('configuration.alertSubscriptions.timing', 'Timing')}
							bind:value={alertSubscription.timing}
							disabled={!alertSubscription.type || timingOptions.length <= 1 || isSaving}
							required
						>
							{#each timingOptions as { key, name }}
								<option value={key}>{name}</option>
							{/each}
						</Select>
					</div>
					<div class="col-12 col-sm-6">
						{#if canSpecifySchedule}
							<Select
								label={translate('configuration.alertSubscriptions.schedule', 'Schedule')}
								bind:value={alertSubscription.scheduleId}
								required
								disabled={isSaving}
							>
								{#each schedules as { id, name }}
									<option value={id}>{name}</option>
								{/each}
							</Select>
						{:else if canSpecifyExpirationOffset}
							<div class="form-group mb-1">
								<label
									class="col-form-label"
									for="hours"
									aria-label={translate('configuration.alertSubscriptions.beforeAfterHours', 'Before/After Hours')}
								></label>
								<div class="input-group input-group-sm">
									<input
										value={alertSubscription.hours}
										on:change={hoursChanged}
										id="hours"
										type="number"
										min="0"
										inputmode="decimal"
										aria-label="Hours"
										class="form-control"
										style="border-right: none;"
										disabled={isSaving}
									/>
									<div class="input-group-prepend">
										<div class="input-group-text">
											{translate('configuration.alertSubscriptions.hour', 'Hours')}
										</div>
									</div>
									<select
										class="form-control custom-select"
										bind:value={alertSubscription.tense}
										disabled={isSaving}
									>
										<option
											value="BEFORE"
											use:translated={'configuration.alertSubscriptions.before'}>Before</option
										>
										<option
											value="AFTER"
											use:translated={'configuration.alertSubscriptions.after'}>After</option
										>
									</select>
								</div>
							</div>
						{/if}
					</div>
				</div>
			</CollapsibleCard>

			<CollapsibleCard
				cardClass="mt-3"
				headerText={translate('configuration.alertSubscriptions.filters', 'Filters')}
				cardHeaderClass="card-header d-flex justify-content-between h5"
				entireHeaderToggles
			>
				<div class="form-row align-items-end">
					<div class="col-12 col-sm-6">
						<Select
							label={translate('common:plant', 'Plant')}
							bind:value={alertSubscription.filters.plantId}
							emptyText="{translate('common:any', 'Any')} {translate('common:plant', 'Plant')}"
							on:change={() => {
								locations = loadLocations(alertSubscription.filters.plantId)
							}}
							disabled={isSaving}
							options={plants}
							let:option={plant}
						>
							{#if plant}
								<option value={plant.id}>{plant.name}</option>
							{/if}
						</Select>
					</div>
					{#if !alertSubscription.type || alertTypeMap.get(alertSubscription.type)?.category !== 'User'}
						<div class="col-12 col-sm-6">
							<Select
								label={translate('common:analysis', 'Analysis')}
								bind:value={alertSubscription.filters.analysisId}
								emptyText="{translate('common:any', 'Any')} {translate('common:analysis', 'Analysis')}"
								disabled={isSaving}
							>
								{#each choosableAnalyses as analysis}
									<option value={analysis.id}>{analysis.name}</option>
								{/each}
							</Select>
						</div>
						<div class="col-12 col-sm-6">
							<Select
								label={translate('common:location', 'Location')}
								bind:value={alertSubscription.filters.locationId}
								emptyText="{translate('common:any', 'Any')} {translate('common:location', 'Location')}"
								disabled={!alertSubscription.filters.plantId || isSaving}
								options={locations}
								let:option={location}
							>
								{#if location}
									<option value={location.id}>{location.location}</option>
								{/if}
							</Select>
						</div>
						<div class="col-12 col-sm-6">
							<Select
								label={translate('common:product', 'Product')}
								bind:value={alertSubscription.filters.productId}
								emptyText="{translate('common:any', 'Any')} {translate('common:product', 'Product')}"
								disabled={isSaving}
							>
								{#each choosableProducts as product}
									<option value={product.id}>{product.name}</option>
								{/each}
							</Select>
						</div>
						<div class="col-12 col-sm-6">
							<Select
								label={translate('common:woType', 'WO Type')}
								bind:value={alertSubscription.filters.workOrderTypeId}
								emptyText="{translate('common:any', 'Any')} {translate('common:woType', 'WO Type')}"
								disabled={isSaving}
								options={workOrderTypes}
								let:option={workOrderType}
							>
								{#if workOrderType}
									<option value={workOrderType.id}>{workOrderType.name}</option>
								{/if}
							</Select>
						</div>
					{/if}
					<div class="col-12 col-sm-6">
						<Select
							label={alertSubscription.type && alertTypeMap.get(alertSubscription.type)?.category === 'Data'
								? `${translate('common:assignedToGroup', 'Assigned To Group')}`
								: translate('common:group', 'Group')}
							bind:value={alertSubscription.filters.groupId}
							emptyText="{translate('common:any', 'Any')} {translate('common:group', 'Group')}"
							disabled={isSaving}
							options={groups}
							let:option={group}
						>
							{#if group}
								<option value={group.id}>{group.name}</option>
							{/if}
						</Select>
					</div>
					{#if alertSubscription.type === 'INVESTIGATION'}
						<div class="col-12">
							<Select
								label={translate('configuration.alertSubscriptions.investigationRule', 'Investigation Rule')}
								bind:value={alertSubscription.filters.investigationTriggerId}
								emptyText={translate('configuration.alertSubscriptions.anyInvestigationRule', 'Any Investigation Rule')}
								disabled={isSaving}
								options={investigationRules}
								let:option={investigationRule}
							>
								{#if investigationRule}
									<option value={investigationRule.id}>{investigationRule.description}</option>
								{/if}
							</Select>
						</div>
					{/if}
					<div class="col-12 col-sm-6">
						<Select
							label={translate('configuration.alertSubscriptions.resultStatus', 'Result Status')}
							bind:value={alertSubscription.filters.resultStatus}
							emptyText="{translate('common:any', 'Any')} {translate('configuration.alertSubscriptions.resultStatus', 'Result Status')}"
							disabled={isSaving || (displayResultStatusOptions ? Object.keys(displayResultStatusOptions).length === 1 : true)}
						>
							{#each displayResultStatusOptions ? Object.entries(displayResultStatusOptions) : [] as [key, { name }]}
								<option value={key}>{name}</option>
							{/each}
						</Select>
					</div>
				</div>
			</CollapsibleCard>
		</div>
		<div class="col-12 col-xl-7">
			<CollapsibleCard
				cardClass="mt-3"
				headerText={translate('common:message', 'Message')}
				cardHeaderClass="card-header d-flex justify-content-between h5"
				entireHeaderToggles
				bodyClass="p-0"
			>
				<div class="card border-0">
					<div class="card-header">
						<NavTabBar
							bind:selectedTab={messageTab}
							tabs={[
								{
									name: 'EDIT',
									title: translate('common:edit', 'Edit'),
									iconClass: 'pencil',
								},
								{
									name: 'PREVIEW',
									title: translate('common:preview', 'Preview'),
									disabled: !canSaveAlertSubscription,
									iconClass: 'magnifying-glass',
								},
							]}
						/>
					</div>
					<div class="card-body">
						{#if messageTab === 'EDIT'}
							<div class="form-row">
								<div class="col-12 col-sm-4 col-xl-3">
									<Select
										label={translate('common:sendVia', 'Send Via')}
										bind:value={alertSubscription.message.method}
										showEmptyOption={false}
										disabled={isSaving}
										on:change={() => {
											if (alertSubscription.message.method === 'TEXT' && !alertSubscription.message.phone.length) {
												alertSubscription.message.phone = [{ number: '', carrier: '', carrierDomain: '' }]
											}
										}}
									>
										{#each [...alertMethodMap] as [key, { name }]}
											<option value={key}>{name}</option>
										{/each}
									</Select>
								</div>
								<div
									class:d-none={alertSubscription.message.method !== 'SHOW'}
									class="col-0 col-sm-8 col-xl-9"
								></div>
								{#if alertSubscription.message.method === 'EMAIL'}
									<div class="col-12 col-sm-8 col-xl-9 align-items-end">
										<div class="d-flex align-items-end">
											<Input
												labelParentClass="form-group w-100"
												label={translate('common:emailAddress', 'Email Address')}
												class="emailAddressInput"
												placeholder="eg. janedoe@example.com"
												value={emailAddressString}
												on:change={emailAddressStringChange}
												list="unusedUserAccountEmailAddresses"
												autocomplete="email"
												disabled={isSaving}
												validation={{
													validator: value => {
														const isValid = computeEmailIsValid(getEmailArrayFromString(value))
														const lengthIsValid = value.length < 320

														let translatedValidation = ''
														if (!isValid) {
															translatedValidation = translate('common:invalidEmailAddress', 'Invalid Email Address(s)')
														} else if (!lengthIsValid) {
															translatedValidation = translate('common:emailAddressTooLong', 'Max length is 320')
														}
														const translatedValidationString = isString(translatedValidation) ? translatedValidation : ''
														return isValid ? true : translatedValidationString
													},
												}}
											/>
											<Button
												class="mb-1 ml-1"
												size="sm"
												outline
												color="success"
												iconClass="plus"
												on:click={showPickEmailAddressesModal}
												disabled={isSaving}
												title={translate('configuration.alertSubscriptions.addAdditionalEmailAddresses', 'Add additional email addresses')}
											/>
											<EmailAddressPicker
												bind:this={emailAddressPicker}
												on:confirm={event => {
													emailAddressString = event.detail.join(';')
												}}
												{userAccounts}
												{plants}
												{groups}
											/>
										</div>
										<small use:translated={'configuration.alertSubscriptions.separateMultipleEmailsWithASemicolon'}>Separate multiple emails with a semicolon(;) or comma(,)</small>
									</div>
									<datalist id="unusedUserAccountEmailAddresses">
										{#each unusedUserAccountEmailAddresses as email}
											<option value={email} />
										{/each}
									</datalist>
								{:else if alertSubscription.message.method === 'TEXT'}
									<div class="col-12 col-sm-8 col-xl-9 align-content-end mb-1">
										<Button
											outline
											size="sm"
											color="success"
											iconClass="plus"
											disabled={isSaving}
											on:click={() => {
												alertSubscription.message.phone.push({ number: '', carrier: '', carrierDomain: '' })
												alertSubscription.message.phone = alertSubscription.message.phone
												document.getElementById(`phoneNumberInput-${alertSubscription.message.phone.length - 1}`)?.focus()
											}}
										></Button>
									</div>
									{#each alertSubscription.message.phone as phone, i}
										<div class="col-12 col-md-4 col-xl-4">
											<Input
												id="phoneNumberInput-{i}"
												label={translate('common:phoneNumber', 'Phone Number')}
												bind:value={phone.number}
												required={i === alertSubscription.message.phone.length - 1}
												placeholder="eg. 402-435-3850"
												inputmode="tel"
												type="tel"
												autocomplete="tel"
												disabled={isSaving}
											/>
										</div>
										<div class="col-12 col-md-8 col-xl-8">
											<div class="form-group mb-1 d-inline-block">
												<label
													class="col-form-label"
													for="phoneCarrierSelect-{i}">{translate('common:phoneCarrier', 'Phone Carrier')}</label
												>
												<div class="input-group input-group-sm">
													<select
														id="phoneCarrierSelect-{i}"
														bind:value={phone.carrier}
														on:change={event => phoneCarrierChange(event, i)}
														class="custom-select custom-select-sm"
														disabled={isSaving}
													>
														<option value="">-- {translate('common:select', 'Select')} {translate('common:carrier', 'Carrier')} --</option>
														{#each displayPhoneCarriers as { domain, name }}
															<option value={domain}>{name}</option>
														{/each}
													</select>
													<input
														id="carrierDomainInput-{i}"
														class="form-control form-control-sm"
														bind:value={phone.carrierDomain}
														disabled={isSaving || phone.carrier !== 'CUSTOM'}
														placeholder="mycarrier.com"
													/>
													{#if alertSubscription.message.phone.length > 1}
														<div class="input-group-append">
															<Button
																class="mb-1 btn-outline-danger btn-sm"
																outline
																iconClass="trash"
																disabled={isSaving}
																on:click={() => removePhoneNumber(i)}
															/>
														</div>
													{/if}
												</div>
											</div>
										</div>
									{/each}
								{/if}
							</div>
							<hr />
							<div class="form-row">
								<div class="col-12 col-lg-6">
									<Select
										label={translate('common:template', 'Template')}
										bind:value={alertSubscription.message.templateId}
										on:change={() => templateChange(alertSubscription.message.templateId)}
										disabled={isSaving || !Object.keys(templatesGroupedByCategory).length}
										hint="({translate('configuration.alertSubscriptions.requiredWithoutBody', 'Required w/o Body')})"
										required={(!alertSubscription.message.templateId && !alertSubscription.message.body) || !!alertSubscription.message.templateId}
									>
										{#each Object.keys(templatesGroupedByCategory) as key}
											<optgroup label={key}>
												{#each templatesGroupedByCategory[key] as { id, name }}
													<option value={id}>{name}</option>
												{/each}
											</optgroup>
										{/each}
									</Select>
								</div>
								<div class="col-12 col-lg-6">
									<Input
										label={translate('common:subject', 'Subject')}
										bind:value={alertSubscription.message.title}
										readonly={alertSubscription.message.templateId ? true : undefined}
										disabled={isSaving}
									/>
								</div>
								<div class="col-12">
									<Textarea
										label={translate('common:body', 'Body')}
										style="height: 79px;"
										class="form-control"
										readonly={alertSubscription.message.templateId ? true : undefined}
										required
										disabled={isSaving}
										bind:value={alertSubscription.message.body}
									/>
								</div>
								<div class="col-12">
									<div class="card mt-2">
										<h6 class="card-header">
											{#if alertSubscription.type && alertTypeMap.get(alertSubscription.type)?.category === 'User'}
												{translate('configuration.alertSubscriptions.includedAccessStatusLabel', 'Included Access Status')}
											{:else}
												{translate('configuration.alertSubscriptions.includedResultStatusLabel', 'Included Results')}
											{/if}
										</h6>
										<div class="card-body p-0">
											<div class="d-flex flex-wrap">
												<div class="m-2">
													<Checkbox
														label={alertSubscription.type && alertTypeMap.get(alertSubscription.type)?.category === 'User'
															? translate('common:denied', 'Denied')
															: translate('common:failed', 'Failed')}
														bind:checked={alertSubscription.message.send.failed}
														disabled={isSaving}
													/>
												</div>
												{#if !alertSubscription.type || alertTypeMap.get(alertSubscription.type)?.category !== 'User'}
													<div class="m-2">
														<Checkbox
															label={translate('common:marginal', 'Marginal')}
															bind:checked={alertSubscription.message.send.marginal}
															disabled={isSaving}
														/>
													</div>
												{/if}
												<div class="m-2">
													<Checkbox
														label={alertSubscription.type && alertTypeMap.get(alertSubscription.type)?.category === 'User'
															? translate('common:granted', 'Granted')
															: translate('common:acceptable', 'Acceptable')}
														bind:checked={alertSubscription.message.send.acceptable}
														disabled={isSaving}
													/>
												</div>
												<!-- end the "send" section -->
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- end message section -->
						{:else if messageTab === 'PREVIEW'}
							{#if alertSubscription.type && alertTypeMap.get(alertSubscription.type)?.isSummary}
								<SummaryPreviewConfiguration
									{alertSubscription}
									{alertTypeMap}
									{schedules}
									isLoading={isPreviewLoading}
									on:preview={event => {
										alertPreviewComponent.showPreview(event.detail)
									}}
								/>
							{:else}
								<StandardPreviewConfiguration
									{alertSubscription}
									{alertTypeMap}
									isLoading={isPreviewLoading}
									on:preview={event => {
										alertPreviewComponent.showPreview(event.detail)
									}}
								/>
							{/if}
							<AlertPreview
								bind:this={alertPreviewComponent}
								{alertSubscription}
								{alertTypeMap}
								bind:isLoading={isPreviewLoading}
							/>
						{/if}
					</div>
				</div>
			</CollapsibleCard>
		</div>
	</div>
	<div slot="modalFooter">
		<div class="form-row">
			<div class="col-12 col-lg-4">
				<Select
					label={translate('configuration.alertSubscriptions.assignedToUser', 'Assigned To User')}
					bind:value={alertSubscription.assignedToUserAccountId}
					required
					disabled={isSaving || !permissions.canManageAlertSubscriptions}
				>
					{#each userAccounts as user}
						<option value={user.id}>{user.name}</option>
					{/each}
				</Select>
			</div>
			<div class="col-12 col-lg-8">
				<Textarea
					label="Description"
					bind:value={alertSubscription.description}
					placeholder={translate('configuration.alertSubscriptions.alertDescriptionPlaceholder', 'A short description of the alert subscription')}
					rows="1"
					disabled={isSaving}
				/>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<small class="font-weight-bold">* {translate('common:boldFieldsAreRequired', 'Bold fields are required')}</small>
			</div>
		</div>
	</div>
</Modal>
