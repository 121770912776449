import type { AppContext, ResolveParameters } from 'types/common'
import component from './History.svelte'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import { ReportQueueJobsStore, graphql, type ReportQueueStatus$options, ReportQueueOrderBy, type ReportQueueOrderBy$options } from '$houdini'
import { stringToBoolean } from '@isoftdata/utility-string'

const reportQueueJobsQuery: ReportQueueJobsStore = graphql`
	query ReportQueueJobs($pagination: PaginatedInput, $filter: ReportQueueFilter, $orderBy: [ReportQueueOrderBy!]!) {
		reportQueueJobs(pagination: $pagination, filter: $filter, orderBy: $orderBy) {
			data {
				id
				reportId
				type
				name
				quantity
				createdBy
				dateCreated
				destinationType
				destination
				status
				executionTime
				notes
				error
				parameters {
					key: parameterName
					value
				}
			}
			info {
				totalItems
				pageNumber
			}
		}
	}
`

type StateParameters = {
	showSentJobs: boolean
	showCancelledJobs: boolean
	pageNumber: number
	sortDirection: 'ASC' | 'DESC'
	sortColumnProperty: string
}

export default function ({ mediator, stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer.history',
		route: 'history',
		defaultParameters: {
			pageNumber: '1',
			// TODO, what are the desktop defaults? I don't think it's stored in a setting there at least.
			showSentJobs: 'true',
			showCancelledJobs: 'true',
			sortDirection: 'DESC',
			sortColumnProperty: 'dateCreated',
		},
		querystringParameters: ['showSentJobs', 'showCancelledJobs', 'pageNumber', 'sortDirection', 'sortColumnProperty', 'lastRefreshTime'],
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, parameters: ResolveParameters<StateParameters>) {
			const showSentJobs = stringToBoolean(parameters.showSentJobs)
			const showCancelledJobs = stringToBoolean(parameters.showCancelledJobs)
			const pageNumber = parseInt(parameters.pageNumber, 10) || 1
			const pageSize = 100
			const sortDirection = parameters.sortDirection ?? 'ASC'
			const sortColumnProperty = parameters.sortColumnProperty ?? 'dateCreated'

			const statusFilter: Array<ReportQueueStatus$options> = ['READY', 'FAILED']

			if (showSentJobs) {
				statusFilter.push('SENT')
			}

			if (showCancelledJobs) {
				statusFilter.push('CANCELLED')
			}

			let orderBy: Array<ReportQueueOrderBy$options> = []

			const orderByString = `${sortColumnProperty}_${sortDirection}`

			if (orderByString in ReportQueueOrderBy) {
				orderBy = [orderByString as ReportQueueOrderBy$options]
			}

			const { data } = await reportQueueJobsQuery.fetch({
				variables: {
					pagination: {
						pageNumber,
						pageSize,
					},
					filter: {
						status: statusFilter,
					},
					orderBy,
				},
			})
			if (!data) {
				throw showErrorAndRedirect(mediator, 'Failed to load Print Job History', 'No data returned from server', { name: 'app.report-viewer' })
			}

			return {
				history: data.reportQueueJobs.data,
				currentPageNumber: pageNumber,
				sortDirection,
				sortColumnProperty,
				perPageCount: pageSize,
				totalItemsCount: data.reportQueueJobs.info.totalItems,
				showSentJobs,
				showCancelledJobs,
			}
		},
	})
}
