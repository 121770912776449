export default {
    "name": "WoProductData",
    "kind": "HoudiniFragment",
    "hash": "be5dff227990a03c27f2deb6b789cca22ea8d2f7d23ed11390017a31b43db424",

    "raw": `fragment WoProductData on Product {
  id
  name
  productType
  attachmentCount(fileType: IMAGE)
  __typename
}
`,

    "rootType": "Product",

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "name": {
                "type": "String",
                "keyRaw": "name",
                "visible": true
            },

            "productType": {
                "type": "ProductType",
                "keyRaw": "productType",
                "visible": true
            },

            "attachmentCount": {
                "type": "UnsignedInt",
                "keyRaw": "attachmentCount(fileType: IMAGE)",
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=bc388a1a7c9890cbba49b68e2667966ce87f46b1b2166a4e0fe956a74891c98b";