export default {
    "name": "UpdateLocationDelimiter",
    "kind": "HoudiniMutation",
    "hash": "cb164df8a16d29e560618148700111a4df47501920e86195e2caa36df1ba200f",

    "raw": `mutation UpdateLocationDelimiter($delimiter: String!) {
  updateLocationDelimiter(delimiter: $delimiter)
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "updateLocationDelimiter": {
                "type": "Void",
                "keyRaw": "updateLocationDelimiter(delimiter: $delimiter)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "delimiter": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=7d3fb64d14dbc1b559ce31d2517fb29811d500e3ebbf4ca610d8d9cfc6563330";