export default {
    "name": "AppData",
    "kind": "HoudiniQuery",
    "hash": "2fcd58d61025779b5891c3da7ca80eb949fd298eddcea8d37e2cd2b3d9acd8aa",

    "raw": `query AppData {
  session {
    user {
      sidebarCollapsed: getUserSetting(
        lookup: {category: "Global", name: "Collapse Presage Web sidebar", settingType: INTERFACE_HISTORY, defaultValue: "True"}
      ) {
        value
      }
      id
    }
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "session": {
                "type": "Session",
                "keyRaw": "session",
                "nullable": true,

                "selection": {
                    "fields": {
                        "user": {
                            "type": "UserAccount",
                            "keyRaw": "user",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "sidebarCollapsed": {
                                        "type": "SettingResult",
                                        "keyRaw": "sidebarCollapsed(lookup: {category: \"Global\", name: \"Collapse Presage Web sidebar\", settingType: INTERFACE_HISTORY, defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=506447c9c15507644273aafb3fb6a120f9e634f4a4ea67d0d0c8ced4743ba2eb";