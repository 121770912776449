export default {
    "name": "SetGlobalSetting",
    "kind": "HoudiniMutation",
    "hash": "8e791aa9b6ed173839d49feb7cb606e6bf4facfb175f791785cf15751ff1f5e7",

    "raw": `mutation SetGlobalSetting($value: SettingChange!) {
  setGlobalSetting(value: $value)
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "setGlobalSetting": {
                "type": "Void",
                "keyRaw": "setGlobalSetting(value: $value)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "value": "SettingChange"
        },

        "types": {
            "SettingChange": {
                "category": "String",
                "name": "String",
                "newValue": "String",
                "settingType": "SettingType"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=8cb0fc68d0eef737a56aee0a6b30cd69a75b298e36ac7b89db1d706d2d17963a";