import svelteComponent from './verify.svelte'
import { getSession } from 'stores/session'

export default ({ mediator, stateRouter }) => {
	stateRouter.addState({
		name: 'app.verify',
		route: 'verify',
		querystringParameters: [ 'workOrderId' ],
		defaultParameters: {
			workOrderId: null,
		},
		template: {
			svelte: true, // this tells our renderer wrapper that this is a svelte component
			component: svelteComponent, // this is the svelte component
			options: {
				// these are the options passed to the svelte component
			},
		},
		async resolve(data, parameters) {
			const authToken = getSession().authToken
			const { workOrderId } = parameters
			let iframeURL = null
			if (workOrderId) {
				iframeURL = `/verify/workorderdetail.php?session_token=${authToken}&embedded=true&woid=${workOrderId}`
			} else {
				iframeURL = `/verify/index.php?session_token=${authToken}&embedded=true`
			}

			return {
				iframeURL,
			}
		},
		activate(_activateContext) {

		},
	})
}
