import pProps from 'p-props'
import { canEditPlantSpecificFields, canEditGlobalFieldsMap, canEditGlobalFields } from 'utility/analysis-permission-helper'
import apiFetch from 'utility/api-fetch'
import { klona } from 'klona'
import { v4 as uuid } from '@lukeed/uuid'
import { TableKeyboardNavigator } from 'utility/table-keyboard-navigator'

//ractive components
import template from './options.html' // ,
import makeTableComponent from '@isoftdata/table' // ({ stickyHeader: true }), // TODO: will we need server side paging and custom sorting?
import makeButtonComponent from '@isoftdata/button' // (),
import makeModalComponent from '@isoftdata/modal' // (),
import makeSelectComponent from '@isoftdata/select' // ({ twoway: false }),
import makeInputComponent from '@isoftdata/input' // ({ twoway: false }),
import makeCheckboxComponent from '@isoftdata/checkbox' // (),
import makeMightyMorphingInput from '../../../../components/mighty-morphing-input' // ({ twoway: false }),

const gqlPagninationAllPages = { pagination: { pageSize: 0, pageNumber: 1 } }

// #region Constants
const defaultOptionData = Object.freeze({
	active: true,
	analysisId: null,
	choices: [],
	defaultType: 'FIXED',
	defaultValue: null,
	id: null,
	productId: null,
	rank: 0,
	thresholdType: 'FIXED',
	informational: false,
	requiredToClose: false,
	requiredToPerform: false,
	unit: '',
	valueType: 'NUMBER',
})

const defaultChoiceData = Object.freeze({
	active: true,
	analysisOptionId: null,
	boundaryType: 'ALLOWED',
	choice: '',
	constraint: 'NONE',
	global: false,
	id: null,
	plantId: null,
	product: null,
	severityClass: null,
})

const defaultRuleData = Object.freeze({
	active: true,
	analysisOptionId: null,
	created: null,
	description: '',
	id: null,
	outcome: 'HIDDEN',
	restriction: 'PRESENT',
	tags: [],
})

const defaultDefaultOptionValueModalData = Object.freeze({
	show: false,
	defaultValue: '',
	testResult: '',
	options: [],
	loading: false,
	errorMessage: '',
	errorDetail: '',
})

const defaultTestThresholdsModalData = Object.freeze({
	show: false,
	loading: true,
	severityClassId: null,
	plantId: null,
	productId: null,
	testValue: '',
	testResult: '',
	thresholds: [],
})

const defaultTagSelectionModalData = Object.freeze({
	show: false,
	loading: true,
	selectedPlantTagIndex: null,
	selectedProductTagIndex: null,
	selectedLocationTagIndex: null,
	ruleName: '',
	plantTags: [],
	productTags: [],
	locationTags: [],
})

// Maybe want to break these out into categories or something, or add more to the list later
const sqlKeywords = Object.freeze([
	'SELECT',
	'FROM',
	'WHERE',
	'AND',
	'OR',
	'NOT',
	'IN',
	'LIKE',
	'IS',
	'NULL',
	'NOT NULL',
	'LEFT JOIN',
	'JOIN',
	'GROUP BY',
	'ORDER BY',
	'ASC',
	'DESC',
])

// #endregion

export default function({ mediator, stateRouter, hasPermission, i18next: { t: translate } }) {
	stateRouter.addState({
		name: 'app.analysis-management.analyses.options',
		route: ':selectedAnalysisId/:selectedAnalysisUuid',
		defaultParameters: {
			selectedAnalysisId: null,
			selectedAnalysisUuid: null,
		},
		template: {
			template,
			translate,
			components: {
				itTable: makeTableComponent({ stickyHeader: true }), // TODO: will we need server side paging and custom sorting?
				itButton: makeButtonComponent(),
				itModal: makeModalComponent(),
				itSelect: makeSelectComponent({ twoway: false }),
				itInput: makeInputComponent({ twoway: false }),
				itCheckbox: makeCheckboxComponent(),
				mightyMorphingInput: makeMightyMorphingInput({ twoway: false }),
			},
			data: {},
			decorators: {
				// I wanted to add true/false in the middle of the id that I passed to the component so I can select them with my keyboard navigator
				checkboxButtonIdHack: (buttonGroup, baseId) => {
					const radioInputs = buttonGroup.querySelectorAll('input')
					for (const radioInput of radioInputs) {
						const lastDashIndex = baseId.lastIndexOf('-')
						radioInput.id = `${baseId.substring(0, lastDashIndex)}-${radioInput.value.toLowerCase()}${baseId.substring(lastDashIndex)}`
					}
					return {
						teardown() {
							return false
						},
					}
				},
			},
			computed: {
				canEditPlantSpecificFields,
				canEditGlobalFieldsMap,
				optionsToSave() {
					return this.get('analysisOptions')
						.filter(option => option.dirty || (option.deleted && option.id))
						.map(option => ({
							...option,
							choices: option.choices.filter(choice => choice.dirty || choice.id),
						}))
				},
				productsAtThisPlant() {
					const plantId = this.get('plantId')
					return this.get('products').filter(product => product.inUseAtPlants.some(plant => plant.id === plantId))
				},
				selectedOption: {
					get() {
						return this.get(`analysisOptions[${this.get('selectedOptionIndex')}]`)
					},
					set(option) {
						const index = this.get('selectedOptionIndex')
						this.set(`analysisOptions[${index}]`, option)
					},
				},
				thresholdValueType() {
					const selectedOption = this.get('selectedOption')
					// Since we're entering choices not choosing one, we want to have an input not a select
					if (!selectedOption || selectedOption.valueType === 'CHOICE') {
						return 'TEXT'
					}
					return selectedOption.valueType
				},
				testOptions() {
					const ractive = this
					const options = ractive.get('defaultOptionValueModal.options')
					const defaultValue = ractive.get('defaultOptionValueModal.defaultValue')
					return options
						?.map((option, mainArrayIndex)=> ({ ...option, mainArrayIndex }))
						.filter(({ option }) => defaultValue.includes(`{?${option}}`)) ?? []
				},
				testQuery() {
					const ractive = this
					let defaultValue = ractive.get('defaultOptionValueModal.defaultValue')

					const options = ractive.get('defaultOptionValueModal.options')
					if (!options?.length) {
						return ''
					}
					for (const option of options) {
						// These are both strings, so it shouldn't use defaultValue if testValue is '0' or 'false'
						const replacementValue = option.testValue || option.defaultValue
						defaultValue = defaultValue.replace(new RegExp(`\\{\\?${option.option}\\}`, 'g'), replacementValue)
					}
					return `SELECT ${defaultValue} AS test_result_1`
				},
				severityClassesMap() {
					return this.get('severityClasses').reduce((acc, severityClass) => {
						acc.set(severityClass.id, severityClass)
						return acc
					}, new Map())
				},
				productsMap() {
					return this.get('products').reduce((acc, product) => {
						acc.set(product.id, product)
						return acc
					}, new Map())
				},
				plantsMap() {
					return this.get('plants').reduce((acc, plant) => {
						acc.set(plant.id, plant)
						return acc
					}, new Map())
				},
				applicableThresholds() {
					const ractive = this
					const valueType = ractive.get('selectedOption.valueType')
					const thresholdsMap = ractive.get('thresholdsWhenMap')
					// Don't show 'Invalid when' when it's not relevant
					if (valueType === 'CHOICE' || valueType === 'BOOLEAN' || valueType === 'TEXT') {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const { BOUNDARY, ...applicableThresholds } = thresholdsMap
						return applicableThresholds
					}
					return thresholdsMap
				},
			},
			tagSelectionModalNameCounts(entityType) {
				const ractive = this
				const tagsInModal = ractive.get(`tagSelectionModal.${entityType.toLowerCase()}Tags`)
				// Do not allow them to create a tag with a name equal to another tag's originalName or current name
				const duplicateCounts = tagsInModal.reduce((acc, { name, originalName }) => {
					acc[name] = (acc[name] || 0) + 1
					// If the name and originalName are different, we need to check both, because we tag maps key off of the originalName
					// If a new tag is created with the same name as another tag's originalName, it will overwrite the other tag , even if its visible name is different
					// I don't think this will happen often at all, but because it's possible, I want to prevent it.
					if (originalName && originalName !== name) {
						acc[originalName] = (acc[originalName] || 0) + 1
					}
					return acc
				}, {})
				return duplicateCounts
			},
			tagSelectionModalHasDuplicateTagNames(entityType) {
				const ractive = this

				if (!ractive.get('tagSelectionModal.show')) {
					return false
				}

				return Object.values(ractive.tagSelectionModalNameCounts(entityType)).some(count => count > 1)
			},
			tagSelectionModalTagIsDuplicate(entityType, { name, originalName }) {
				const ractive = this
				const nameCounts = ractive.tagSelectionModalNameCounts(entityType)
				return nameCounts[name] > 1 || (originalName && originalName !== name && nameCounts[originalName] > 1)
			},
			canEditChoice(plantId) {
				const ractive = this
				if (!ractive) {
					throw new Error('Ractive is not defined')
				}
				if (plantId === null) { // Choice/Threshold is "Global" if not at a plant
					return ractive.canEditGlobalFields(ractive.get('selectedAnalysisId'))
				}
				// Choice/Threshold is this plant only
				if (Number.isInteger(plantId)) {
					return hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', plantId)
				}
				// Shouldn't happen, but just in case
				return false
			},
			canEditGlobalFields,
			updateOptionKeypath(index, keypath, value) {
				const ractive = this
				const optionCount = ractive.get('analysisOptions').length

				ractive.set({
					[`analysisOptions.${index}.${keypath}`]: value,
					[`analysisOptions.${index}.dirty`]: true,
				})

				if (index === optionCount - 1) {
					ractive.set('lazySortOptions', false)
					ractive.push('analysisOptions', {
						analysisId: ractive.get('selectedAnalysisId'),
						rank: optionCount,
						...klona(defaultOptionData),
						uuid: uuid(),
						rules: [
							{
								...klona(defaultRuleData),
								analysisOptionId: ractive.get('selectedOption.id'),
								uuid: uuid(),
							},
						],
						choices: [
							{
								...klona(defaultChoiceData),
								analysisOptionId: ractive.get('selectedOption.id'),
								plantId: ractive.get('plantId'),
								uuid: uuid(),
							},
						],
					})
					ractive.set('lazySortOptions', true)
				}
			},
			updateRuleKeypath(index, keypath, value) {
				const ractive = this
				const selectedOptionIndex = ractive.get('selectedOptionIndex')
				const rulesCount = ractive.get(`analysisOptions.${selectedOptionIndex}.rules`).length
				ractive.set({
					[`analysisOptions.${selectedOptionIndex}.rules.${index}.${keypath}`]: value,
					[`analysisOptions.${selectedOptionIndex}.rules.${index}.dirty`]: true,
					[`analysisOptions.${selectedOptionIndex}.dirty`]: true,
				})

				if (index === rulesCount - 1) {
					ractive.set('lazySortRules', false)
					ractive.push(`analysisOptions.${selectedOptionIndex}.rules`, {
						...klona(defaultRuleData),
						analysisOptionId: ractive.get('selectedOption.id'),
						uuid: uuid(),
					})
					ractive.set('lazySortRules', true)
				}
			},
			updateChoiceKeypath(index, keypath, value) {
				const ractive = this
				const selectedOptionIndex = ractive.get('selectedOptionIndex')
				ractive.set({
					[`analysisOptions.${selectedOptionIndex}.choices.${index}.${keypath}`]: value,
					[`analysisOptions.${selectedOptionIndex}.choices.${index}.dirty`]: true,
					[`analysisOptions.${selectedOptionIndex}.dirty`]: true,
				})

				if (index === ractive.get(`analysisOptions.${selectedOptionIndex}.choices`).length - 1) {
					ractive.set('lazySortChoices', false)
					ractive.push(`analysisOptions.${selectedOptionIndex}.choices`, {
						...klona(defaultChoiceData),
						analysisOptionId: ractive.get('selectedOption.id'),
						plantId: ractive.get('plantId'),
						uuid: uuid(),
					})
					ractive.set('lazySortChoices', true)
				}
			},
			getDisplayTagsList(ruleIndex) {
				const ruleTags = this.get(`selectedOption.rules.${ruleIndex}.tags`)
				const plantTagsMap = this.get('plantTagsMap')
				const locationTagsMap = this.get('locationTagsMap')
				const productTagsMap = this.get('productTagsMap')

				const displayTags = ruleTags.reduce((nameArr, { entityType, originalName }) => {
					let tag
					switch (entityType) {
						case 'PLANT':
							tag = plantTagsMap.get(originalName)
							break
						case 'LOCATION':
							tag = locationTagsMap.get(originalName)
							break
						case 'PRODUCT':
							tag = productTagsMap.get(originalName)
							break
					}
					// Don't include deleted tags in the display list, even though they're still on the rule (they will be removed on save)
					if (tag?.name && !tag?.deleted) {
						nameArr.push(tag.name)
					}
					return nameArr
				}, []).join(' & ')

				return displayTags
			},
		},
		async resolve(data, parameters) {
			const selectedAnalysisId = (!parameters.selectedAnalysisId || parameters.selectedAnalysisId === 'null') ? null : parseInt(parameters.selectedAnalysisId, 10)
			const plantId = parseInt(parameters.plantId, 10)

			const {
				products,
				analysisOptions,
				severityClasses,
			} = await pProps({
				products: apiFetch(mediator, {
					query: queries.products,
					variables: { filter: { activeOnly: false, plantIds: [ plantId ] }, ...gqlPagninationAllPages },
				}, 'products.data'),
				analysisOptions: selectedAnalysisId ? await apiFetch(mediator, {
					query: queries.analysisOptions,
					variables: { filter: { analysisIds: [ selectedAnalysisId ] }, ...gqlPagninationAllPages },
				}, 'analysisOptions.data') : [],
				severityClasses: apiFetch(mediator, {
					query: queries.severityClasses,
					variables: { filter: { plantIds: [ plantId ] }, ...gqlPagninationAllPages },
				}, 'severityClasses.data'),
			})

			const selectedOptionIndex = 0 // This will always be 0 because we don't persist the selected option, and we'll always have at least one (empty) option
			const selectedOptionId = analysisOptions[0]?.id ?? null

			const mappedOptions = analysisOptions.map(option => {
				return {
					...option,
					choices: [
						{
							...klona(defaultChoiceData),
							analysisOptionId: option.id,
							plantId,
							uuid: uuid(),
						},
					],
					rules: (option.rules?.map(rule => {
						return {
							...rule,
							tags: rule.tags.map(tag => ({ ...tag, originalName: tag.name })), // used as key for maps, as name can be changed on this screen
							created: (new Date(rule.created)).toLocaleDateString(),
						}
					}) ?? []).concat([{
						...klona(defaultRuleData),
						analysisOptionId: option.id,
						uuid: uuid(),
					}]),
					uuid: uuid(),
				}
			}).concat([{
				analysisId: selectedAnalysisId,
				rank: analysisOptions.length,
				...klona(defaultOptionData),
				uuid: uuid(),
				rules: [
					{
						...klona(defaultRuleData),
						uuid: uuid(),
					},
				],
				choices: [
					{
						...klona(defaultChoiceData),
						plantId,
						uuid: uuid(),
					},
				],
			}])

			const analysisOptionChoices = selectedOptionId ? await apiFetch(mediator, {
				query: queries.analysisOptionChoices,
				variables: { filter: { optionId: selectedOptionId, plantId } },
			}, 'analysisOptionChoices.data') : []

			if ((selectedOptionIndex || selectedOptionIndex === 0) && selectedOptionId) {
				// At this point, the only choice in the array is the "empty" one, so preserve it
				mappedOptions[selectedOptionIndex].choices = analysisOptionChoices
					.map(choice => ({ ...choice, global: choice.plantId === null }))
					.concat(mappedOptions[selectedOptionIndex].choices)
			}

			// #region Maps
			const thresholdsWhenMap = Object.freeze({
				ALLOWED: translate('analysisManagement.allowedWhen', 'Allowed when'),
				BOUNDARY: translate('analysisManagement.invalidWhen', 'Invalid when'),
				MARGINAL: translate('analysisManagement.marginalWhen', 'Marginal when'),
				UNACCEPTABLE: translate('analysisManagement.unacceptableWhen', 'Unacceptable when'),
			})

			const thresholdsMap = Object.freeze({
				ALLOWED: 'Allowed',
				BOUNDARY: 'Invalid',
				INVALID: 'Invalid',
				MARGINAL: 'Marginal',
				NOT_CALCULATED: 'Not Calculated',
				UNACCEPTABLE: 'Unacceptable',
			})

			const constraintsMap = Object.freeze({
				MINIMUM: translate('common:above', 'Above'),
				MAXIMUM: translate('common:below', 'Below'),
				NOT_EQUAL: translate('common:doesNotEqual', 'Doesn\'t equal'),
				NONE: translate('common:equals', 'Equals'),
			})

			const applicabilityMap = Object.freeze({
				APPLICABLE: 'Applicable',
				DEACTIVATED: 'Deactivated',
				WRONG_BATCH: 'Wrong Batch',
				WRONG_PLANT: 'Wrong Plant',
				WRONG_PRODUCT: 'Wrong Product',
				WRONG_SEVERITY_CLASS: 'Wrong Severity Class',
			})

			const outcomeMap = Object.freeze({
				INACTIVE: 'Inactive',
				HIDDEN: 'Hidden',
				READONLY: 'Readonly',
				REQUIRED_TO_PERFORM: 'Required to Perform',
				REQUIRED_TO_CLOSE: 'Required to Close',
			})

			// #endregion

			return {
				selectedChoiceIndex: 0, // only used for kb navigator
				selectedRuleIndex: 0, // only used for kb navigator
				constraintsMap,
				severityClasses,
				applicabilityMap,
				outcomeMap,
				thresholdsMap,
				thresholdsWhenMap,
				products: products.sort((a, b) => a.name.localeCompare(b.name)),
				selectedAnalysisId,
				selectedAnalysisUuid: parameters.selectedAnalysisUuid,
				selectedOptionIndex,
				selectedOptionUuid: mappedOptions[selectedOptionIndex]?.uuid ?? null,
				analysisOptions: mappedOptions,
				analysisOptionsTableColumns: [
					{ property: 'dirty', icon: 'fas fa-fw fa-save', columnWidth: '1rem', title: translate('analysisManagment.dirtyColumnTooltip', 'Rows with a save icon have unsaved changes, and will be saved when you hit the "Save" button') },
					{ property: 'option', name: 'Option', columnMinWidth: '200px', title: 'The option the user will be presented with' },
					{ property: 'active', name: 'Active', columnWidth: '1rem', title: 'Whether this option is active (active options will be shown on new samples)' },
					{ property: 'defaultValue', name: 'Default Value', columnMinWidth: '150px', title: 'The default value is filled in on samples as soon as they are marked as sampled.  This field supports calculation syntax (see the wiki for more information).' },
					{ property: 'unit', name: 'Unit', columnMinWidth: '80px', title: 'The unit of measurement (where applicable)' },
					{ property: 'valueType', name: 'Value Type', columnWidth: '120px', title: 'Choose the type of data values are collected as (such as integer or string)' },
					{ property: 'requiredToPerform', name: 'Required to Perform', columnWidth: '1rem', title: 'Required options must be filled out before saving a sample as performed. (Samples become performed once any options have values)' },
					{ property: 'requiredToClose', name: 'Required to Close', columnWidth: '1rem', title: 'Required options must be filled out before closing a work order' },
					{ property: 'informational', name: 'Informational', columnWidth: '1rem', title: 'Informational items will be filled out on WOs but won\'t be graphed or sent to visualizations' },
					{ property: 'deleted', icon: 'fas fa-trash', class: 'text-center', sortType: false, columnWidth: '1rem', title: 'Mark this for deletion. It will be deleted on save.' },
				],
				analysisOptionChoiceTableColumns: [
					{ property: 'dirty', icon: 'fas fa-fw fa-save', columnWidth: '1rem', title: 'Rows with a save icon have unsaved changes, and will be saved when you hit the "Save" button' },
					{ property: 'active', name: 'Active', columnWidth: '1rem', title: 'Whether this choice/threshold is active (active choices will be available on new samples and active thresholds affect subsequent acceptability tests)' },
					{ property: 'global', name: 'Global', columnWidth: '1rem', title: 'Controls whether this choice/threshold applies to just the current plant or all plants' },
					{ property: 'boundaryType', name: 'Threshold', columnMinWidth: '170px', title: `What type of choice is this?\r\n\t'Marginal' values are the threshold for triggering a warning;\r\n\t'Unacceptable' values are the threshold for triggering an error;\r\n\t'Acceptable' values are used to specify predefined options for choice-based fields, informational thresholds on the graphs, or minimum/maximum values on numeric fields` },
					// TODO: In the desktop, "All Severities" and "All Products" are retrieved from a function and aren't hardcoded into the tooltip like they are here
					{ property: 'severity', name: 'Severity', columnMinWidth: '150px', title: '(Optional) Which severity class this threshold/choice applies to (Or choose \'All Severities\' for everything)' },
					{ property: 'product[name]', name: 'Product', columnMinWidth: '150px', title: '(Optional) Which product this threshold/choice applies to.\nChoosing a \'parent\' product will apply to all sub-products as well.  (Or choose \'All Products\' for everything)' },
					{ property: 'constraint', name: 'Constraint Type', title: 'Is this the maximum or minimum boundary/marginal/or unacceptable value?' },
					{ property: 'choice', name: 'Value', columnMinWidth: '150px', title: 'For numeric fields, enter a value that represents a boundary for a warning level/etc.  For text/choice based options, enter possible choices.' },
					{ property: 'deleted', icon: 'fas fa-trash', class: 'text-center', sortType: false, columnWidth: '1rem', title: 'Mark this for deletion. It will be deleted on save.' },
				],
				rulesColumns: [
					{ property: 'dirty', icon: 'fas fa-fw fa-save' },
					{ property: 'active', name: 'Active', columnWidth: '1rem', title: 'Whether this rule is active (active rules will affect documents the next time they are edited)' },
					{ property: 'tags', name: 'Tags', title: 'A group of plant, product, and/or location tags associated with this rule.\r\nIf more than one tag is chosen, all of them must be absent/present to trigger the rule.' },
					{ property: 'restriction', name: 'Tag Trigger', title: 'Is this rule triggered when the relevant tags are all present, or all absent?' },
					{ property: 'outcome', name: 'Outcome', columnMinWidth: '180px', title: 'If this rule is triggered, this is the effect that will be applied to the option' },
					{ property: 'description', name: 'Description', columnMinWidth: '180px', title: 'A description of the rule' },
					{ property: 'created', name: 'Created', title: 'When the rule was created' },
					{ property: 'deleted', icon: 'fas fa-fw fa-trash' },
				],
				testThresholdsColumns: [
					{ property: 'threshold', name: 'Threshold', title: 'How this threshold applies to a value' },
					{ property: 'constraint', name: 'Constraint', title: 'Is this the maximum or minimum boundary/marginal/or unacceptable value?' },
					{ property: 'value', name: 'Value', columnClass: 'text-right', title: 'The value of the threshold' },
					{ property: 'severity', name: 'Severity', title: 'Which severity class this threshold/choice applies to' },
					{ property: 'plant', name: 'Plant', title: 'The plant a threshold applies to' },
					{ property: 'product', name: 'Product', title: 'Which product this threshold/choice applies to' },
					{ property: 'violated', name: 'Violated?', title: 'Whether this threshold was triggered by the entered value' },
					{ property: 'applicability', name: 'Applicable?', title: 'When a threshold does not apply, this field will say why' },
				],
				sqlKeywords,
				// Since I'm doing lazy sorting, I need to clear the sort direction/column when I change the data in the table and on state reload
				optionsSortColumn: '',
				optionsSortDirection: '',
				choicesSortColumn: '',
				choicesSortDirection: '',
				rulesSortColumn: '',
				rulesSortDirection: '',
				lazySortOptions: true,
				lazySortChoices: true,
				lazySortRules: true,
			}
		},
		activate(context) {
			const { domApi: ractive } = context

			ractive.observe('selectedOption', (newVal, oldVal, keypath) => console.log(keypath, newVal))
			// #region Keyboard Navigators
			const optionsNavigator = new TableKeyboardNavigator({
				ractive,
				tableId: 'options-table',
				rowsKeypath: 'analysisOptions',
				selectedIndexKeypath: 'selectedOptionIndex',
				columnIds: [ 'option-option', 'option-active', 'option-default', 'option-unit', 'option-value-type', 'option-required-to-perform', 'option-required-to-close', 'option-informational', 'option-delete' ],
				changeRow: newIndex => ractive.fire('option-clicked', null, newIndex),
				otherTable: (tableWithEventId, event) => {
					console.log('otherTable', tableWithEventId, event)
				},
			})

			const choicesNavigator = new TableKeyboardNavigator({
				ractive,
				tableId: 'choices-thresholds-table',
				rowsKeypath: 'selectedOption.choices',
				selectedIndexKeypath: 'selectedChoiceIndex',
				columnIds: [ 'choice-active', 'choice-global', 'choice-boundary', 'choice-severity', 'choice-product', 'choice-constraint', 'choice-choice', 'choice-delete' ],
				changeRow: newIndex => ractive.set('selectedAnalysisIndex', newIndex),
			})

			const rulesNavigator = new TableKeyboardNavigator({
				ractive,
				tableId: 'rules-table',
				rowsKeypath: 'selectedOption.rules',
				selectedIndexKeypath: 'selectedRuleIndex',
				columnIds: [ 'rule-active', 'rule-tags', 'rule-restriction-true', 'rule-restriction-false', 'rule-outcome', 'rule-description', 'rule-delete' ],
				changeRow: newIndex => ractive.set('selectedRuleIndex', newIndex),
			})
			// #endregion
			// merge in cached options from analyses state
			const cachedDirtyOptions = ractive.get('analyses')
				.find(analysis => analysis.id === ractive.get('selectedAnalysisId') && analysis.uuid === ractive.get('selectedAnalysisUuid'))
				?.options?.filter(option => option.dirty) ?? []
			if (cachedDirtyOptions.length) {
				const analysisOptions = ractive.get('analysisOptions')
				const mergedOptions = analysisOptions.map(option => {
					const cachedOption = cachedDirtyOptions.find(cachedOption => cachedOption.id && cachedOption.id === option.id)
					return cachedOption ? cachedOption : option
				}) // Replace existing options with cached options if it exists
					.concat(cachedDirtyOptions.filter(cachedOption => !cachedOption.id)) // Add unsaved options
					.sort((a, b) => (!b.id && !b.dirty) ? -1 : 1)// Keep "New Analysis Option" at the end\

				if (!mergedOptions[0].choices.some(choice => !choice.dirty && !choice.id)) { // make sure we have the empty choice
					mergedOptions[0].choices.push({
						...klona(defaultChoiceData),
						analysisOptionId: mergedOptions[0].id,
						plantId: ractive.get('plantId'),
						uuid: uuid(),
					})
				}

				if (!mergedOptions[0].rules.some(rule => !rule.dirty && !rule.id)) { // make sure we have the empty rule
					mergedOptions[0].rules.push({
						...klona(defaultRuleData),
						uuid: uuid(),
					})
				}
				ractive.set('analysisOptions', mergedOptions)
				if (!ractive.get('selectedOptionIndex') && ractive.get('selectedOptionIndex') !== 0) {
					ractive.set({
						selectedOptionIndex: 0,
						selectedOptionUuid: ractive.get('analysisOptions')[0].uuid,
					})
				}
			}

			console.log('options activate', ractive.get())

			// #region Events
			ractive.on('option-clicked', async(context, index) => {
				const lastSelectedOptionIndex = ractive.get('selectedOptionIndex')
				if (lastSelectedOptionIndex === index || ractive.get(`analysisOptions.${index}.deleted`)) {
					return
				}
				// Show choices as loading
				ractive.set('choicesLoading', true)
				// Cache only the "dirty" choices from this option
				let valuesToSetAtEnd = {}
				if (lastSelectedOptionIndex !== null) {
					const lastOption = ractive.get(`analysisOptions.${lastSelectedOptionIndex}`)
					lastOption.choices = lastOption.choices?.filter(choice => choice.dirty) ?? []
					if (lastOption.choices.length || lastOption.dirty) {
						// The rows shifted before choices were loaded when set them here, so set at the end
						valuesToSetAtEnd = { [`analysisOptions.${lastSelectedOptionIndex}`]: lastOption }
					}
				}
				// Query choices for the new option
				const selectedOptionId = ractive.get(`analysisOptions.${index}.id`)
				const newChoices = selectedOptionId ? await apiFetch(mediator, {
					query: queries.analysisOptionChoices,
					variables: { filter: { optionId: selectedOptionId, plantId: ractive.get('plantId') } },
				}, 'analysisOptionChoices.data') : []

				// Merge the cached choices with the new choices, and add on any new unsaved choices
				const cachedChoices = ractive.get(`analysisOptions.${index}.choices`) ?? []
				const cachedChoicesMap = new Map(cachedChoices.filter(({ id }) => id).map(choice => [ choice.id, choice ]))
				const mergedChoices = newChoices
					.map(choice => ({ ...(cachedChoicesMap.get(choice.id) ?? choice), global: choice.plantId === null }))
					.concat(cachedChoices.filter(choice => !choice?.id))

				// Make sure we always have a "blank" choice for them to edit
				if (mergedChoices.findIndex(choice => !choice.id && !choice.deleted && !choice.dirty) === -1) {
					mergedChoices.push({
						...klona(defaultChoiceData),
						analysisOptionId: selectedOptionId,
						plantId: ractive.get('plantId'),
						uuid: uuid(),
					})
				}

				// Show choices in the UI
				ractive.set({
					selectedOptionIndex: index,
					selectedOptionId,
					[`analysisOptions.${index}.choices`]: mergedChoices,
					choicesLoading: false,
					selectedChoiceIndex: 0,
					selectedRuleIndex: 0,
					choicesSortColumn: '',
					choicesSortDirection: '',
					rulesSortColumn: '',
					rulesSortDirection: '',
					...valuesToSetAtEnd,
				})
			})

			ractive.on('add-option', () => {
				const analysisOptions = ractive.get('analysisOptions')
				let newOptionIndex = analysisOptions.findIndex(option => !option.id && !option.deleted && !option.dirty)

				if (newOptionIndex === -1) {
					newOptionIndex = analysisOptions.length
					ractive.set('lazySortOptions', false)
					ractive.push('analysisOptions', {
						analysisId: ractive.get('selectedAnalysisId'),
						rank: ractive.get('analysisOptions.length'),
						...klona(defaultOptionData),
						// dirty: true,
						uuid: uuid(),
						rules: [
							{
								...klona(defaultRuleData),
								analysisOptionId: ractive.get('selectedOption.id'),
								uuid: uuid(),
							},
						],
						choices: [
							{
								...klona(defaultChoiceData),
								analysisOptionId: ractive.get('selectedOption.id'),
								plantId: ractive.get('plantId'),
								uuid: uuid(),
							},
						],
					})
					ractive.set('lazySortOptions', true)
				}
				ractive.set('selectedOptionIndex', newOptionIndex)
				ractive.find(`#option-option-${newOptionIndex}`).focus()
			})

			ractive.on('add-choice', () => {
				const choices = ractive.get('selectedOption.choices')
				let newChoiceIndex = choices.findIndex(choice => !choice.id && !choice.deleted && !choice.dirty)
				if (newChoiceIndex === -1) {
					newChoiceIndex = choices.length
					ractive.set('lazySortChoices', false)
					ractive.push('selectedOption.choices', {
						...klona(defaultChoiceData),
						analysisOptionId: ractive.get('selectedOption.id'),
						plantId: ractive.get('plantId'),
						uuid: uuid(),
					})
					ractive.set('lazySortChoices', true)
				}
				ractive.set('selectedChoiceIndex', newChoiceIndex)
				ractive.find(`#choice-active-${newChoiceIndex}`).focus({ focusVisible: true })
			})

			ractive.on('delete-option', (context, index, id) => {
				if (!confirm('Delete this analysis option? All sample values and thresholds that reference this analysis option will be deleted as well. \r\n\r\nAfter saving, this cannot be undone.')) {
					return
				}
				ractive.set(`analysisOptions.${index}.deleted`, true)
				// Select the first non-deleted option
				const newSelectedOptionIndex = ractive.get('analysisOptions').findIndex(option => !option.deleted)
				ractive.set('selectedOptionIndex', newSelectedOptionIndex)
			})

			ractive.on('undo-delete-option', (context, index) => {
				ractive.set(`analysisOptions.${index}.deleted`, false)
			})

			// #region Default Option Value Modal
			ractive.on('edit-test-default-clicked', () => {
				const { defaultType, defaultValue, valueType, uuid: selectedOptionUuid } = ractive.get('selectedOption')
				ractive.set({
					defaultOptionValueModal: {
						...klona(defaultDefaultOptionValueModalData),
						show: true,
						defaultValue,
						defaultType,
						valueType,
						options: ractive.get('analysisOptions')
							.filter(({ uuid }) => uuid !== selectedOptionUuid)
							.map(({ id, option, valueType, defaultValue }) => ({ id, option, valueType, defaultValue, testValue: '' })),
					},
				})
			})

			ractive.on('insert-keyword-into-default-value', (context, keyword) => {
				const defaultValue = ractive.get('defaultOptionValueModal.defaultValue') || ''
				ractive.set('defaultOptionValueModal.defaultValue', `${defaultValue}${defaultValue ? ' ' : ''}${keyword}`)
			})

			ractive.on('test-option-default-value', async(context, defaultValue, defaultType) => {
				if (!defaultValue || defaultType === 'FIXED') {
					return
				}

				function transformResult(result) {
					try {
						result = JSON.parse(result)[0].test_result_1
					} catch (err) {
						console.error(err)
					}
					if (ractive.get('selectedOption.valueType') === 'BOOLEAN') {
						return result === '1' ? 'True' : 'False'
					}
					return result
				}

				ractive.set({
					'defaultOptionValueModal.testResult': 'Loading...',
					'defaultOptionValueModal.loading': true,
					'defaultOptionValueModal.errorMessage': '',
					'defaultOptionValueModal.errorDetail': '',
				})
				try {
					let result = await apiFetch(mediator, {
						query: `query Query($query: String!) {testSelectQuery(query: $query)}`,
						variables: { query: `${ractive.get('testQuery')}` },
					}, 'testSelectQuery')

					ractive.set({
						'defaultOptionValueModal.testResult': transformResult(result),
						'defaultOptionValueModal.loading': false,
						'defaultOptionValueModal.errorMessage': '',
						'defaultOptionValueModal.errorDetail': '',
					})
				} catch (err) {
					console.error(err)
					ractive.set({
						'defaultOptionValueModal.testResult': '',
						'defaultOptionValueModal.errorMessage': err.message,
						'defaultOptionValueModal.errorDetail': `${err.extensions.originalError.sqlMessage }`,
						'defaultOptionValueModal.loading': false,
					})
				}
			})

			ractive.on('set-option-default-value', (context, defaultValue, defaultType) => {
				// This won't save it, just update the backing object
				ractive.set({
					defaultOptionValueModal: klona(defaultDefaultOptionValueModalData),
					['selectedOption.defaultValue']: defaultValue,
					['selectedOption.defaultType']: defaultType,
					['selectedOption.dirty']: true,
				})
			})

			// #endregion

			// #region Test Threshold Modal
			ractive.on('test-thresholds-clicked', () => {
				ractive.set({
					testThresholdsModal: {
						...klona(defaultTestThresholdsModalData),
						show: true,
						plantId: ractive.get('plantId'),
					},
				})
				ractive.fire('check-applicable-thresholds')
			})

			ractive.on('test-thresholds-update-value', (context, keypath) => {
				// the only falsy values passed here will be empty string, but those should be null
				ractive.set(`testThresholdsModal.${keypath}`, context.node.value || null)
				ractive.fire('check-applicable-thresholds')
				ractive.fire('get-value-acceptability')
			})

			ractive.on('test-thresholds-recalculate', () => {
				ractive.set('testThresholdsModal.loading', true)
				ractive.fire('check-applicable-thresholds')
				ractive.fire('get-value-acceptability')
			})

			ractive.on('check-applicable-thresholds', async() => {
				const res = await apiFetch(mediator, {
					query: queries.checkApplicableThresholds,
					variables: {
						currentResult: ractive.get('testThresholdsModal.testValue'),
						analysisOptionId: ractive.get('selectedOption.id'),
						plantId: ractive.get('testThresholdsModal.plantId'),
						productId: ractive.get('testThresholdsModal.productId'),
						severityClassId: ractive.get('testThresholdsModal.severityClassId'),
						productBatchId: null, // recipes only
					},
				}, 'checkApplicableThresholds')
				ractive.set({
					'testThresholdsModal.loading': false,
					'testThresholdsModal.thresholds': res.map(({ applicability, boundaryType, constraint, ...threshold }) => {
						const plant = ractive.get('plantsMap').get(threshold.plantId)
						return {
							...threshold,
							applicability,
							displayApplicability: ractive.get('applicabilityMap')[applicability],
							threshold: boundaryType,
							displayThreshold: ractive.get('thresholdsMap')[boundaryType],
							constraint: ractive.get('constraintsMap')[constraint],
							severity: ractive.get('severityClassesMap').get(threshold.severityClassId)?.name ?? 'All',
							plant: plant ? `${plant.code } - ${ plant.name}` : 'All',
							product: ractive.get('productsMap').get(threshold.productId)?.name ?? 'All',
							violated: threshold.violated,
							displayViolated: threshold.violated.slice(0, 1) + threshold.violated.slice(1).toLowerCase(),
						}
					}),
				})
			})

			ractive.on('get-value-acceptability', async() => {
				//
				const testResult = await apiFetch(mediator, {
					query: queries.getValueAcceptability,
					variables: {
						currentResult: ractive.get('testThresholdsModal.testValue'),
						analysisOptionId: ractive.get('selectedOption.id'),
						plantId: ractive.get('testThresholdsModal.plantId'),
						productId: ractive.get('testThresholdsModal.productId'),
						severityClassId: ractive.get('testThresholdsModal.severityClassId'),
					},
				}, 'getValueAcceptability')
				ractive.set({
					'testThresholdsModal.testResult': testResult,
					'testThresholdsModal.displayTestResult': ractive.get('thresholdsMap')[testResult],
				})
			})
			// #endregion

			// #region tagSelection Modal

			ractive.on('tag-selection-clicked', (context, rule) => {
				ractive.set({
					tagSelectionModal: {
						...klona(defaultTagSelectionModalData),
						show: true,
						ruleName: rule.description,
						ruleIndex: rule.originalIndex,
					},
				})

				function mapToArrayForModal(map) {
					return Array.from(map.values()).map(tag => ({ ...tag, inUse: rule.tags.some(t => t.name === tag.name || t.id === tag.id) }))
				}

				const plantTags = mapToArrayForModal(ractive.get('plantTagsMap'))
				const locationTags = mapToArrayForModal(ractive.get('locationTagsMap'))
				const productTags = mapToArrayForModal(ractive.get('productTagsMap'))

				ractive.set({
					'tagSelectionModal.plantTags': plantTags,
					'tagSelectionModal.locationTags': locationTags,
					'tagSelectionModal.productTags': productTags,
					'tagSelectionModal.loading': false,
				})
			})

			ractive.on('tag-selection-confirm', () => {
				const { plantTags, locationTags, productTags, ruleIndex } = ractive.get('tagSelectionModal')
				const tagsToUpdate = [ ...plantTags, ...locationTags, ...productTags ].filter(tag => tag.add || tag.remove)
				console.log('tagsToUpdate', tagsToUpdate)
				// tag type + name is unique. Can't use id bc they can create new tags.
				const ruleTagsMap = new Map(ractive.get(`selectedOption.rules.${ruleIndex}.tags`).map(tag => [ `${tag.entityType}-${tag.name}`, tag ]))
				for (const tag of tagsToUpdate) {
					// name might change if they rename it, reinsert with the old one as key.
					ruleTagsMap.set(`${tag.entityType}-${tag.originalName || tag.name}`, { ...tag, originalName: tag.originalName || tag.name })
				}
				const newRuleTagArray = Array.from(ruleTagsMap.values())
				ractive.set({
					[`selectedOption.rules.${ruleIndex}.tags`]: newRuleTagArray,
					[`selectedOption.rules.${ruleIndex}.dirty`]: ractive.get(`selectedOption.rules.${ruleIndex}.dirty`) || !!tagsToUpdate.length,
					'selectedOption.dirty': ractive.get('selectedOption.dirty') || !!tagsToUpdate.length,
					'tagSelectionModal.show': false,
					// Update this and the parent states' global tag lists
					'plantTagsMap': mediator.call('analysis-management-update-tag-list', 'PLANT', plantTags),
					'locationTagsMap': mediator.call('analysis-management-update-tag-list', 'LOCATION', locationTags),
					'productTagsMap': mediator.call('analysis-management-update-tag-list', 'PRODUCT', productTags),
				})
			})

			ractive.on('tag-clicked', (context, entityType, index) => {
				ractive.set({
					[`tagSelectionModal.selected${entityType.slice(0, 1)}${entityType.slice(1).toLowerCase()}TagIndex`]: index,
				})
			})

			ractive.on('use-tag-clicked', (context, entityType, index, isNew) => {
				ractive.set({
					[`tagSelectionModal.${entityType.toLowerCase()}Tags.${index}.inUse`]: context.node.checked,
					[`tagSelectionModal.${entityType.toLowerCase()}Tags.${index}.add`]: context.node.checked,
					[`tagSelectionModal.${entityType.toLowerCase()}Tags.${index}.remove`]: !isNew && !context.node.checked,
				})
			})

			ractive.on('tag-name-changed', (context, entityType, index, isNew) => {
				const tagKeypath = `tagSelectionModal.${entityType.toLowerCase()}Tags.${index}`
				ractive.set({
					[`${tagKeypath}.name`]: context.node.value,
					[`${tagKeypath}.dirty`]: !isNew,
				})
			})

			ractive.on('delete-tag', (context, entityType, index, isNew) => {
				// I could make this a modal so they can decide between Cancel, Delete, and Remove, but I don't think it's necessary for now and that's a lot of modals.
				if (confirm('Are you sure you want to permanently delete this tag? If you want to remove it from this rule, use the checkbox instead. \r\n\r\n Note: After saving, this tag will be removed from all items at all plants.')) {
					if (isNew && (index || index === 0)) {
						ractive.splice(`tagSelectionModal.${entityType.toLowerCase()}Tags`, index, 1)
					} else if (index || index === 0) {
						ractive.set({
							[`tagSelectionModal.${entityType.toLowerCase()}Tags.${index}.inUse`]: false,
							[`tagSelectionModal.${entityType.toLowerCase()}Tags.${index}.add`]: false,
							[`tagSelectionModal.${entityType.toLowerCase()}Tags.${index}.remove`]: false,
							[`tagSelectionModal.${entityType.toLowerCase()}Tags.${index}.deleted`]: true,
						})
					}
					ractive.set({
						[`tagSelectionModal.selected${entityType.slice(0, 1)}${entityType.slice(1).toLowerCase()}TagIndex`]: null,
					})
				}
			})

			ractive.on('add-tag', async(context, entityType) => {
				await ractive.push(`tagSelectionModal.${entityType.toLowerCase()}Tags`, {
					// EntityTag fields
					active: true,
					entityType,
					id: null,
					name: '',
					originalName: '',
					// client meta fields
					inUse: true,
					add: true,
					remove: false,
					deleted: false,
					dirty: false,
				})
				ractive.find(`#${entityType}-tag-name-${ractive.get(`tagSelectionModal.${entityType.toLowerCase()}Tags`).length - 1}`)?.focus()
			})

			// #endregion

			ractive.on('delete-choice', (context, index, id) => {
				if (confirm('Delete this value? \r\n\r\nAfter saving, this cannot be undone.')) {
					// for some reason setting this on selectedOption wasn't working after a state reload
					ractive.set({
						[`analysisOptions.${ractive.get('selectedOptionIndex')}.choices.${index}.deleted`]: true,
						[`analysisOptions.${ractive.get('selectedOptionIndex')}.dirty`]: true,
					})
				}
			})

			ractive.on('undo-delete-choice', (context, index) => {
				ractive.set(`analysisOptions.${ractive.get('selectedOptionIndex')}.choices.${index}.deleted`, false)
			})

			ractive.on('global-checked', (context, index) => {
				const checked = context.node.checked
				let plantId = ractive.get('plantId')
				if (!checked) {
					plantId = null
				}
				ractive.set({
					[`selectedOption.choices.${index}.plantId`]: plantId,
					[`selectedOption.choices.${index}.global`]: checked,
					[`selectedOption.choices.${index}.severityClass`]: null, // in theory this should already be null, but just in case
					[`selectedOption.choices.${index}.dirty`]: true,
					[`selectedOption.dirty`]: true,
				})

				if (index === ractive.get('selectedOption.choices').length - 1) {
					ractive.set('lazySortChoices', true)
					ractive.push('selectedOption.choices', {
						...klona(defaultChoiceData),
						analysisOptionId: ractive.get('selectedOption.id'),
						plantId: ractive.get('plantId'),
						uuid: uuid(),
					})
					ractive.set('lazySortChoices', false)
				}
			})

			ractive.on('product-changed', (context, index) => {
				const productId = parseInt(context.node.value, 10) || null
				ractive.set({
					[`selectedOption.choices.${index}.product`]: { id: productId, name: context.node.selectedOptions[0].innerText },
					[`selectedOption.choices.${index}.dirty`]: true,
					[`selectedOption.dirty`]: true,
				})
			})

			ractive.on('severity-changed', (context, index) => {
				const severityId = parseInt(context.node.value, 10) || null
				ractive.set({
					[`selectedOption.choices.${index}.severityClass`]: severityId ? { id: severityId, name: context.node.selectedOptions[0].innerText } : null,
					[`selectedOption.choices.${index}.plantId`]: ractive.get('plantId'), // can't be global since severity is plant based
					[`selectedOption.choices.${index}.global`]: false,
					[`selectedOption.choices.${index}.dirty`]: true,
					[`selectedOption.dirty`]: true,
				})
			})

			// #region Rules
			ractive.on('add-rule', () => {
				const rules = ractive.get('selectedOption.rules')
				let newRuleIndex = rules.findIndex(rule => !rule.id && !rule.deleted && !rule.dirty)
				if (newRuleIndex === -1) {
					newRuleIndex = rules.length
					ractive.set('lazySortRules', true)
					ractive.push('selectedOption.rules', {
						...klona(defaultRuleData),
						analysisOptionId: ractive.get('selectedOption.id'),
						// dirty: true,
						uuid: uuid(),
					})
					ractive.set('lazySortRules', false)
				}
				ractive.set('selectedRuleIndex', newRuleIndex)
				ractive.find(`#rule-active-${newRuleIndex}`)?.focus({ focusVisible: true })
			})

			ractive.on('delete-rule', (context, index, id) => {
				ractive.set({
					[`selectedOption.rules.${index}.deleted`]: true,
					[`selectedOption.dirty`]: true,
				})
			})

			ractive.on('undo-delete-rule', (context, index) => {
				ractive.set(`selectedOption.rules.${index}.deleted`, false)
			})

			// #endregion Rules

			// #endregion Events

			const removeGetOptionsChoicesProvider = mediator.provide('analysis-management-get-modified-options-choices', () => {
				return ractive.get('optionsToSave')
			})

			context.on('destroy', () => {
				console.log('destroy options')
				removeGetOptionsChoicesProvider()
				optionsNavigator.teardown()
				choicesNavigator.teardown()
				rulesNavigator.teardown()
			})
		},
	})
}

const queries = {
	analysisOptions: `#graphql
query AnalysisOptions($filter: AnalysisOptionFilter, $pagination: PaginatedInput) {
  analysisOptions(filter: $filter, pagination: $pagination) {
    data {
      id
      active
      analysisId
      option
      unit
      valueType
      defaultValue
      defaultType
      thresholdType
      requiredToPerform
      requiredToClose
      informational
      rank
      productId
      rules {
        id
        analysisOptionId
        active
        restriction
        outcome
        description
        created
        tags {
          id
          name
          active
          entityType
        }
      }
    }
  }
}`,
	analysisOptionChoices: `#graphql
query AnalysisOptionChoices($filter: AnalysisOptionChoiceFilter) {
  analysisOptionChoices(filter: $filter) {
    data {
      active
	  analysisOptionId
      plantId
      constraint
      boundaryType
      choice
      id
      product {
        name
        id
      }
	  severityClass {
        id
        name
      }
    }
  }
}`,
	products: `#graphql
query Product($filter: ProductFilter, $pagination: PaginatedInput) {
  products(filter: $filter, pagination: $pagination) {
    data {
      id
      name
      active
      category
      inUseAtPlants {
        id
      }
    }
  }
}`,
	severityClasses: `#graphql
query SeverityClasses($filter: SeverityClassFilter, $pagination: PaginatedInput) {
  severityClasses(filter: $filter, pagination: $pagination) {
    data {
      id
      name
      description
      default
      plantId
    }
  }
}`,
	checkApplicableThresholds: `#graphql
query CheckApplicableThresholds(
  $analysisOptionId: PositiveInt!
  $currentResult: String!
  $plantId: PositiveInt
  $productBatchId: PositiveInt
  $productId: PositiveInt
  $severityClassId: PositiveInt
) {
  checkApplicableThresholds(
    analysisOptionId: $analysisOptionId
    currentResult: $currentResult
    plantId: $plantId
    productBatchId: $productBatchId
    productId: $productId
    severityClassId: $severityClassId
  ) {
    applicability
    boundaryType
    choice
    constraint
    plantId
    productId
    severityClassId
    violated
  }
}`,
	getValueAcceptability: `#graphql
	query GetValueAcceptability(
  $analysisOptionId: PositiveInt!
  $plantId: PositiveInt!
  $currentResult: String!
  $severityClassId: PositiveInt
  $productionVolume: NonNegativeFloat
  $productId: PositiveInt
  $productBatchId: PositiveInt
) {
  getValueAcceptability(
    analysisOptionId: $analysisOptionId
    plantId: $plantId
    currentResult: $currentResult
    severityClassId: $severityClassId
    productionVolume: $productionVolume
    productId: $productId
    productBatchId: $productBatchId
  )
}`,
}
