export default {
    "name": "LOCATIONS_LIST_PAGE",
    "kind": "HoudiniQuery",
    "hash": "32dda6dc71de1f716134b58d312a714b763dc4576770e349877c32e81b085354",

    "raw": `query LOCATIONS_LIST_PAGE($pagination: PaginatedInput, $locationFilter: LocationFilter, $processZoneFilter: ProcessZoneFilter, $processZoneId: PositiveInt, $productProximityFilter: ProductProximityFilter, $productProximityId: PositiveInt, $plantId: PositiveInt!) {
  locations(filter: $locationFilter, pagination: $pagination) {
    data {
      id
      attachmentCount
      attachments {
        fileId
        public
        rank
        file {
          mimeType
          name
          path
          size
          id
        }
        id
      }
      code
      location
      plantId
      description
      active
      parentLocationId
      productProximity {
        id
        name
      }
      processZone {
        id
        name
      }
      severityClass {
        id
        name
      }
      tags {
        active
        entityType
        id
        name
      }
      testable
    }
  }
  processZones(filter: $processZoneFilter, pagination: $pagination) {
    data {
      id
      name
      description
    }
  }
  productProximities(filter: $productProximityFilter, pagination: $pagination) {
    data {
      id
      name
      description
    }
  }
  processZoneAssignments: processZones(
    filter: $processZoneFilter
    pagination: $pagination
  ) {
    data {
      id
      name
      severityClassAssignments {
        productProximity {
          id
          name
        }
        severityClass {
          id
          name
        }
        id
      }
    }
  }
  defaultSeverityClass: getSeverityClass(
    processZoneId: $processZoneId
    productProximityId: $productProximityId
    plantId: $plantId
  ) {
    id
    name
  }
}
`,

    "rootType": "Query",

    "selection": {
        "fields": {
            "locations": {
                "type": "LocationResponse",
                "keyRaw": "locations(filter: $locationFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Location",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "attachmentCount": {
                                        "type": "UnsignedInt",
                                        "keyRaw": "attachmentCount",
                                        "visible": true
                                    },

                                    "attachments": {
                                        "type": "LocationFile",
                                        "keyRaw": "attachments",

                                        "selection": {
                                            "fields": {
                                                "fileId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "fileId",
                                                    "visible": true
                                                },

                                                "public": {
                                                    "type": "Boolean",
                                                    "keyRaw": "public",
                                                    "visible": true
                                                },

                                                "rank": {
                                                    "type": "SafeInt",
                                                    "keyRaw": "rank",
                                                    "visible": true
                                                },

                                                "file": {
                                                    "type": "File",
                                                    "keyRaw": "file",

                                                    "selection": {
                                                        "fields": {
                                                            "mimeType": {
                                                                "type": "String",
                                                                "keyRaw": "mimeType",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "visible": true
                                                            },

                                                            "size": {
                                                                "type": "UnsignedInt",
                                                                "keyRaw": "size",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "location": {
                                        "type": "String",
                                        "keyRaw": "location",
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "parentLocationId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "parentLocationId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "productProximity": {
                                        "type": "ProductProximity",
                                        "keyRaw": "productProximity",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "processZone": {
                                        "type": "ProcessZone",
                                        "keyRaw": "processZone",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "severityClass": {
                                        "type": "SeverityClass",
                                        "keyRaw": "severityClass",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "tags": {
                                        "type": "EntityTag",
                                        "keyRaw": "tags",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                },

                                                "entityType": {
                                                    "type": "EntityTagType",
                                                    "keyRaw": "entityType",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "testable": {
                                        "type": "Boolean",
                                        "keyRaw": "testable",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "processZones": {
                "type": "ProcessZoneResponse",
                "keyRaw": "processZones(filter: $processZoneFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "ProcessZone",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "productProximities": {
                "type": "ProductProximityResponse",
                "keyRaw": "productProximities(filter: $productProximityFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "ProductProximity",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "processZoneAssignments": {
                "type": "ProcessZoneResponse",
                "keyRaw": "processZoneAssignments(filter: $processZoneFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "ProcessZone",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "severityClassAssignments": {
                                        "type": "SeverityClassAssignment",
                                        "keyRaw": "severityClassAssignments",

                                        "selection": {
                                            "fields": {
                                                "productProximity": {
                                                    "type": "ProductProximity",
                                                    "keyRaw": "productProximity",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "severityClass": {
                                                    "type": "SeverityClass",
                                                    "keyRaw": "severityClass",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "defaultSeverityClass": {
                "type": "SeverityClass",
                "keyRaw": "defaultSeverityClass(plantId: $plantId, processZoneId: $processZoneId, productProximityId: $productProximityId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput",
            "locationFilter": "LocationFilter",
            "processZoneFilter": "ProcessZoneFilter",
            "processZoneId": "PositiveInt",
            "productProximityFilter": "ProductProximityFilter",
            "productProximityId": "PositiveInt",
            "plantId": "PositiveInt"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            },

            "ProcessZoneFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            },

            "ProductProximityFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            },

            "LocationFilter": {
                "activeOnly": "Boolean",
                "location": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "processZone": "ProcessZoneFilter",
                "productProximity": "ProductProximityFilter",
                "testableOnly": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=b7f72af84ac36cb7c12b55debd444903bf1b1d5a45adfbd80e3acacddf3ab0b3";